import { PriceCalculation, PriceColumn, WallPosition } from '@idearoom/types';
import { Dispatch } from 'react';
import { change } from 'redux-form';
import { TFunction } from 'i18next';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ClientDataType } from '../constants/ClientDataType';
import {
  ActionColumn,
  CLIENT_UPDATE_CATEGORY_MAPPING,
  componentColumnMap,
  DisplayColumns,
  ComponentFormData,
  HelperColumns,
  MiscPriceColumns,
  ComponentAttributeColumns,
  PRICING_TYPE_MAP,
  PricingCalculationColumns,
  PriceByQuantity,
} from '../constants/PricingClientUpdate';
import { PricingComponentEditFields } from '../constants/FormFields';
import {
  ClientUpdateCategory,
  ClientUpdateCategoryKey,
  ComponentCategoryItem,
  ComponentCategoryItemWithConditions,
  ComponentUpdate,
  ConditionalPrice,
  SizeBasedPricingSheet,
} from '../types/PricingClientUpdate';
import { setPricingComponentDataBranch, setPricingSizeBasedDataBranch } from '../ducks/pricingSlice';
import {
  arePriceValuesDifferent,
  getValidatedNewValue,
  formatPrice,
  isDecimalPrice,
  formatNumber,
  removeCurrencySymbolsCommasAndSpaces,
} from './pricingUtils';
import { mapClientAndDataTypeAndTableToUndoStackId } from './clientIdUtils';
import { compoundCaseToTitleCase, fuzzyMatchIncludes, kebabCaseToTitleCase, pluralizeString } from './stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { Forms } from '../constants/Forms';
import { Region } from '../types/Region';
import { PricingTab } from '../constants/Pricing';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { getPriceColumnsByCategory, getPricingSheetTable } from './pricingSheetUtils';
import { OPTION_CONDITION_TABLE } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { TableData } from '../types/DataGrid';
import { CellMetadata } from '../types/ClientData';

/**
 * Converts a price string to a number. Returns null if the price is not a valid number.
 *
 * @param price
 * @returns
 */
const convertPriceToNumber = (price: string | number | null | undefined): number | null => {
  if (price === null || price === undefined) return null;
  if (typeof price === 'string') {
    const parsed = parseFloat(removeCurrencySymbolsCommasAndSpaces(price));
    if (parsed === null || Number.isNaN(parsed)) return null;
    return parsed;
  }
  return price;
};

/**
 * Returns the rowId and clientCategoryKey that can be used to update a component category
 * item's column.
 *
 * @param clientId clientId
 * @param dataType data type that is being edited
 * @param column column to update
 * @param rowData source of the edit value
 * @param item component category item being edited
 * @returns rowId and clientCategoryKey that can be used to update the column
 */
export const getIdentifiersForComponentUpdate = (
  clientId: string,
  dataType: ClientDataType,
  column: string,
  rowData: ComponentCategoryItem | ConditionalPrice | undefined,
  componentCategoryItem: ComponentCategoryItem | undefined,
  componentCategoryKey: ComponentCategoryKey | undefined,
) => {
  const value = (rowData as any)?.[column];

  let { table = '', rowId = '' } = rowData || {};
  // If the value is an object, use it's rowId and table
  if (typeof value === 'object' && value !== null) {
    ({ rowId = '', table = '' } = value);
  } else if (rowId !== componentCategoryItem?.[ClientDataFixedColumns.RowId]) {
    // If the rowId is different from the item, then it's a conditional price (option condition)
    table = OPTION_CONDITION_TABLE;
  }

  return {
    clientCategoryKey: mapClientAndDataTypeAndTableToUndoStackId(clientId, dataType, componentCategoryKey),
    rowId,
    table,
  };
};

/**
 * Returns the value of a component category item column.
 * Handles values which are from a different table (represented as an object with a
 * table, rowId, and value property).
 *
 * @param column column to get the value of
 * @param item component category item or conditional price
 * @returns value of the column
 */
export const getComponentCategoryItemColumnValue = (
  column: string,
  item: ComponentCategoryItem | ConditionalPrice | TableData | CellMetadata,
) => {
  const value = (item as any)?.[column];
  if (typeof value === 'object' && value !== null) return value.value;
  return value;
};

/**
 * Returns whether the field is a pricing field including upgrade price.
 * @param field field to evaluate
 * @returns whether the field is a pricing field
 */
export const isPricingField = (field: string) =>
  [PriceColumn, MiscPriceColumns].some((e) => Object.values(e).includes(field));

/**
 * Returns whether the field is a regional pricing field.
 * @param field field to evaluate
 * @returns whether the field is a regional pricing field
 */
export const isRegionalPricingField = (field: string) =>
  isPricingField(field) && field.toLowerCase().includes('region');

/**
 * Determines whether the condition should be used in place of the default price.
 * Right now just confirms that the condition has a price.
 *
 * @param condition condition to evaluate
 * @returns
 */
export const isMatchingPriceCondition = (condition: ConditionalPrice, priceColumn: string = PriceColumn.price) =>
  (condition as any)[priceColumn] || condition[PriceColumn.price];

/**
 * Return the item or condition data that should be used for a given column.
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns item or condition data
 */
export const getMatchingItemOrConditionData = (item: ComponentCategoryItemWithConditions, column: string) => {
  const { item: componentData, conditions } = item;

  if (!(Object.values(PriceColumn) as string[]).includes(column)) return componentData;

  const pricingCondition = conditions.find((c) => isMatchingPriceCondition(c, column));
  return pricingCondition || componentData;
};

/**
 * Returns all row data for the item that matches the price of the primary matching item/condition data for the item
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns all matching item or condition data
 */
export const getAllMatchingItemOrConditionData = (
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions,
  column: string,
) => {
  const matchingItemOrConditionData = getMatchingItemOrConditionData(componentCategoryItemsWithConditions, column);
  const { [column]: valueToMatch } = matchingItemOrConditionData as unknown as Record<string, string>;

  const { item: componentData, conditions } = componentCategoryItemsWithConditions;
  return [
    ...(componentData[column as keyof ComponentCategoryItem] === valueToMatch ? [componentData] : []),
    ...conditions.filter((c) => c[column as keyof ConditionalPrice] === valueToMatch),
  ] as (ComponentCategoryItem | ConditionalPrice)[];
};

/**
 * Determines whether the item/condition data should be editable. This is a safeguard to prevent editing items that are using option conditions for pricing
 * and have multiple conditions with different prices. In this case, editing isn't allowed and "Varies" is displayed in the UI.
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns whether the item or condition data should be editable
 */
export const allowEditingItemOrConditionData = (item: ComponentCategoryItemWithConditions, column: string) => {
  const allMatchingItemOrConditionData = getAllMatchingItemOrConditionData(item, column);
  const matchingConditionData = allMatchingItemOrConditionData.filter(
    (rowData) => rowData[ClientDataFixedColumns.RowId] !== item.item[ClientDataFixedColumns.RowId],
  );

  // Allow editing the component data (not using conditions)
  if (allMatchingItemOrConditionData.length === 1 && matchingConditionData.length === 0) return true;

  // Allow editing the component data only if all conditions match the price
  return matchingConditionData.length === item.conditions.length;
};

/**
 * Returns a parsed and formatted price value for a given field and row/form data.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns parsed and formatted price value
 */
export const parsePriceValue = (column: string, rowData: any): number | string | null => {
  const value = `${rowData?.[column]}` || '';
  const valueWithoutCurrency = removeCurrencySymbolsCommasAndSpaces(value);

  if (rowData?.priceExpression) return i18n.t(I18nKeys.PricingComponentPriceExpressionPrice) as string;

  let valueAsNumber = parseFloat(valueWithoutCurrency || '0');
  if (Number.isNaN(valueAsNumber)) valueAsNumber = 0;
  return valueAsNumber.toFixed(2);
};

/**
 * Returns whether the given region column is using the default price column's value.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns whether the region column is using the default price column's value
 */
export const usingDefaultRegionPrice = (column: string, componentCategoryItem: ComponentCategoryItemWithConditions) => {
  const rowData = getMatchingItemOrConditionData(
    componentCategoryItem,
    column as keyof ComponentCategoryItem,
  ) as unknown as Record<string, string | number | null | undefined>;
  // If the value is null or undefined, it falls back to the default price
  if (rowData[column] === undefined || rowData[column] === null || rowData[column] === '') return true;
  return (
    parsePriceValue(column as keyof ComponentCategoryItem, rowData as any) ===
    parsePriceValue(PriceColumn.price, rowData as any)
  );
};

/**
 * Returns whether any of the component category item's region prices differ from the default price.
 *
 * @param rowData data for the component row
 * @returns whether any region prices differ from the default price
 */
export const pricingVariesByRegion = (componentCategoryItem: ComponentCategoryItemWithConditions) =>
  Object.keys(componentCategoryItem.item).some(
    (col) => isRegionalPricingField(col) && !usingDefaultRegionPrice(col, componentCategoryItem),
  );

export const getComponentFieldLabel = (
  field: keyof ComponentFormData | ComponentAttributeColumns | PriceColumn | ActionColumn,
  regions: Region[],
  varyPriceByRegion: boolean,
) => {
  if (field === DisplayColumns.PriceExpression) return '';

  if (isRegionalPricingField(field)) {
    const regionLabel = regions.find(({ priceColumn }) => priceColumn === (field as string))?.label;
    if (regionLabel) return regionLabel;
  }

  if (field === PriceColumn.price && varyPriceByRegion) {
    return i18n.t(I18nKeys.PricingComponentPriceDefaultPrice);
  }

  return compoundCaseToTitleCase(field);
};

/**
 * Responds to an edit of a component category item's price field and updates any region prices that are using the default price.
 *
 * @param event event that triggered the change
 * @param currentValues current form data
 * @param dispatch
 * @returns
 */
export const onComponentFieldChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  currentValues: ComponentFormData,
  dispatch: Dispatch<any>,
) => {
  const { name: field, value } = event.target;

  if (field !== PriceColumn.price) return;

  Object.keys(currentValues).forEach((f) => {
    if (
      !isRegionalPricingField(f) ||
      parsePriceValue(f, currentValues) !== parsePriceValue(PriceColumn.price, currentValues)
    )
      return;
    dispatch(change(Forms.PricingComponentEdit, f, value));
  });
};

/**
 * Determines whether a component category item should have editable prices. If the component category item does not,
 * the prices will be displayed as "varies".
 *
 * @param column column that may or may not be editable
 * @param categoryKey selected category key
 * @param componentCategoryItemWithConditions edited component category item with conditions
 * @param componentCategoryItemsWithConditions all component category items with conditions
 * @returns whether the component category item should have editable prices
 */
export const contactSupportForPricing = (
  column: string,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
) => !allowEditingItemOrConditionData(componentCategoryItemWithConditions, column);

/**
 * Finds a price for the given component and regional price column. Uses regional price if available, otherwise uses default price.
 *
 * @param componentId row ID of the component
 * @param priceColumn regional price column
 * @param componentCategoryItemsWithConditions all component category items with conditions
 * @param overrideMissingRegionPriceWithDefault If true, will use the defaultPrice for regions that do not have a price set
 * @returns matched price for the component
 */
export const getMatchedPriceFromConditions = (
  priceColumn: string,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
  overrideMissingRegionPriceWithDefault = false,
) => {
  const { [priceColumn]: regionPrice, [PriceColumn.price]: defaultPrice } = getMatchingItemOrConditionData(
    componentCategoryItemWithConditions,
    priceColumn as keyof ComponentCategoryItem,
  ) as any;

  if (!(Object.values(PriceColumn) as string[]).includes(priceColumn)) return regionPrice;

  if (!overrideMissingRegionPriceWithDefault && isRegionalPricingField(priceColumn)) {
    return regionPrice;
  }

  return regionPrice || defaultPrice;
};

/**
 * Gets the starting value for a component pricing field.
 *
 * @param column column name
 * @param rowData data for the component row
 * @param overrideMissingRegionPriceWithDefault If true, will use the defaultPrice for regions that do not have a price set
 * @returns initial value for the component pricing field
 */
export const getDefaultParsedComponentPricingColumnValue = (
  column: keyof ComponentCategoryItem,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
  currency?: string,
  useDecimalFormat = false,
  overrideMissingRegionPriceWithDefault = false,
) => {
  const { t } = i18n;
  const { item } = componentCategoryItemWithConditions;
  const rowData = getMatchingItemOrConditionData(componentCategoryItemWithConditions, column) as any;
  const value = getComponentCategoryItemColumnValue(column, rowData);

  if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
    return (
      (item?.priceExpression && t(I18nKeys.PricingComponentPriceExpressionCalculation)) ||
      value ||
      (categoryKey && CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType) ||
      PriceCalculation.Amount
    );
  }

  if (isPricingField(column)) {
    if (contactSupportForPricing(column, componentCategoryItemWithConditions))
      return t(I18nKeys.PricingComponentPriceVaries);

    const effectivePrice = getMatchedPriceFromConditions(
      column,
      componentCategoryItemWithConditions,
      overrideMissingRegionPriceWithDefault,
    );

    if (([MiscPriceColumns.UpgradePrice] as string[]).includes(column) && !effectivePrice && effectivePrice !== 0)
      return null;

    // If we don't have a region price set, simply return. There is no price to format in this case.
    if (isRegionalPricingField(column) && !effectivePrice && effectivePrice !== 0) {
      return null;
    }

    return formatPrice(effectivePrice, currency, useDecimalFormat ? 2 : 0, useDecimalFormat ? 2 : 0);
  }
  return value;
};

/**
 * Finds all available component pricing form fields for the given component ID's table columns
 * and includes initial values if data exists.
 * Pricing calculation fields default to 'Amount' if no value exists.
 *
 * @param componentRowId component row ID
 * @param componentCategoryItems all component data for the category
 * @returns component pricing form fields and initial values
 */
export const getComponentPricingFormInitialValues = (
  componentRowId: string | number,
  categoryKey: ComponentCategoryKey,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[],
  currency?: string,
): Record<string, string | number | null | undefined> | undefined => {
  const componentCategoryItemWithConditions = componentCategoryItemsWithConditions.find(
    ({ item }) => item.rowId === componentRowId,
  );

  if (!componentCategoryItemWithConditions) {
    return undefined;
  }

  const overrideMissingRegionPriceWithDefault = true;
  const { item: componentData } = componentCategoryItemWithConditions;
  const useDecimalFormat = Object.keys(componentData).some(
    (col) =>
      isPricingField(col) &&
      isDecimalPrice(
        getMatchedPriceFromConditions(col, componentCategoryItemWithConditions, overrideMissingRegionPriceWithDefault),
      ),
  );
  const columns = [
    ...Object.keys(componentData).filter((c) =>
      [DisplayColumns, PriceColumn, MiscPriceColumns].some((e) => Object.values(e).some((v) => v === c)),
    ),
    // Always include price calculation column
    PricingCalculationColumns.PriceCalculation,
  ];
  return Object.fromEntries(
    [
      [PricingComponentEditFields.Component, componentRowId],
      [PricingComponentEditFields.Table, componentData.table],
      [HelperColumns.Key, componentData.key],
      ...columns
        .map((c) => [
          c,
          getDefaultParsedComponentPricingColumnValue(
            c as keyof ComponentCategoryItem,
            categoryKey,
            componentCategoryItemWithConditions,
            currency,
            useDecimalFormat,
            overrideMissingRegionPriceWithDefault,
          ),
        ])
        // Do not display price expression column if a value does not exist
        .filter(
          ([c, v]) =>
            !([DisplayColumns.PriceExpression, MiscPriceColumns.UpgradePrice] as string[]).includes(c as string) || !!v,
        ),
      [HelperColumns.VariesByRegion, pricingVariesByRegion(componentCategoryItemWithConditions)],
    ].sort(([a]: any, [b]: any) => (componentColumnMap[a]?.order || 0) - (componentColumnMap[b]?.order || 0)),
  );
};

export const getChangedValues = (categoryKey: ComponentCategoryKey, updates: ComponentUpdate[]) => {
  const newValues: ComponentUpdate[] = [];

  updates.forEach(({ clientCategoryKey, table, data, column, oldValue, newValue }) => {
    let newVal: string | null = `${newValue}`.trim();
    let oldVal: string = `${oldValue}`.trim();

    let valueChanged = oldVal !== newVal;
    if (isPricingField(column)) {
      newVal = newValue === null ? newValue : getValidatedNewValue(newValue, oldVal);
      oldVal = getValidatedNewValue(oldVal, oldVal);
      valueChanged = arePriceValuesDifferent(oldVal, newVal);
    } else if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
      valueChanged =
        valueChanged &&
        (!!oldValue ||
          newVal !== (CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType || PriceCalculation.Amount));
    }

    if (valueChanged) {
      newValues.push({ clientCategoryKey, table, data: { ...data, [column]: newVal }, column, oldValue, newValue });
    }
  });

  return newValues;
};

/**
 * Used to filter out form data fields and values that should not be edited.
 *
 * @param formData component edit form data
 * @param editedComponentCategoryItem edited component category item with conditions
 * @returns filtered form data
 */
export const filterComponentEditFormData = (
  formData: ComponentFormData,
  editedComponentCategoryItem: ComponentCategoryItemWithConditions,
) =>
  Object.entries(formData).filter(([column, value]) => {
    let newValue = value;
    let oldValue = getComponentCategoryItemColumnValue(
      column,
      getMatchingItemOrConditionData(editedComponentCategoryItem, column as keyof ComponentCategoryItem),
    );
    const newDefaultPrice = parsePriceValue(PriceColumn.price, formData);
    const oldDefaultPrice = parsePriceValue(
      PriceColumn.price,
      getMatchingItemOrConditionData(editedComponentCategoryItem, PriceColumn.price),
    );

    if (isPricingField(column)) {
      [newValue, oldValue] = [value, oldValue].map((v) =>
        v || v === 0 ? parsePriceValue(column, { [column]: v }) : null,
      );
    }
    if (
      ![PriceColumn, MiscPriceColumns, [PricingCalculationColumns.PriceCalculation], [DisplayColumns.Label]]
        .flatMap((e) => Object.values(e))
        .includes(column)
    )
      return false;
    // Ignore changes to prices if a price expression exists
    if (isPricingField(column) && formData[DisplayColumns.PriceExpression]) return false;

    // Previously using default price and no changes or prices do not vary by region
    if (
      isRegionalPricingField(column) &&
      ((usingDefaultRegionPrice(column, editedComponentCategoryItem) && newValue === oldDefaultPrice) ||
        !formData[HelperColumns.VariesByRegion])
    ) {
      if (!oldValue && oldValue !== 0) return false;
      if (newValue !== newDefaultPrice) return true;
    }

    // Don't allow updates to Upgrade Price unless they aleady have an upgrade price and it's not 0
    if (MiscPriceColumns.UpgradePrice === column && (!oldValue || parseFloat(oldValue) === 0)) {
      return false;
    }

    if (isPricingField(column)) {
      newValue = convertPriceToNumber(newValue);
      if (newValue !== null && Number.isNaN(parseFloat(newValue))) {
        return false;
      }
    }
    return isPricingField(column) ? formatNumber(newValue || 0) !== formatNumber(oldValue || 0) : newValue !== oldValue;
  });

/**
 * Gets all component category items by within a row groups.
 *
 * @param categoryKey selected category key
 * @param componentCategoryItems component category items
 * @returns grouped component category items
 */
export const getComponentCategoryItemsWithinRowGroup = (
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[],
) => {
  if (!categoryKey) return [componentCategoryItemWithConditions];

  const { rowGroups = [] } = CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey];
  const temp = componentCategoryItemsWithConditions.filter(
    (row) =>
      row.item[ClientDataFixedColumns.RowId] ===
        componentCategoryItemWithConditions.item[ClientDataFixedColumns.RowId] ||
      (rowGroups.length &&
        CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.rowGroups?.every(
          (c) =>
            getDefaultParsedComponentPricingColumnValue(c, categoryKey, row) ===
            getDefaultParsedComponentPricingColumnValue(c, categoryKey, componentCategoryItemWithConditions),
        )),
  );
  return temp;
};

/**
 * Gets a list of updates to be made based on the given form data and edited component category item.
 * Filters out fields that should not be edited via filterComponentEditFormData. Adds in additional updates
 * for any "update groups" that are defined for the category. Also adds in updates for other row data for the item
 * that matches the price of the primary matching item/condition data for the item.
 *
 * @param clientId client ID
 * @param categoryKey selected category key
 * @param formData component edit form data
 * @param editedComponentCategoryItem edited component category item with conditions
 * @param allComponentCategoryItems all component category items with conditions
 * @returns list of updates to be made
 */
export const getComponentEditUpdates = (
  clientId: string,
  categoryKey: ComponentCategoryKey,
  formData: ComponentFormData,
  editedComponentCategoryItem: ComponentCategoryItemWithConditions,
  allComponentCategoryItems: ComponentCategoryItemWithConditions[],
): ComponentUpdate[] => {
  const filteredUpdates = filterComponentEditFormData(formData, editedComponentCategoryItem);
  const updates = filteredUpdates.reduce((allUpdatesForAllItems, [c, newValue]) => {
    let otherItemsInGroup = getComponentCategoryItemsWithinRowGroup(
      categoryKey,
      editedComponentCategoryItem,
      allComponentCategoryItems,
    );
    otherItemsInGroup = otherItemsInGroup.filter((row) => editedComponentCategoryItem.item.rowId !== row.item.rowId);
    const updatesForThisField = [editedComponentCategoryItem, ...otherItemsInGroup].reduce(
      (allUpdatesForThisField, componentCategoryItemWithCondition) => {
        let value = newValue;
        // For this item and field, if it is a price field, update all conditions and the item itself that share the same price
        const allUpdatesForThisItemAndField = getAllMatchingItemOrConditionData(
          componentCategoryItemWithCondition,
          c,
        ).map((rowData: ComponentCategoryItem | ConditionalPrice) => {
          const oldValue = getComponentCategoryItemColumnValue(c, rowData);
          const newDefaultPrice = parsePriceValue(PriceColumn.price, formData);
          const oldDefaultPrice = parsePriceValue(
            PriceColumn.price,
            getMatchingItemOrConditionData(editedComponentCategoryItem, PriceColumn.price),
          );

          // Format the value if it's a number
          if (isPricingField(c)) {
            value = parsePriceValue(c, { [c]: value });
          }

          if (
            isRegionalPricingField(c) &&
            ((usingDefaultRegionPrice(c, editedComponentCategoryItem) && value === oldDefaultPrice) ||
              !formData[HelperColumns.VariesByRegion])
          ) {
            value = newDefaultPrice;
          }

          // Format the value if it's a number
          if (isPricingField(c)) {
            value = parsePriceValue(c, { [c]: value });
          }

          const { clientCategoryKey, rowId, table } = getIdentifiersForComponentUpdate(
            clientId,
            ClientDataType.Supplier,
            c,
            rowData,
            componentCategoryItemWithCondition.item,
            categoryKey,
          );

          return {
            clientCategoryKey,
            table,
            data: { [ClientDataFixedColumns.RowId]: rowId },
            column: c,
            oldValue,
            newValue: value,
          };
        });

        return [...allUpdatesForThisField, ...allUpdatesForThisItemAndField];
      },
      [] as ComponentUpdate[],
    );

    return [...allUpdatesForAllItems, ...updatesForThisField];
  }, [] as ComponentUpdate[]);

  return updates;
};

/**
 * Transforms a list of component category items with conditions into a list of component category items.
 *
 * @param componentCategoryItemsWithConditions list of component category items with conditions
 * @returns list of component category items
 */
export const getComponentCategoryItems = (
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[] = [],
) => componentCategoryItemsWithConditions.map(({ item }) => item);

/**
 * Filters component category items or size based pricing sheets based on a search value.
 *
 * @param searchValue search value
 * @param componentCategoryItems component category items or size based pricing sheets
 * @returns filtered component category items or size based pricing sheets
 */
export const filterComponentCategoryItems = (
  searchValue: string,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[] = [],
) =>
  componentCategoryItemsWithConditions.filter(
    ({ item }) =>
      !searchValue ||
      [PricingCalculationColumns, DisplayColumns, PriceColumn, MiscPriceColumns]
        .flatMap((e) => Object.values(e))
        .some((c) => fuzzyMatchIncludes(`${getComponentCategoryItemColumnValue(c, item)}`, searchValue)),
  );

/**
 * Filters client update categories based on a search value.
 *
 * @param searchValue search value
 * @param categories client update categories
 * @returns filtered client update categories
 */
export const filterClientUpdateCategories = (
  searchValue: string,
  categories: ClientUpdateCategory[] = [],
  t: TFunction,
) =>
  categories.filter(
    ({ key }) =>
      !searchValue ||
      [t(CLIENT_UPDATE_CATEGORY_MAPPING[key]?.label), key].some((val) => fuzzyMatchIncludes(`${val}`, searchValue)),
  );

/**
 * Gets the search count text for the client update search.
 *
 * @param searchValue search value
 * @param pricingTab selected pricing tab
 * @param categoryKey selected category key
 * @param filteredItems filtered display items
 * @param items non-filtered display items
 * @param categories client update categories
 * @returns search count text
 */
export const getClientUpdateSearchCountText = (
  searchValue: string,
  pricingTab: string,
  categoryKey?: ClientUpdateCategoryKey,
  filteredItems?: ClientUpdateCategory[] | ComponentCategoryItemWithConditions[] | SizeBasedPricingSheet[],
  items?: ClientUpdateCategory[] | ComponentCategoryItemWithConditions[] | SizeBasedPricingSheet[],
  categories?: ClientUpdateCategory[],
) => {
  const groupItemsName = 'category';
  const itemName = pricingTab === PricingTab.SizeBased ? 'price sheet' : 'component';

  const { total = 0, label } = categoryKey
    ? { total: items?.length, label: itemName }
    : { total: categories?.length, label: groupItemsName };
  const pluralizedLabel = total > 1 ? pluralizeString(label) : label;

  if (!searchValue) return `${total} ${pluralizedLabel}`;
  return `${filteredItems?.length || 0} of ${total} ${pluralizedLabel}`;
};

/**
 * Gets the label for a pricing calculation. Mainly used by "Amount" to display "Each" in the UI.
 *
 * @param type pricing calculation type
 * @returns label for the pricing calculation
 */
export const getPricingTypeLabel = (type: PriceCalculation) => {
  const { t } = i18n;
  const uniqueKey = PRICING_TYPE_MAP[type];
  return uniqueKey ? t(uniqueKey) : kebabCaseToTitleCase(type);
};

/**
 * Gets which pricing data branch to use based on the selected pricing tab.
 *
 * @param pricingTab selected pricing tab
 * @returns client data branch
 */
export const getClientUpdateBranch = (pricingTab: string | undefined) =>
  pricingTab === PricingTab.Component ? ClientDataBranch.ClientUpdate : ClientDataBranch.PricingSizeBased;

/**
 * Gets function to set pricing data branch based on the selected pricing tab.
 *
 * @param pricingTab selected pricing tab
 * @returns function to set pricing data branch
 */
export const getSetClientUpdateBranch = (pricingTab: string | undefined) =>
  pricingTab === PricingTab.Component ? setPricingComponentDataBranch : setPricingSizeBasedDataBranch;

/**
 * Gets a list of tables that may contain pricing updates
 *
 * @param clientId clientId
 * @param pricingTab current pricing tab
 * @param categoryKey selected category key (if applicable)
 * @param componentCategoryItems component category items (if applicable)
 * @returns list of tables that may contain pricing updates
 */
export const getClientUpdatePricingTables = (
  clientId: string,
  categoryKey: ClientUpdateCategoryKey | undefined,
  pricingTab: string | undefined,
  componentCategoryItems: ComponentCategoryItem[] = [],
) => {
  const uniqueTables = new Set<string>();
  switch (pricingTab) {
    case PricingTab.Component:
      uniqueTables.add(OPTION_CONDITION_TABLE);
      componentCategoryItems.forEach((item) => {
        const { table } = item;
        uniqueTables.add(table);
        Object.values(item).forEach((value) => {
          if (typeof value === 'object' && value !== null) {
            uniqueTables.add(value.table);
          }
        });
      });
      break;
    case PricingTab.SizeBased:
      [
        categoryKey,
        ...(([SizeBasedCategoryKey.SideWalls, SizeBasedCategoryKey.EndWalls] as string[]).includes(categoryKey || '')
          ? [SizeBasedCategoryKey.Siding]
          : []),
      ].forEach((category) => {
        uniqueTables.add(getPricingSheetTable(clientId, pricingTab, category) || '');
      });
      break;
    case PricingTab.Base:
      uniqueTables.add(getPricingSheetTable(clientId, pricingTab, undefined) || '');
      break;
    default:
      break;
  }
  return Array.from(uniqueTables).filter(Boolean);
};

/**
 * Determines whether vary by region UI elements should be displayed.
 * For instance, extra pricing fields in the component pricing edit form or the "Vary By Region" checkbox in the items table.
 *
 * @param regions - regions for the vendor
 * @param tableColumns - columns that are available in the current table
 * @returns whether vary by region UI elements should be displayed
 */
export const displayVaryByRegion = (regions: Region[], tableColumns: string[]) =>
  !!regions.length &&
  tableColumns.some((col) => isRegionalPricingField(col) && regions.some(({ priceColumn }) => priceColumn === col));

/**
 * Converts a series of style keys into a single, combined style label with an abbreviated version.
 * If all styles are included, displays the empty string '' instead of the full list.
 *
 * @param styleKeys style values
 * @param styles all styles available for the vendor
 * @param abbreviated whether to display the short version of the label
 * @returns style label
 */
const getStyleKeyLabel = (styleKeys: string[], styles: { key: string; label: string }[], abbreviated: boolean) => {
  const allStylesLabel = '';
  let filteredStylesLabel = styleKeys.map((key) => styles.find(({ key: k }) => k === key.trim())?.label).join(', ');

  if (!abbreviated) {
    filteredStylesLabel = `${styleKeys.length} style${styleKeys.length > 1 ? 's' : ''}: ${filteredStylesLabel}`;
  }

  return styles.every(({ key }) => styleKeys.includes(key)) || !styleKeys.length ? allStylesLabel : filteredStylesLabel;
};

/**
 * Converts a series of ranged values, such as 12-20, single values, or width tags, into a single, combined range label.
 *
 * @param attribute type of range to be shown as a prefix
 * @param values range values
 * @returns range label
 */
const getRangeLabel = (attribute: ComponentAttributeColumns, values: string[]) => {
  const { t } = i18n;

  // Match ranges like 12-20 or single numbers like 12 and sort ascending
  const ranges = values
    .flatMap((val) => val.match(/\b(\d+)(?:-(\d+))?\b/g) || [])
    .sort((a, b) => {
      const [aStart, bStart] = [a, b].map((v) => parseInt(v.split('-')[0], 10));
      return aStart - bStart;
    });

  const consolidatedRanges = ranges.reduce((acc, val) => {
    if (!acc.length) return [val];

    const [[previousStart, previousEnd = previousStart], [start, end = start]]: number[][] = [
      acc[acc.length - 1],
      val,
    ].map((range) => range.split('-').map((v) => parseInt(v, 10)));

    // The new start falls into the previous range
    if (start >= previousStart && start <= previousEnd) {
      // Extend the previous range to include the new end
      if (end >= previousEnd) {
        return [...acc.slice(0, -1), previousStart === end ? `${previousStart}` : `${previousStart}-${end}`];
      }
      return acc;
    }
    return [...acc, val];
  }, [] as string[]);

  let prefix = '';
  switch (attribute) {
    case ComponentAttributeColumns.WidthTags:
      prefix = t(I18nKeys.PricingComponentBuildingWidthPrefix);
      break;
    case ComponentAttributeColumns.Height:
      prefix = t(I18nKeys.PricingComponentHeightPrefix);
      break;
    case ComponentAttributeColumns.Width:
      prefix = t(I18nKeys.PricingComponentWidthPrefix);
      break;
    default:
      break;
  }
  return [prefix, consolidatedRanges.join(', ')].join(': ');
};

/**
 * Gets a label describing a component attribute for a component category item.
 *
 * @param attribute the attribute to get the label for
 * @param rowId the item's row ID
 * @param categoryKey the selected category key
 * @param componentCategoryItems all component category items
 * @param styles all styles available for the vendor
 * @param abbreviated whether to display the short version of the label (if applicable)
 * @returns label describing the component attribute
 */
export const getComponentAttributeLabel = (
  attribute: ComponentAttributeColumns,
  rowId: string,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  styles: { key: string; label: string }[],
  abbreviated: boolean,
) => {
  const { item } =
    componentCategoryItems.find(({ item: { [ClientDataFixedColumns.RowId]: id } }) => id === rowId) || {};

  if (!item || !categoryKey) return '';

  const values = Array.from(
    new Set(
      componentCategoryItems
        .filter(({ item: i }) =>
          CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.rowGroups?.every(
            (c) => getComponentCategoryItemColumnValue(c, i) === getComponentCategoryItemColumnValue(c, item),
          ),
        )
        .map(({ item: { [attribute]: a } }) => a || '')
        .filter(Boolean),
    ),
  );

  if (!values.length) return '';

  const duplicateItems = componentCategoryItems.filter(
    (i) =>
      i.item.rowId !== item.rowId &&
      i.item.label.value === item.label.value &&
      i.item.price !== item.price &&
      i.item[attribute] === item[attribute],
  );

  // We do not want to show an attribute if a different row happens to have the same attribute, with the same label and different price
  if (duplicateItems.length >= 1) {
    return '';
  }

  const { t } = i18n;
  switch (attribute) {
    case ComponentAttributeColumns.StyleKey:
      return getStyleKeyLabel(
        values.flatMap((v) => v?.replace(/\s/g, '')?.split(',') || []),
        styles,
        abbreviated,
      );
    case ComponentAttributeColumns.WallPosition:
      return `${t(I18nKeys.WallPosition)}: ${values
        .map((v) => (v === WallPosition.GableEnd ? t(I18nKeys.WallPositionGableEnd) : t(I18nKeys.WallPositionSideWall)))
        .join(', ')}`;
    case ComponentAttributeColumns.WidthTags:
    case ComponentAttributeColumns.Height:
    case ComponentAttributeColumns.Width:
      return getRangeLabel(attribute, values);
    default:
      return values.join(', ');
  }
};

/**
 * Gets a list of columns to display in the component category item table
 *
 * @param componentCategoryItems all component category items
 * @param clientTableColumns columns available in relevant tables
 * @param regions regions for the vendor
 * @returns list of columns to display in the component category item table
 */
export const getComponentCategoryColumns = (
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  clientTableColumns: { [table: string]: string[] },
  regions: Region[] = [],
) => {
  const isApplicableToAnItem = (
    columns: string[],
    isApplicable: (item: ComponentCategoryItemWithConditions) => boolean,
  ) => (componentCategoryItems.some(isApplicable) ? columns : []);

  return Array.from(
    new Set([
      ...componentCategoryItems
        .flatMap(({ item }) => Object.entries(item))
        .filter(([key, value]) => {
          // Only display upgrade price if a value exists, otherwise hide it
          if (MiscPriceColumns.UpgradePrice === key && (!!value || value === 0)) {
            return true;
          }
          if (([DisplayColumns.Label, PriceColumn.price] as string[]).includes(key)) {
            return true;
          }
          return false;
        }, [] as string[])
        .map(([key]) => key),
      // Always include price calculation column
      PricingCalculationColumns.PriceCalculation,
      ...isApplicableToAnItem([HelperColumns.VariesByRegion], ({ item: { table } }) =>
        displayVaryByRegion(regions || [], clientTableColumns[table]),
      ),
      ...isApplicableToAnItem([HelperColumns.AppliesTo], ({ item: { table } }) =>
        Object.values(ComponentAttributeColumns).some(
          // Applies to column is only applicable to the frameout tables
          (c) =>
            (table.toLowerCase().includes('frameout') && clientTableColumns[table].includes(c)) ||
            clientTableColumns[table].includes(ComponentAttributeColumns.StyleKey),
        ),
      ),
      ...(regions || []).map(({ priceColumn: regionColumn }) => regionColumn),
      ActionColumn.Edit,
    ]),
  );
};

/**
 * Formats a column value of a component category item for display in the table view.
 *
 * @param property column name
 * @param row component category item
 * @param categoryKey selected category key
 * @param componentCategoryItems all component category items
 * @param styles all visible styles
 * @param currency vendor currency
 * @returns formatted column value
 */
export const formatComponentCategoryValue = (
  property: string,
  row: any,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  styles: { key: string; label: string }[],
  currency = 'USD',
  minimumFractionDigits = 0,
): string => {
  const item = componentCategoryItems.find(
    ({ item: { [ClientDataFixedColumns.RowId]: rowId } }) => rowId === row[ClientDataFixedColumns.RowId],
  );

  if (HelperColumns.AppliesTo === property && item && !contactSupportForPricing(PriceColumn.price, item))
    return Object.values(ComponentAttributeColumns)
      .filter((c) => ![ComponentAttributeColumns.CustomExpression].includes(c))
      .map((c) =>
        getComponentAttributeLabel(
          c,
          row[ClientDataFixedColumns.RowId],
          categoryKey,
          componentCategoryItems,
          styles,
          false,
        ),
      )
      .filter(Boolean)
      .join(', ');
  if (property === PricingCalculationColumns.PriceCalculation) return getPricingTypeLabel(row[property]).toLowerCase();
  // Don't display 0 for upgrade price if it's not set
  if (property === MiscPriceColumns.UpgradePrice && !row[property] && row[property] !== 0) {
    return '';
  }
  // Don't display anything for region-pricing fields if we don't have a value set
  if (isRegionalPricingField(property) && !row[property] && row[property] !== 0) {
    return '';
  }
  if (isPricingField(property)) {
    return formatPrice(row[property], currency, minimumFractionDigits);
  }
  return row[property];
};

/**
 * Groups component category items by unique row groups if they exist for the category.
 *
 * @param categoryKey selected category key
 * @param componentCategoryItems component category items
 * @returns grouped component category items
 */
export const groupComponentCategoryItems = (
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[],
) =>
  componentCategoryItemsWithConditions.filter(
    (row, i, arr) =>
      !categoryKey ||
      !arr
        .slice(0, i)
        .some((r) =>
          CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.rowGroups?.every(
            (c) =>
              getDefaultParsedComponentPricingColumnValue(c, categoryKey, r) ===
              getDefaultParsedComponentPricingColumnValue(c, categoryKey, row),
          ),
        ),
  );

export const getComponentCategoryItemRows = (
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[],
  existingRows: any[],
  currency: string | undefined,
) => {
  const groupedRows = groupComponentCategoryItems(categoryKey, componentCategoryItemsWithConditions);
  const useDecimalFormat = groupedRows.some((itemWithConditions) =>
    Object.keys(itemWithConditions.item).some(
      (col) => isPricingField(col) && isDecimalPrice(getMatchedPriceFromConditions(col, itemWithConditions)),
    ),
  );
  return groupedRows
    .map((itemWithConditions) => {
      const { item: { [ClientDataFixedColumns.RowId]: rowId } = {} } = itemWithConditions;
      const existingRow = existingRows.find((r) => r[ClientDataFixedColumns.RowId] === rowId);

      const updatedRow = Object.fromEntries(
        Object.keys(itemWithConditions.item).map((column) => [
          column,
          getDefaultParsedComponentPricingColumnValue(
            column as keyof ComponentCategoryItem,
            categoryKey,
            itemWithConditions,
            currency,
            useDecimalFormat,
          ),
        ]),
      );
      return {
        ...(existingRow || {}),
        ...updatedRow,
        label: updatedRow.label,
        [HelperColumns.VariesByRegion]:
          existingRow?.[HelperColumns.VariesByRegion] === undefined
            ? pricingVariesByRegion(itemWithConditions)
            : existingRow?.[HelperColumns.VariesByRegion],
      };
    })
    .sort((a, b) => {
      // Sorting label by lowercase, spaces removed, alphabetically
      const [labelA, labelB] = [a, b].map((row) =>
        getComponentCategoryItemColumnValue(DisplayColumns.Label, row).toLowerCase().replace(/\s/g, ''),
      );
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
};

export const isClientUpdatePricingTab = (pricingTab?: string): boolean =>
  !!pricingTab && ([PricingTab.Component, PricingTab.SizeBased] as string[]).includes(pricingTab);

/**
 * Determines whether the split view is enabled for the selected pricing sheet.
 * It is enabled when there are values for combinedWallKeys.
 *
 * @param pricingSheet the selected pricing sheet
 * @param clientUpdateRegions the selected regions
 * @returns whether the split grid view should be displayed
 */
export const splitViewIsEnabled = (selectedPricingSheet: PricingSheet): boolean => {
  const { prices = [], category = '' } = selectedPricingSheet;
  return (
    ([SizeBasedCategoryKey.SideWalls, SizeBasedCategoryKey.EndWalls, SizeBasedCategoryKey.Siding] as string[]).includes(
      category,
    ) && prices.some(({ combinedWallKeys }) => combinedWallKeys)
  );
};

/**
 * Determines whether the user is using the combined grid view by default for the selected pricing sheet.
 * The user is using the combined grid view when there are values in the combined pricing columns.
 *
 * @param pricingSheet the selected pricing sheet
 * @param clientUpdateRegions the selected regions
 * @returns whether the split grid view should be displayed
 */
export const isUsingCombinedView = (selectedPricingSheet: PricingSheet, clientUpdateRegions: Region[]): boolean =>
  Object.values(clientUpdateRegions)
    .map(
      ({ priceColumn: standardPriceColumn }) =>
        getPriceColumnsByCategory(standardPriceColumn, selectedPricingSheet, PriceByQuantity.Combined)[0],
    )
    .some((priceColumn) => selectedPricingSheet?.prices?.some(({ [priceColumn]: price }) => price));
