import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { SyntheticEvent, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingSurcharge } from './PricingSurcharge';
import { PricingBase } from './PricingBase';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { setClientId, setSelectedTable } from '../ducks/clientDataSlice';
import { unknownUser } from '../types/User';
import { Loading } from './Loading';
import { AppRoutes } from '../constants/AppRoutes';
import { PricingTab, pricingTabs } from '../constants/Pricing';
import { getPathPart } from '../utils/urlUtils';
import { setSearchHidden } from '../ducks/search';
import { PricingClientUpdate } from './PricingClientUpdate';
import { viewingDealer } from '../utils/supplierUtils';
import { unknownGroup } from '../constants/Group';
import { setPricingTab } from '../middleware/viewerThunk';
import { UserPreference } from '../constants/User';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
});

export const Pricing: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { selectedPricingTabId } = useAppSelector((state: AppState) => state?.viewer);

  const clientId = useAppSelector((state: AppState) =>
    getClientIdFromClientSupplier(state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || ''),
  );
  const { clientId: clientDataClientId } = useAppSelector((state: AppState) => state?.clientData);
  const {
    user,
    preferences: {
      [UserPreference.ProfilePreferences]: { selectedPricingTabId: savedPricingTabId = undefined } = {},
    } = {},
  } = useAppSelector((state) => state.currentUser || unknownUser);

  const isDealer = useAppSelector(
    ({
      viewer: { selectedTabId, selectedClientId },
      currentUser: { group: { configurators: groupConfigs = [] } = unknownGroup },
    }) => viewingDealer(selectedTabId || selectedClientId, groupConfigs),
  );

  const { pricingEnableClientManaged, isLoadingPricingEnabled } = usePricingRepo({
    usePricingEnabled: true,
  });

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setClientId(clientId));
      dispatch(setPricingTab(undefined));
    }
  }, [clientId, clientDataClientId, dispatch]);

  useEffect(() => {
    if (isLoadingPricingEnabled) return;

    const route = getPathPart(3) as PricingTab | undefined;
    const availablePricingTabs = pricingTabs.filter(({ enabled }) =>
      enabled({
        user,
        clientId,
        pricingEnableClientManaged: !!pricingEnableClientManaged,
        isDealer,
      }),
    );
    let tab = availablePricingTabs[0].value;
    // Tab priority: (1) route, (2) user's saved tab, (3) Base tab if enabled
    if (route && availablePricingTabs.find(({ value }) => value === route)) {
      tab = route;
    } else if (savedPricingTabId && availablePricingTabs.find(({ value }) => value === savedPricingTabId)) {
      tab = savedPricingTabId;
    } else if (availablePricingTabs.find(({ value }) => value === PricingTab.Base)) {
      tab = PricingTab.Base;
    }

    if (selectedPricingTabId !== tab) {
      dispatch(setPricingTab(tab));
      window.history.replaceState(null, '', `${AppRoutes.Pricing}/${tab}`);
    }
  }, [
    user,
    clientId,
    pricingEnableClientManaged,
    isDealer,
    selectedPricingTabId,
    savedPricingTabId,
    isLoadingPricingEnabled,
    dispatch,
  ]);

  const changeTab = (newTab: PricingTab) => {
    dispatch(setPricingTab(newTab));
    dispatch(setSelectedTable(''));
    const route = pricingTabs.find((pricingTab) => pricingTab.value === newTab)?.route;
    window.history.replaceState(null, '', `${AppRoutes.Pricing}/${route}`);
  };

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={selectedPricingTabId || PricingTab.Base}
          onChange={(event: SyntheticEvent<Element, Event>, newValue: PricingTab): void => {
            changeTab(newValue);
          }}
          aria-label="pricing-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {pricingTabs
            .filter(({ enabled }) =>
              enabled({
                user,
                clientId,
                pricingEnableClientManaged: !isLoadingPricingEnabled && !!pricingEnableClientManaged,
                isDealer,
              }),
            )
            .map(({ i18nKey, value, id }) => (
              <Tab key={id} label={t(i18nKey)} value={value} id={id} aria-controls={id} />
            ))}
        </Tabs>
      </AppBar>

      <div className={classes.content}>
        {!selectedPricingTabId && isLoadingPricingEnabled && <Loading />}
        {selectedPricingTabId === PricingTab.Base && <PricingBase />}
        {selectedPricingTabId &&
          ([PricingTab.Component, PricingTab.SizeBased] as string[]).includes(selectedPricingTabId) && (
            <PricingClientUpdate />
          )}
        {(selectedPricingTabId === PricingTab.Surcharge ||
          (!selectedPricingTabId && !isLoadingPricingEnabled && !pricingEnableClientManaged)) && <PricingSurcharge />}
      </div>
    </div>
  );
};
