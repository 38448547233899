import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';

import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { setPricingPublishMergeResult } from '../ducks/pricingSlice';
import { MergeStatus } from '../constants/ClientData';
import { PublishDialog } from './PublishDialog';
import { PublishingResult } from '../types/ClientData';
import { useGetClientName } from '../hooks/useGetClientName';
import { unknownUser } from '../types/User';
import { useGetSupplierVendorsListQuery } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { EventName } from '../analytics/AnalyticsTypes';
import { recordUserEvent } from '../ducks/currentUserSlice';
import { getClientUpdateEventCategory, getSetBranch } from '../utils/clientDataUtils';

export const PricingPublishDialog: React.FC<{
  pricingBranch: ClientDataBranch;
  title: I18nKeys;
  content: I18nKeys;
}> = ({ pricingBranch, title, content }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const getClientName = useGetClientName();

  const { user = unknownUser, group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const { clientId, clientDataType } = useAppSelector((state) => state?.clientData);
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });

  let dialogId: string;
  switch (pricingBranch) {
    case ClientDataBranch.Pricing:
      dialogId = 'pricing-base';
      break;
    case ClientDataBranch.ClientUpdate:
      dialogId = 'pricing-component';
      break;
    case ClientDataBranch.PricingSizeBased:
      dialogId = 'pricing-size-based';
      break;
    default:
      dialogId = 'pricing-surcharge';
      break;
  }

  const onPublishClientData = (publishingResult: Promise<PublishingResult>) => {
    const setBranch = getSetBranch(pricingBranch);
    dispatch(setBranch(ClientDataBranch.Main));
    publishingResult
      .then((data) => {
        if (data.mainMerge.status !== MergeStatus.Succeed) {
          dispatch(setBranch(pricingBranch));
        }
        dispatch(setPricingPublishMergeResult({ data, isSuccess: true, error: undefined }));
        const eventCategory = getClientUpdateEventCategory(pricingBranch);
        dispatch(recordUserEvent({ groupId, clientId, eventCategory, eventType: EventName.Publish }));
      })
      .catch((error) => {
        dispatch(setBranch(pricingBranch));
        dispatch(setPricingPublishMergeResult({ data: undefined, isSuccess: false, error }));
      })
      .finally(() => {
        dispatch(closeDialogFunc());
        dispatch(openDialog({ dialog: Dialogs.PricingPublishResult }));
      });
  };

  const branch = activeBranches.find((metadata) => metadata.branchType === pricingBranch);
  const { authors = [], branchType } = branch || {};
  const dataType = branchType === ClientDataBranch.PricingSurcharge ? clientDataType : ClientDataType.Supplier;
  const displayAuthorsAsNumber = authors.length > 3;
  const localizedAuthors = authors.map((authorName) =>
    authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
  );
  const { data: supplierVendorsList = [] } = useGetSupplierVendorsListQuery(
    { clientId, groupId },
    { skip: !clientId || !groupId },
  );

  return (
    <PublishDialog
      dialogKey={Dialogs.PricingPublish}
      dialogId={dialogId}
      dialogTitle={t(title)}
      branch={branch}
      dataType={dataType}
      onPublishClientData={onPublishClientData}
      dialogTopContent={
        <Typography gutterBottom>
          <Trans
            i18nKey={content as string}
            values={{
              authors: displayAuthorsAsNumber
                ? t(I18nKeys.PricingBasePublishDialogTextAuthorsMultiple, { count: authors.length })
                : t(I18nKeys.PricingBasePublishDialogTextAuthors, {
                    authors: localizedAuthors,
                    count: authors.length,
                  }),
              sites:
                supplierVendorsList.length > 1
                  ? t(I18nKeys.PricingBasePublishDialogTextChangedSitesMultiple, { count: supplierVendorsList.length })
                  : t(I18nKeys.PricingBasePublishDialogTextChangedSites, {
                      changedSite: clientId ? getClientName(supplierVendorsList[0]) : '',
                    }),
            }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      }
    />
  );
};
