import { toast } from 'react-toastify';
import { reset, SubmissionError } from 'redux-form';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { DealerFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { closeDialog } from '../ducks/dialogSlice';
import { AppState } from '../types/AppState';
import { Dealer } from '../types/Dealer';
import { DepositAmount } from '../types/DepositAmount';
import { Group } from '../types/Group';
import { Vendor } from '../types/VendorData';
import { getDepositAmountExpression } from '../utils/depositAmountUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { dealerApi } from '../services/dealerApi';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { setAvailableDealers } from '../ducks/currentUserSlice';
import {
  fetchDealers as fetchDealersAction,
  removeDealer as removeDealerAction,
  fetchDealersError,
  fetchDealersSuccess,
  setDealerDialog,
  removeDealerSuccess,
  saveDealerSuccess,
} from '../ducks/dealersSlice';

const defaultErrorMessage = 'Something went wrong. Please try again.';

function* fetchDealers({ payload: clientId }: any): Generator {
  try {
    const getVendorDataFetch = (yield put(
      clientDataApi.endpoints.getVendorData.initiate({
        clientId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getVendorDataFetch.unsubscribe();

    const { data: { logoUrl } = {} } = (yield getVendorDataFetch) as { data: Vendor };

    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };

    const getAllDealersFetch = (yield put(
      dealerApi.endpoints.getDealersByGroup.initiate({
        clientId,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getAllDealersFetch.unsubscribe();

    const { data: { dealers = [] } = {} } = (yield getAllDealersFetch) as { data: { dealers: Dealer[] } };

    yield put(fetchDealersSuccess({ dealers, logoUrl }));
  } catch (error) {
    yield put(fetchDealersError());
    console.error(`Failed to fetch dealers for ${clientId}`);
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* removeDealer({ payload: { clientId, dealer } }: any): Generator {
  try {
    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };

    const { id } = dealer;
    const deleteDealerFetch = (yield put(
      dealerApi.endpoints.deleteDealer.initiate({
        groupId,
        clientId,
        id,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteDealerFetch.unsubscribe();

    yield deleteDealerFetch;

    yield put(removeDealerSuccess({ dealer }));
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* saveDealer({ reject, resolve, values }: any): Generator {
  try {
    const depositPrice = values[DealerFormFields.DepositAmounts]
      ? values[DealerFormFields.DepositAmounts].map((depositAmount: DepositAmount) => depositAmount.price)
      : [];
    // Reverse before save to align with potential configurator data expectations
    const depositPercent = values[DealerFormFields.DepositAmounts]
      ? values[DealerFormFields.DepositAmounts].map((depositAmount: DepositAmount) => depositAmount.percent)
      : [];
    // Reverse before save to align with potential configurator data expectations
    depositPrice.reverse();
    depositPercent.reverse();

    const clientId = values[DealerFormFields.ClientId];

    const dealer: Dealer = {
      clientId: values[DealerFormFields.ClientId],
      name: values[DealerFormFields.DealerName].trim(),
      id: values[DealerFormFields.Id],
      key: values[DealerFormFields.Key].trim(),
      city: values[DealerFormFields.City]?.trim(),
      state: values[DealerFormFields.State]?.trim(),
      zip: values[DealerFormFields.ZipCode]?.trim(),
      logoUrl: values[DealerFormFields.CustomLogoUrl]?.trim(),
      homeLinkUrl: values[DealerFormFields.HomeLinkUrl]?.trim(),
      phoneNumber: values[DealerFormFields.PhoneNumber]?.trim(),
      emailAddress: values[DealerFormFields.EmailAddress]?.trim(),
      quoteEmailReplyToAddress: values[DealerFormFields.QuoteEmailReplyToSame]
        ? values[DealerFormFields.EmailAddress]
        : values[DealerFormFields.QuoteEmailReplyToAddress],
      quoteEmailCopyAddress: values[DealerFormFields.QuoteEmailCopySame]
        ? values[DealerFormFields.EmailAddress]
        : values[DealerFormFields.QuoteEmailCopyAddress],
      depositPrice,
      depositPercent,
      depositAmount: getDepositAmountExpression(values[DealerFormFields.DepositAmounts]),
      integration: values[DealerFormFields.Integration]
        ? JSON.parse(values[DealerFormFields.Integration])
        : values[DealerFormFields.Integration],
      contactBarCustomHtml: values[DealerFormFields.ContactBarHtml]?.trim(),
      emailLogoUrl: values[DealerFormFields.EmailLogoUrl]?.trim(),
      dealerURL: values[DealerFormFields.DealerUrl]?.trim(),
      integrationsKey:
        values[DealerFormFields.IntegrationsKeySame] || !values[DealerFormFields.IntegrationsKey]
          ? values[DealerFormFields.Key]
          : values[DealerFormFields.IntegrationsKey],
    };

    // Check if a dealer with the same key already exists for the client when the id is undefined
    if (!dealer.id) {
      const { dealers } = (yield select(({ dealers: stateDealers }: AppState) => stateDealers)) as {
        dealers: Dealer[];
      };
      const dealerWithSameKey = dealers.find((d) => d.key === dealer.key);
      if (dealerWithSameKey) {
        yield call(reject, new SubmissionError({ _error: 'A dealer with the same key already exists' }));
        return;
      }
    }

    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };

    const updateDealerFetch = (yield put(
      dealerApi.endpoints.updateDealer.initiate({
        groupId,
        clientId,
        id: dealer.id,
        dealer,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    updateDealerFetch.unsubscribe();

    yield updateDealerFetch;

    yield put(saveDealerSuccess({ dealer }));

    const getAllDealersFetch = (yield put(
      dealerApi.endpoints.getDealersByGroup.initiate({
        clientId,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getAllDealersFetch.unsubscribe();

    const { data: { dealers = [] } = {} } = (yield getAllDealersFetch) as { data: { dealers: Dealer[] } };

    yield put(fetchDealersSuccess({ dealers, logoUrl: undefined }));
    // Update the users available dealers with the updated dealers
    yield put(
      setAvailableDealers(dealers.slice(0).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))),
    );

    yield call(resolve);
    yield put(reset(Forms.Dealer));
    yield put(setDealerDialog({ dialogDealer: undefined, dialogClientId: undefined, logoUrl: undefined }));
    yield put(closeDialog());
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* watchFetchDealers(): Generator {
  yield takeLatest(fetchDealersAction.type, fetchDealers);
}

function* watchRemoveDealer(): Generator {
  yield takeEvery(removeDealerAction.type, removeDealer);
}

function* watchSubmitDealerForm(): Generator {
  yield takeEvery(`${Forms.Dealer}_SUBMIT`, saveDealer);
}

export function* dealersSaga(): Generator {
  yield all([watchFetchDealers(), watchRemoveDealer(), watchSubmitDealerForm()]);
}
