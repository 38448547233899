import React from 'react';
import { makeStyles } from '@mui/styles';
import { Close, FileUploadOutlined } from '@mui/icons-material';
import { Grid2 as Grid, IconButton, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  dropArea: {
    height: '130px',
    outline: `1px dashed ${theme.palette.text.secondary}`,
    borderRadius: '6px',
    margin: '15px 0px',
    padding: '20px',
    placeContent: 'center',
  },
  uploadIcon: {
    color: theme.palette.text.secondary,
    height: '45px',
    width: '45px',
  },
  fileInput: {
    display: 'none',
  },
  fileText: {
    height: '45px',
  },
  fileName: {
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexShrink: 1,
    marginRight: '5px',
  },
}));

interface Props {
  setFile(file: File | undefined): void;
}

export const DragAndDropUpload: React.FC<Props> = ({ setFile: setFileFunc }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [file, setFile] = React.useState<File | undefined>();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newFile = e.dataTransfer.files[0];
    if (newFile) {
      setFile(newFile);
      setFileFunc(newFile);
    }
  };

  const handleChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target?.files?.[0];
    if (newFile) {
      setFile(newFile);
      setFileFunc(newFile);
    }
  };

  return (
    <Grid
      size={{ xs: 12 }}
      container
      className={classes.dropArea}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
    >
      <Grid size={{ xs: 12 }} container justifyContent="center" alignItems="center">
        <FileUploadOutlined className={classes.uploadIcon} />
      </Grid>
      {file ? (
        <Grid
          className={classes.fileText}
          size={{ xs: 12 }}
          container
          justifyContent="center"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid className={classes.fileName}>{file.name}</Grid>
          <Grid sx={{ flexShrink: 0 }}>
            <IconButton sx={{ padding: 0 }} size="small" aria-label="close" onClick={() => setFile(undefined)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.fileText} size={{ xs: 12 }} container justifyContent="center" alignItems="center">
          <Typography color="textSecondary" height="34px" alignContent="center">
            <label htmlFor="file-upload" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
              {t(I18nKeys.DragAndDropChooseFile)}
              <input
                id="file-upload"
                ref={inputRef}
                className={classes.fileInput}
                type="file"
                accept=".csv, .xlsx"
                onChange={handleChooseFile}
              />
            </label>
            <span>{t(I18nKeys.DragAndDropDropHere)}</span>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
