import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';
import React, { useMemo } from 'react';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { closeDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { I18nKeys } from '../constants/I18nKeys';
import { setDealerDialog, setLoading } from '../ducks/dealersSlice';
import { DealerForm } from './DealerForm';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DealerFormFields } from '../constants/FormFields';
import { DepositAmount } from '../types/DepositAmount';
import { DragAndDropUpload } from './DragAndDropUpload';
import { exportBulkDealersTemplate, importBulkDealers } from '../utils/dealerUtils';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { isIdeaRoomUser as isIdeaRoomUserFunc } from '../utils/userUtils';
import { unknownUser } from '../types/User';
import { validateSpreadsheetFile } from '../utils/fileUtils';
import { unknownGroup } from '../constants/Group';

enum DealerComponentTabs {
  SingleDealer = 'SINGLE_DEALER',
  BulkDealers = 'BULK_DEALERS',
}

const useStyles = makeStyles((theme: Theme) => ({
  bulkImportSteps: {
    color: theme.palette.text.secondary,
  },
}));

export const DealerDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [tab, setTab] = React.useState(DealerComponentTabs.SingleDealer);

  const [bulkDealersFile, setBulkDealersFile] = React.useState<File | undefined>();
  const [bulkDealersErrorMessage, setBulkDealersErrorMessage] = React.useState<string | undefined>(undefined);

  const { loading, dialogDealer, dialogClientId = '', logoUrl } = useAppSelector((state) => state.dealers);
  const isIdeaRoomUser = useAppSelector((state) => isIdeaRoomUserFunc(state.currentUser.user || unknownUser));
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const configuratorLink = useAppSelector(
    ({ dealers: { dialogClientId: clientId }, currentUser: { group: { configurators = [] } = unknownGroup } }) => {
      const selectedConfigurator = configurators.find((config) => clientId === `${config.key}-${config.vendor}`);
      return selectedConfigurator ? selectedConfigurator.url || '' : '';
    },
  );

  const closeAndResetDialog = (): void => {
    dispatch(reset(Forms.Dealer));
    dispatch(closeDialog());
    dispatch(setDealerDialog({ dialogDealer: undefined, dialogClientId: undefined, logoUrl: undefined }));
    setBulkDealersFile(undefined);
    setBulkDealersErrorMessage(undefined);
  };

  const initialValues = useMemo(() => {
    const depositAmounts: DepositAmount[] = [];
    if (dialogDealer) {
      if (dialogDealer.depositPrice instanceof Array && dialogDealer.depositPercent instanceof Array) {
        depositAmounts.push(
          ...dialogDealer.depositPrice.reduce<DepositAmount[]>((res, cur, index): DepositAmount[] => {
            res.push({ price: cur, percent: dialogDealer.depositPercent[index] });
            return res;
          }, []),
        );
      } else if (typeof dialogDealer.depositPrice === 'string' && typeof dialogDealer.depositPercent === 'string') {
        depositAmounts.push({ price: dialogDealer.depositPrice, percent: dialogDealer.depositPercent });
      }
    }
    // depositPrice and depositPercent are historically store descending. Reverse the depositAmounts objects so that
    // the form can use them in ascending order.
    depositAmounts.reverse();

    return dialogDealer
      ? {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.DealerName]: dialogDealer.name,
          [DealerFormFields.City]: dialogDealer.city,
          [DealerFormFields.State]: dialogDealer.state,
          [DealerFormFields.ZipCode]: dialogDealer.zip,
          [DealerFormFields.Key]: dialogDealer.key,
          [DealerFormFields.Id]: Number(dialogDealer.id) || undefined,
          [DealerFormFields.PhoneNumber]: dialogDealer.phoneNumber,
          [DealerFormFields.CustomLogoUrl]: dialogDealer.logoUrl,
          [DealerFormFields.DealerUrl]: dialogDealer.dealerURL,
          [DealerFormFields.HomeLinkUrl]: dialogDealer.homeLinkUrl,
          [DealerFormFields.EmailAddress]: dialogDealer.emailAddress,
          [DealerFormFields.QuoteEmailReplyToSame]: dialogDealer.emailAddress === dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailReplyToAddress]: dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailCopySame]: dialogDealer.emailAddress === dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.QuoteEmailCopyAddress]: dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.DepositAmounts]: depositAmounts,
          [DealerFormFields.Integration]: JSON.stringify(dialogDealer.integration),
          [DealerFormFields.ContactBarHtml]: dialogDealer.contactBarCustomHtml,
          [DealerFormFields.EmailLogoUrl]: dialogDealer.emailLogoUrl,
          [DealerFormFields.IntegrationsKeySame]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey,
          [DealerFormFields.IntegrationsKey]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey
              ? ''
              : dialogDealer.integrationsKey,
        }
      : {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.QuoteEmailReplyToSame]: true,
          [DealerFormFields.QuoteEmailCopySame]: true,
          [DealerFormFields.CustomLogoUrl]: logoUrl,
          [DealerFormFields.IntegrationsKeySame]: true,
        };
  }, [dialogDealer, dialogClientId, logoUrl]);

  return (
    <Dialog dialogKey={Dialogs.Dealer} onClosed={closeAndResetDialog}>
      <DialogTitle>{t(I18nKeys.DealerDialogTitle)}</DialogTitle>
      <Tabs
        value={tab}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: DealerComponentTabs): void => {
          setTab(newValue);
        }}
        style={{
          padding: '0px 24px',
        }}
        aria-label="dealers tabs"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          label={t(I18nKeys.DealerDialogTabSingleDealer)}
          value={DealerComponentTabs.SingleDealer}
          id="single-dealer-tab"
          aria-controls="single-dealer-tab"
        />
        {isIdeaRoomUser && (
          <Tab
            label={t(I18nKeys.DealerDialogTabBulkImport)}
            value={DealerComponentTabs.BulkDealers}
            icon={<IdeaRoomOnlyIndicator />}
            iconPosition="start"
            id="bulk-dealers-tab"
            aria-controls="bulk-dealers-tab"
          />
        )}
      </Tabs>
      <DialogContent style={{ padding: '20px 24px 0px' }}>
        {tab === DealerComponentTabs.SingleDealer && <DealerForm initialValues={initialValues} />}
        {tab === DealerComponentTabs.BulkDealers && (
          <>
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: t(I18nKeys.DealerDialogBulkImportHeader),
              }}
            />
            <div className={classes.bulkImportSteps}>
              <ol style={{ margin: '16px 0px 0px' }}>
                <li>{t(I18nKeys.DealerDialogBulkImportStep1)}</li>
                <Button style={{ margin: '15px 0px' }} variant="contained" onClick={() => exportBulkDealersTemplate(t)}>
                  {t(I18nKeys.DealerDialogBulkImportDownloadTemplate)}
                </Button>
                <li>{t(I18nKeys.DealerDialogBulkImportStep2)}</li>
                <DragAndDropUpload setFile={setBulkDealersFile} />
                <FormHelperText error style={{ height: '20px' }}>
                  {bulkDealersErrorMessage}
                </FormHelperText>
              </ol>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton loading={loading} onClick={closeAndResetDialog} color="primary">
            {t(I18nKeys.DialogCloseButton)}
          </LoadingButton>
          <LoadingButton
            disabled={tab === DealerComponentTabs.BulkDealers && !bulkDealersFile}
            loading={loading}
            onClick={() => {
              if (tab === DealerComponentTabs.SingleDealer) {
                dispatch(submit(Forms.Dealer));
              } else {
                dispatch(setLoading(true));
                const errorMessage = validateSpreadsheetFile(bulkDealersFile);
                setBulkDealersErrorMessage(errorMessage);

                if (!errorMessage && bulkDealersFile) {
                  importBulkDealers(dialogClientId, groupId, configuratorLink, bulkDealersFile, dispatch, t)
                    .catch((e) => {
                      setBulkDealersErrorMessage(e.message);
                      return Promise.reject(e);
                    })
                    .then(() => closeAndResetDialog());
                }
              }
            }}
            color="primary"
          >
            {t(I18nKeys.DialogSaveButton)}
          </LoadingButton>
        </>
      </DialogActions>
    </Dialog>
  );
};
