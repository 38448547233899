import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { Field, FieldArray, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { DealerFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { DepositAmount } from '../types/DepositAmount';
import { isCarportView } from '../utils/clientIdUtils';
import { required } from '../utils/reduxFormUtils';
import { DepositAmounts } from './DepositAmounts';
import { NewWindowLink } from './NewWindowLink';
import { CheckboxField } from './redux-form/CheckboxField';
import { InputField } from './redux-form/InputField';
import { isIdeaRoomUser } from '../utils/userUtils';
import { unknownGroup } from '../constants/Group';
import { I18nKeys } from '../constants/I18nKeys';
import { IdeaRoomLogo } from './IdeaRoomLogo';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setLoading } from '../ducks/dealersSlice';
import { unknownDealer } from '../types/Dealer';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: '100%',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
  imageOrMessageGrid: {
    height: theme.spacing(6),
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: theme.spacing(6),
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    alignSelf: 'center',
  },
  linkGrid: {
    display: 'flex',
  },
  checkboxLabel: {
    paddingLeft: theme.spacing(3),
  },
  hidden: {
    display: 'none',
  },
}));

interface FormData {
  [DealerFormFields.ClientId]: string;
  [DealerFormFields.DealerName]: string;
  [DealerFormFields.City]: string;
  [DealerFormFields.State]: string;
  [DealerFormFields.ZipCode]: string;
  [DealerFormFields.Key]: string;
  [DealerFormFields.Id]: number;
  [DealerFormFields.PhoneNumber]: string;
  [DealerFormFields.CustomLogoUrl]: string;
  [DealerFormFields.DealerUrl]: string;
  [DealerFormFields.HomeLinkUrl]: string;
  [DealerFormFields.EmailAddress]: string;
  [DealerFormFields.QuoteEmailReplyToSame]: boolean;
  [DealerFormFields.QuoteEmailReplyToAddress]: string;
  [DealerFormFields.QuoteEmailCopySame]: boolean;
  [DealerFormFields.QuoteEmailCopyAddress]: string;
  [DealerFormFields.DepositAmounts]: DepositAmount[];
  [DealerFormFields.Integration]: string;
  [DealerFormFields.ContactBarHtml]: string;
  [DealerFormFields.EmailLogoUrl]: string;
  [DealerFormFields.IntegrationsKeySame]: boolean;
  [DealerFormFields.IntegrationsKey]: string;
}

const DealerFormComponent: React.FC<InjectedFormProps<FormData>> = ({
  handleSubmit,
  initialValues,
  change,
  error,
}: InjectedFormProps<FormData>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    emailLogoUrl: initialEmailLogoUrl = '',
    dealerURL: initialDealerUrl = '',
    homeLinkUrl: initialHomeLinkUrl = '',
  } = useAppSelector((state) => state.dealers.dialogDealer || unknownDealer);
  const initialLogoUrl = useAppSelector(({ dealers: { dialogDealer, logoUrl } }) => dialogDealer?.logoUrl || logoUrl);
  const replyToSame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? dialogDealer.emailAddress === dialogDealer?.quoteEmailReplyToAddress : true,
  );
  const copySame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? dialogDealer.emailAddress === dialogDealer?.quoteEmailCopyAddress : true,
  );
  const integrationsKeySame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey : true,
  );
  const configuratorLink = useAppSelector(
    ({ dealers: { dialogClientId }, currentUser: { group: { configurators = [] } = unknownGroup } }) => {
      const selectedConfigurator = configurators.find((config) => dialogClientId === `${config.key}-${config.vendor}`);
      return selectedConfigurator ? selectedConfigurator.url || '' : '';
    },
  );
  const { user } = useAppSelector((state) => state.currentUser);

  const [customLogoLoaded, setCustomLogoLoaded] = React.useState<boolean>(false);
  const [customLogoUrl, setCustomLogoUrl] = React.useState(initialLogoUrl);
  const [replyToSameChecked, setReplyToSameChecked] = React.useState<boolean>(replyToSame);
  const [copySameChecked, setCopySameChecked] = React.useState<boolean>(copySame);
  const [integrationsSameChecked, setIntegrationsSameChecked] = React.useState<boolean>(integrationsKeySame);
  const [advancedPanelExpanded, setAdvancedPanelExpanded] = React.useState<boolean>(false);
  const [dealerUrl, setDealerUrl] = React.useState(initialDealerUrl);
  const [homeLinkUrl, setHomeLinkUrl] = React.useState(initialHomeLinkUrl);
  const [emailLogoLoaded, setEmailLogoLoaded] = React.useState<boolean>(false);
  const [emailLogoUrl, setEmailLogoUrl] = React.useState(initialEmailLogoUrl);

  useEffect(() => setReplyToSameChecked(replyToSame), [replyToSame]);
  useEffect(() => setReplyToSameChecked(copySame), [copySame]);
  useEffect(() => setIntegrationsSameChecked(integrationsKeySame), [integrationsKeySame]);
  useEffect(() => setCustomLogoUrl(initialLogoUrl), [initialLogoUrl]);
  useEffect(() => setEmailLogoUrl(initialEmailLogoUrl), [initialEmailLogoUrl]);
  useEffect(() => setDealerUrl(initialDealerUrl), [initialDealerUrl]);
  useEffect(() => setHomeLinkUrl(initialHomeLinkUrl), [initialHomeLinkUrl]);

  const showCarportViewFields = isCarportView(initialValues[DealerFormFields.ClientId] || '');

  const handleDealerIdChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const url = `${configuratorLink}${configuratorLink.includes('?') ? '&' : '?'}dealer=${event.target.value}`;
    change(DealerFormFields.DealerUrl, url);
    setDealerUrl(url);
    // If the home link URL hasn't been changed, update to new dealer ID
    if (!initialHomeLinkUrl || initialHomeLinkUrl === initialDealerUrl) {
      change(DealerFormFields.HomeLinkUrl, url);
      setHomeLinkUrl(url);
    }
  };

  const handleCustomLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomLogoLoaded(false);
    setCustomLogoUrl(event.target.value);
  };

  const handleEmailLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailLogoLoaded(false);
    setEmailLogoUrl(event.target.value);
  };

  const handleReplyToSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setReplyToSameChecked(event.target.checked);
  };

  const handleCopySameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCopySameChecked(event.target.checked);
  };

  const handleIntegrationsSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIntegrationsSameChecked(event.target.checked);
  };

  const handleAdvancedPanelChange = (event: React.ChangeEvent<any>, expanded: boolean): void => {
    setAdvancedPanelExpanded(expanded);
  };

  const handleDealerUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDealerUrl(event.target.value);
  };

  const handleHomeLinkUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHomeLinkUrl(event.target.value);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit((values: FormData): Promise<AnyAction> => {
          dispatch(setLoading(true));
          return new Promise(
            (resolve, reject): AnyAction =>
              // eslint-disable-next-line no-promise-executor-return
              dispatch({
                type: `${Forms.Dealer}_SUBMIT`,
                values,
                resolve,
                reject,
              }),
          );
        })}
        onReset={() => {
          setCustomLogoUrl(undefined);
          setCustomLogoLoaded(false);
          setReplyToSameChecked(true);
          setCopySameChecked(true);
          setIntegrationsSameChecked(true);
          setAdvancedPanelExpanded(false);
        }}
      >
        <Grid container direction="column">
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ xs: 12 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                autoFocus
                component={InputField}
                required
                label={t(I18nKeys.DealerDialogDealerNameField)}
                name={DealerFormFields.DealerName}
                validate={required}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ xs: 12 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                required
                label={t(I18nKeys.DealerDialogDealerIdField)}
                validate={required}
                name={DealerFormFields.Key}
                onChange={handleDealerIdChanged}
              />
              <FormHelperText>{t(I18nKeys.DealerDialogDealerIdFieldHelpText)}</FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ xs: 12 }} className={classes.linkGrid}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                props={{ disabled: true }}
                label={t(I18nKeys.DealerDialogDealerSiteLinkField)}
                name={DealerFormFields.DealerUrl}
                onChange={handleDealerUrlChange}
              />
              <NewWindowLink link={dealerUrl} />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ sm: 7, xs: 12 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogDealerCityField)}
                name={DealerFormFields.City}
              />
            </Grid>
            <Grid size={{ sm: 2, xs: 6 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogDealerStateField)}
                name={DealerFormFields.State}
              />
            </Grid>
            <Grid size={{ sm: 3, xs: 6 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogDealerZipField)}
                name={DealerFormFields.ZipCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ xs: 12 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.FieldPhone)}
                name={DealerFormFields.PhoneNumber}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid size={{ xs: 12 }}>
              <Field
                className={classes.field}
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogDealerEmailField)}
                name={DealerFormFields.EmailAddress}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box>
                <Field
                  id={DealerFormFields.QuoteEmailReplyToSame}
                  name={DealerFormFields.QuoteEmailReplyToSame}
                  component={CheckboxField}
                  onChange={handleReplyToSameChange}
                />
                <Typography component="span" className={classes.checkboxLabel}>
                  <>{t(I18nKeys.DealerDialogEmailReplyToSameCheckbox)}</>
                </Typography>
              </Box>
              {!replyToSameChecked && (
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  label={t(I18nKeys.DealerDialogEmailReplyToSameField)}
                  name={DealerFormFields.QuoteEmailReplyToAddress}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box>
                <Field
                  id={DealerFormFields.QuoteEmailCopySame}
                  name={DealerFormFields.QuoteEmailCopySame}
                  component={CheckboxField}
                  onChange={handleCopySameChange}
                />
                <Typography component="span" className={classes.checkboxLabel}>
                  <>{t(I18nKeys.DealerDialogEmailCopySameCheckbox)}</>
                </Typography>
              </Box>
              {!copySameChecked && (
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  label={t(I18nKeys.DealerDialogEmailCopySameField)}
                  name={DealerFormFields.QuoteEmailCopyAddress}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box>
                <Field
                  id={DealerFormFields.IntegrationsKeySame}
                  name={DealerFormFields.IntegrationsKeySame}
                  component={CheckboxField}
                  onChange={handleIntegrationsSameChange}
                />
                <Typography component="span" className={classes.checkboxLabel}>
                  <>{t(I18nKeys.DealerDialogCustomDealerIdCheckbox)}</>
                </Typography>
              </Box>
              {!integrationsSameChecked && (
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  label={t(I18nKeys.DealerDialogCustomDealerIdField)}
                  name={DealerFormFields.IntegrationsKey}
                />
              )}
            </Grid>
          </Grid>
          {showCarportViewFields && (
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }}>
                <FieldArray name={DealerFormFields.DepositAmounts} component={DepositAmounts} />
              </Grid>
            </Grid>
          )}
          {isIdeaRoomUser(user) && (
            <Accordion expanded={advancedPanelExpanded} onChange={handleAdvancedPanelChange}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <IdeaRoomLogo className={classes.image} />
                <Typography>{t(I18nKeys.DealerDialogAdvancedSection)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} className={classes.row}>
                  <Grid size={{ sm: 7, xs: 12 }}>
                    <Field
                      className={classes.field}
                      autoComplete="off"
                      component={InputField}
                      label={t(I18nKeys.DealerDialogCustomLogoUrlField)}
                      name={DealerFormFields.CustomLogoUrl}
                      onChange={handleCustomLogoUrlChanged}
                    />
                  </Grid>
                  <Grid size={{ sm: 5, xs: 12 }} className={classes.imageOrMessageGrid}>
                    <img
                      style={{
                        visibility: customLogoLoaded && !!customLogoUrl ? 'initial' : 'hidden',
                        position: customLogoLoaded && !!customLogoUrl ? 'initial' : 'absolute',
                      }}
                      src={customLogoUrl}
                      alt="Custom Logo"
                      className={classes.image}
                      onLoad={(): void => setCustomLogoLoaded(true)}
                      onError={(): void => setCustomLogoLoaded(false)}
                    />
                    {(!customLogoLoaded || !customLogoUrl) && (
                      <Box className={classes.error}>{t(I18nKeys.DealerDialogCustomLogoError)}</Box>
                    )}
                  </Grid>
                  <Grid size={{ sm: 7, xs: 12 }}>
                    <Field
                      className={classes.field}
                      autoComplete="off"
                      component={InputField}
                      label={t(I18nKeys.DealerDialogEmailLogoUrlField)}
                      name={DealerFormFields.EmailLogoUrl}
                      onChange={handleEmailLogoUrlChanged}
                    />
                  </Grid>
                  <Grid size={{ sm: 5, xs: 12 }} className={classes.imageOrMessageGrid}>
                    <img
                      style={{
                        visibility: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'hidden',
                        position: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'absolute',
                      }}
                      src={emailLogoUrl}
                      alt="Email Logo"
                      className={classes.image}
                      onLoad={(): void => setEmailLogoLoaded(true)}
                      onError={(): void => setEmailLogoLoaded(false)}
                    />
                    {(!emailLogoLoaded || !emailLogoUrl) && (
                      <Box className={classes.error}>{t(I18nKeys.DealerDialogEmailLogoError)}</Box>
                    )}
                  </Grid>
                  <Grid size={{ xs: 12 }} className={classes.linkGrid}>
                    <Field
                      className={classes.field}
                      autoComplete="off"
                      component={InputField}
                      label={t(I18nKeys.DealerDialogHomeLinkField)}
                      name={DealerFormFields.HomeLinkUrl}
                      onChange={handleHomeLinkUrlChange}
                    />
                    <NewWindowLink link={homeLinkUrl} />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <Field
                      className={classes.field}
                      autoComplete="off"
                      component={InputField}
                      label={t(I18nKeys.DealerDialogContactBarHTMLField)}
                      name={DealerFormFields.ContactBarHtml}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <Field
                      className={classes.field}
                      autoComplete="off"
                      component={InputField}
                      label={t(I18nKeys.DealerDialogIntegrationField)}
                      name={DealerFormFields.Integration}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <Grid container spacing={2} className={classes.row} />
        </Grid>
      </Form>
      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </>
  );
};

export const DealerForm = reduxForm<FormData>({ form: Forms.Dealer, enableReinitialize: true })(DealerFormComponent);
