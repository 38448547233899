/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Dialogs } from '../constants/Dialogs';
import {
  CliendDataEmailDomainDialogOptions,
  ClientDataCreateBranchDialogOptions,
  ClientDataGoToSourceDataDialogOptions,
  ClientDataRollbackDialogOptions,
  DialogState,
  IntegrationDeactivateOptions,
  IntegrationOptions,
  LoadingDialogOptions,
  SetupPaymentIntegrationOptions,
} from '../types/DialogState';
import { Group } from '../types/Group';
import { Configurator } from '../types/Configurator';

const InitialDialogState: DialogState = {
  key: undefined,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: InitialDialogState,
  reducers: {
    closeDialog() {
      return { key: undefined };
    },

    openDialog(
      state,
      action: PayloadAction<
        | { dialog: Dialogs }
        | { dialog: Dialogs.ClientDataCreateBranch; options: ClientDataCreateBranchDialogOptions }
        | { dialog: Dialogs.ClientDataRollback; options?: ClientDataRollbackDialogOptions }
        | { dialog: Dialogs.ClientDataGoToSourceData; options: ClientDataGoToSourceDataDialogOptions }
        | { dialog: Dialogs.EmailDomain; options?: CliendDataEmailDomainDialogOptions }
        | { dialog: Dialogs.Loading; options?: LoadingDialogOptions }
        | {
            dialog: Dialogs.SetupPaymentIntegration | Dialogs.SetupPaymentIntegrationConfirmation;
            options?: SetupPaymentIntegrationOptions;
          }
        | {
            dialog: Dialogs.Integations;
            options?: IntegrationOptions;
          }
        | {
            dialog: Dialogs.IntegationsDeactivate;
            options?: IntegrationDeactivateOptions;
          }
      >,
    ) {
      const { dialog, options } = action.payload as { dialog: Dialogs; options?: DialogState['options'] };
      return { ...state, key: dialog, options };
    },

    setGroup(state, action: PayloadAction<Group>) {
      const { payload: group } = action;
      return { ...state, group };
    },

    setConfigurator(state, action: PayloadAction<Configurator>) {
      const { payload: configurator } = action;
      return { ...state, configurator };
    },

    setForm(state, action: PayloadAction<string>) {
      const { payload: form } = action;
      return { ...state, form };
    },
  },
});

export const { closeDialog, openDialog, setGroup, setConfigurator, setForm } = dialogSlice.actions;
