import { TFunction } from 'react-i18next';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { I18nKeys } from '../constants/I18nKeys';
import { exportToCSV, importSpreadsheetFile } from './fileUtils';
import { DealerFormFields } from '../constants/FormFields';
import { dealerApi } from '../services/dealerApi';
import { AppState } from '../types/AppState';
import { Dealer, unknownDealer } from '../types/Dealer';
import { fetchDealers } from '../ducks/dealersSlice';

const headersMap = [
  { field: DealerFormFields.DealerName, key: 'name', header: I18nKeys.DealerDialogDealerNameField },
  { field: DealerFormFields.Key, key: 'key', header: I18nKeys.DealerDialogDealerIdField },
  { field: DealerFormFields.City, key: 'city', header: I18nKeys.DealerDialogDealerCityField },
  { field: DealerFormFields.State, key: 'state', header: I18nKeys.DealerDialogDealerStateField },
  { field: DealerFormFields.ZipCode, key: 'zip', header: I18nKeys.DealerDialogDealerZipField },
  { field: DealerFormFields.PhoneNumber, key: 'phoneNumber', header: I18nKeys.FieldPhone },
  { field: DealerFormFields.EmailAddress, key: 'emailAddress', header: I18nKeys.DealerDialogDealerEmailField },
  {
    field: DealerFormFields.QuoteEmailReplyToAddress,
    key: 'quoteEmailReplyToAddress',
    header: I18nKeys.DealerDialogEmailReplyToSameField,
    optional: true,
  },
  {
    field: DealerFormFields.QuoteEmailCopyAddress,
    key: 'quoteEmailCopyAddress',
    header: I18nKeys.DealerDialogEmailCopySameField,
    optional: true,
  },
  {
    field: DealerFormFields.IntegrationsKey,
    key: 'integrationsKey',
    header: I18nKeys.DealerDialogCustomDealerIdField,
    optional: true,
  },
];

/**
 * Get the displayed header for a column in the bulk dealer spreadsheet
 *
 * @param header header info
 * @param t i18n translation function
 * @returns header string
 */
const getDealerColumnHeader = (
  { header, optional }: { key: string; header: I18nKeys; optional?: boolean },
  t: TFunction,
): string => `${t(header)}${optional ? ' (Optional)' : ''}`;

/**
 * Export a template that can be edited to import multiple dealers.
 *
 * @param t i18n translation function
 */
export const exportBulkDealersTemplate = (t: TFunction) => {
  const headers = headersMap.map((header) => getDealerColumnHeader(header, t));
  const rows = [
    [
      'Example Dealer',
      'example-dealer',
      'Boise',
      'Idaho',
      '83701',
      '555-555-5555',
      'dealer@example.com',
      '',
      'copy@example.com',
      '',
    ],
  ];

  exportToCSV(t(I18nKeys.DealerDialogBulkImportTemplateTitle), headers, rows);
};

/**
 * Transform a bulk dealer row into a dealer object
 *
 * @param clientId client id to assign dealers to
 * @param configuratorLink configurator link to use for dealerURL
 * @param row row to transform
 * @returns dealer object
 */
export const mapDealerRowToDealer = (clientId: string, configuratorLink: string, row: string[]) =>
  headersMap.reduce(
    (dealer, { field, key }, i) => {
      let value = row[i];
      if (
        [DealerFormFields.QuoteEmailReplyToAddress, DealerFormFields.QuoteEmailCopyAddress].includes(field) &&
        !value
      ) {
        value = dealer.emailAddress;
      }

      return {
        ...dealer,
        ...(field === DealerFormFields.Key
          ? { dealerURL: `${configuratorLink}${configuratorLink.includes('?') ? '&' : '?'}dealer=${value}` }
          : {}),
        [key]: value,
      };
    },
    {
      ...unknownDealer,
      clientId,
    },
  );

/**
 * Imports multiple dealers from a spreadsheet file
 *
 * @param clientId client id to assign dealers to
 * @param file file to be imported
 */
export const importBulkDealers = async (
  clientId: string,
  groupId: string,
  configuratorLink: string,
  file: File,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  t: TFunction,
): Promise<void> => {
  try {
    const cellDataMatrix = await importSpreadsheetFile(file);
    const [headers] = cellDataMatrix;

    // Ensure the headers match the expected headers from the template
    if (
      headers.length !== headersMap.length ||
      headers.some((header, i) => header !== getDealerColumnHeader(headersMap[i], t))
    ) {
      throw new Error('Column headers must match the template headers.');
    }

    const dealers = cellDataMatrix.slice(1).map((row) => mapDealerRowToDealer(clientId, configuratorLink, row));
    const getAllDealersFetch = dispatch(
      dealerApi.endpoints.getDealersByGroup.initiate({
        clientId,
        groupId,
      }) as any,
    );
    getAllDealersFetch.unsubscribe();
    const { data: { dealers: existingDealers = [] } = {} } = (await getAllDealersFetch) as {
      data: { dealers: Dealer[] };
    };

    // Ensure each of the dealers has a key and that no duplicate keys exist
    dealers.forEach(({ key, name }) => {
      if (!key) {
        throw new Error(`Dealer "${name}" does not have a key.`);
      }
      if (existingDealers.some(({ key: existingKey }) => existingKey === key)) {
        throw new Error(`A dealer with the key "${key}" already exists.`);
      }
    });

    await dispatch(
      dealerApi.endpoints.addDealers.initiate({
        groupId,
        clientId,
        dealers,
      }),
    )
      .catch((e) => {
        throw new Error(e.message);
      })
      .then(() => {
        dispatch(fetchDealers(clientId));
      });
  } catch (error) {
    const { message } = error as Error;
    throw new Error(message);
  }
};
