/* eslint-disable react-hooks/rules-of-hooks */
import useWindowFocus from 'use-window-focus';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { unknownGroup } from '../constants/Group';
import { useAppSelector } from '../hooks';
import {
  useSharedDeleteBranchMutation,
  useGetBranchDiffMergesQuery,
  useGetClientDataBranchDiffQuery,
  useGetClientDataBranchTableDiffQuery,
  useGetClientDataCellMetadataQuery,
  useGetVisibleStylesQuery,
  useSharedPublishClientDataMutation,
} from '../services/clientDataApi';
import { AppState } from '../types/AppState';
import { Commit } from '../types/Commit';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { OnlyExistingKeys } from '../types/Repo';
import {
  useGetComponentCategoriesQuery,
  useGetComponentCategoryItemsQuery,
  useGetClientUpdateRegionsQuery,
  useGetSizeBasedCategoriesQuery,
  useGetSizeBasedCategoryPricingSheetsQuery,
  useGetSizeBasedPricingSheetPricesQuery,
} from '../services/pricingApi';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import {
  ClientUpdateCategory,
  ComponentCategoryItem,
  ComponentCategoryItemWithConditions,
} from '../types/PricingClientUpdate';
import { ClientDataType } from '../constants/ClientDataType';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import { Region } from '../types/Region';
import { PricingTab } from '../constants/Pricing';
import {
  getClientUpdateBranch,
  getClientUpdatePricingTables,
  getComponentCategoryItems,
  isClientUpdatePricingTab,
} from '../utils/pricingClientUpdateUtils';
import { PricingSheet } from '../types/PricingSheet';
import { CELL_METADATA_TABLE } from '../constants/ClientData';
import { AuthStatus } from '../constants/AuthStatus';
import { getPricingSheetTable } from '../utils/pricingSheetUtils';

const EMPTY_COMMITS_REFERENCE: ReadonlyArray<Commit> = [];
const EMPTY_CLIENT_UPDATE_REGIONS: ReadonlyArray<Region> = [];
const EMPTY_CELL_METADATA_REFERENCE: ReadonlyArray<CellMetadata> = [];
const EMPTY_CLIENT_UPDATE_STYLES: ReadonlyArray<{ key: string; label: string }> = [];
const EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE: ReadonlyArray<ClientDataTableRowDiff> = [];

type UseComponentPricingRepoReturn<
  UseClientUpdateCategories,
  UseClientUpdateDiffs,
  UseComponentCategoryItemsWithConditions,
  UseSizeBasedCategoryPricingSheets,
  UseSizeBasedCategoryPricingSheetsPrices,
  UseClientUpdateBranchMergeCommit,
  UseClientUpdateRegions,
  UseCellMetadata,
  UseClientUpdateStyles,
  UseCellMetadataDiff,
> = OnlyExistingKeys<{
  isLoadingClientUpdateCategoriesList: UseClientUpdateCategories extends true ? boolean : never;
  clientUpdateCategoriesList: UseClientUpdateCategories extends true ? ClientUpdateCategory[] | undefined : never;
  isLoadingClientUpdateDiffs: UseClientUpdateDiffs extends true ? boolean : never;
  clientUpdateDiffs: UseClientUpdateDiffs extends true
    ? {
        table: string;
        changes: ClientDataTableRowDiff[];
      }[]
    : never;
  isLoadingComponentCategoryItemsWithConditions: UseComponentCategoryItemsWithConditions extends true ? boolean : never;
  componentCategoryItemsWithConditions: UseComponentCategoryItemsWithConditions extends true
    ? ComponentCategoryItemWithConditions[] | undefined
    : never;
  isFetchingSizeBasedCategoryPricingSheets: UseSizeBasedCategoryPricingSheets extends true ? boolean : never;
  isLoadingSizeBasedCategoryPricingSheets: UseSizeBasedCategoryPricingSheets extends true ? boolean : never;
  errorLoadingSizeBasedCategoryPricingSheets: UseSizeBasedCategoryPricingSheets extends true ? boolean : never;
  sizeBasedCategoryPricingSheets: UseSizeBasedCategoryPricingSheets extends true ? PricingSheet[] : never;
  isFetchingSizeBasedCategoryPricingSheetPrices: UseSizeBasedCategoryPricingSheetsPrices extends true ? boolean : never;
  isLoadingSizeBasedCategoryPricingSheetPrices: UseSizeBasedCategoryPricingSheetsPrices extends true ? boolean : never;
  errorLoadingSizeBasedCategoryPricingSheetPrices: UseSizeBasedCategoryPricingSheetsPrices extends true
    ? boolean
    : never;
  sizeBasedCategoryPricingSheetPrices: UseSizeBasedCategoryPricingSheetsPrices extends true
    ? PricingSheet | undefined
    : never;
  clientUpdateBranchMergeCommit: UseClientUpdateBranchMergeCommit extends true ? Commit : never;
  isLoadingClientUpdateRegions: UseClientUpdateRegions extends true ? boolean : never;
  clientUpdateRegions: UseClientUpdateRegions extends true ? Region[] | undefined : never;
  isLoadingCellMetadata: UseCellMetadata extends true ? boolean : never;
  cellMetadata: UseCellMetadata extends true ? CellMetadata[] | undefined : never;
  isLoadingClientUpdateStyles: UseClientUpdateStyles extends true ? boolean : never;
  clientUpdateStyles: UseClientUpdateStyles extends true ? { key: string; label: string }[] : never;
  isLoadingCellMetadataDiff: UseCellMetadataDiff extends true ? boolean : never;
  cellMetadataDiff: UseCellMetadataDiff extends true ? CellMetadata[] | undefined : never;
  isFetchingClientUpdateDiffs: UseClientUpdateDiffs extends true ? boolean : never;
}>;

export const useClientUpdatePricingRepo = <
  UseClientUpdateCategories extends boolean = false,
  UseClientUpdateDiffs extends boolean = false,
  UseComponentCategoryItemsWithConditions extends boolean = false,
  UseSizeBasedCategoryPricingSheets extends boolean = false,
  UseSizeBasedCategoryPricingSheetsPrices extends boolean = false,
  UseClientUpdateBranchMergeCommit extends boolean = false,
  UseClientUpdateRegions extends boolean = false,
  UseCellMetadata extends boolean = false,
  UseClientUpdateStyles extends boolean = false,
  UseCellMetadataDiff extends boolean = false,
>({
  useClientUpdateCategories,
  useClientUpdateDiffs,
  useComponentCategoryItemsWithConditions,
  useSizeBasedCategoryPricingSheets,
  useSizeBasedCategoryPricingSheetsPrices,
  useClientUpdateBranchMergeCommit,
  useClientUpdateRegions,
  useCellMetadata,
  useClientUpdateStyles,
  useCellMetadataDiff,
}: {
  useClientUpdateCategories?: UseClientUpdateCategories;
  useClientUpdateDiffs?: UseClientUpdateDiffs;
  useComponentCategoryItemsWithConditions?: UseComponentCategoryItemsWithConditions;
  useSizeBasedCategoryPricingSheets?: UseSizeBasedCategoryPricingSheets;
  useSizeBasedCategoryPricingSheetsPrices?: UseSizeBasedCategoryPricingSheetsPrices;
  useClientUpdateBranchMergeCommit?: UseClientUpdateBranchMergeCommit;
  useClientUpdateRegions?: UseClientUpdateRegions;
  useCellMetadata?: UseCellMetadata;
  useClientUpdateStyles?: UseClientUpdateStyles;
  useCellMetadataDiff?: UseCellMetadataDiff;
} = {}): UseComponentPricingRepoReturn<
  UseClientUpdateCategories,
  UseClientUpdateDiffs,
  UseComponentCategoryItemsWithConditions,
  UseSizeBasedCategoryPricingSheets,
  UseSizeBasedCategoryPricingSheetsPrices,
  UseClientUpdateBranchMergeCommit,
  UseClientUpdateRegions,
  UseCellMetadata,
  UseClientUpdateStyles,
  UseCellMetadataDiff
> => {
  const clientId = useAppSelector(
    ({ viewer: { selectedClientId, selectedTabId }, clientData: { clientId: clientDataClientId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataClientId || selectedTabId || selectedClientId || ''),
  );
  const { authStatus, group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const isSignedIn = authStatus === AuthStatus.SignedIn;
  const isWindowFocused = useWindowFocus();

  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId) || '';
  const componentDataBranch =
    useAppSelector((state) => state.pricing.component.pricingDataBranch) || ClientDataBranch.Main;
  const componentCategoryKey = useAppSelector((state) => state.pricing.component.selectedCategoryKey);
  const sizeBasedBranch = useAppSelector((state) => state.pricing.sizeBased.pricingDataBranch) || ClientDataBranch.Main;
  const sizeBasedCategoryKey = useAppSelector((state) => state.pricing.sizeBased.selectedCategoryKey);
  const sizeBasedPricingSheetId = useAppSelector((state) => state.pricing.sizeBased.selectedPricingSheetId);

  const categoryKey = pricingTab === PricingTab.Component ? componentCategoryKey : sizeBasedCategoryKey;
  const clientUpdateBranch = pricingTab === PricingTab.Component ? componentDataBranch : sizeBasedBranch;

  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const [, { isLoading: isPublishing }] = useSharedPublishClientDataMutation();

  const result: any = {};

  const skip = !isSignedIn || !clientId || isDeletingBranch || isPublishing || !isClientUpdatePricingTab(pricingTab);
  if (useClientUpdateCategories) {
    const useGetClientUpdateCategoriesQuery =
      pricingTab === PricingTab.SizeBased ? useGetSizeBasedCategoriesQuery : useGetComponentCategoriesQuery;
    const { currentData: clientUpdateCategoriesList, isLoading: isLoadingClientUpdateCategoriesList } =
      useGetClientUpdateCategoriesQuery(
        {
          groupId,
          clientId,
          branch: clientUpdateBranch,
        },
        {
          skip,
        },
      );
    result.isLoadingClientUpdateCategoriesList =
      clientUpdateCategoriesList === undefined || isLoadingClientUpdateCategoriesList;
    result.clientUpdateCategoriesList = clientUpdateCategoriesList;
  }

  if (useComponentCategoryItemsWithConditions || useClientUpdateBranchMergeCommit || useClientUpdateDiffs) {
    const {
      currentData: componentCategoryItemsWithConditions,
      isLoading: isLoadingComponentCategoryItemsWithConditions,
    } = useGetComponentCategoryItemsQuery(
      {
        groupId,
        clientId,
        branch: componentDataBranch,
        category: componentCategoryKey as ComponentCategoryKey,
      },
      {
        skip: skip || !componentCategoryKey || pricingTab !== PricingTab.Component,
        refetchOnMountOrArgChange: true,
      },
    );
    result.isLoadingComponentCategoryItemsWithConditions =
      componentCategoryItemsWithConditions === undefined || isLoadingComponentCategoryItemsWithConditions;
    result.componentCategoryItemsWithConditions = componentCategoryItemsWithConditions;
  }

  if (useSizeBasedCategoryPricingSheets) {
    const {
      currentData: sizeBasedCategoryPricingSheets,
      isFetching: isFetchingSizeBasedCategoryPricingSheets,
      isLoading: isLoadingSizeBasedCategoryPricingSheets,
      isError: errorLoadingSizeBasedCategoryPricingSheets,
    } = useGetSizeBasedCategoryPricingSheetsQuery(
      {
        groupId,
        clientId,
        branch: sizeBasedBranch,
        category: sizeBasedCategoryKey as SizeBasedCategoryKey,
      },
      {
        skip: skip || !sizeBasedCategoryKey || pricingTab !== PricingTab.SizeBased,
        refetchOnMountOrArgChange: true,
      },
    );
    result.isFetchingSizeBasedCategoryPricingSheets = isFetchingSizeBasedCategoryPricingSheets;
    result.isLoadingSizeBasedCategoryPricingSheets =
      sizeBasedCategoryPricingSheets === undefined || isLoadingSizeBasedCategoryPricingSheets;
    result.errorLoadingSizeBasedCategoryPricingSheets = errorLoadingSizeBasedCategoryPricingSheets;
    result.sizeBasedCategoryPricingSheets = sizeBasedCategoryPricingSheets;
  }

  if (useSizeBasedCategoryPricingSheetsPrices) {
    const {
      currentData: sizeBasedCategoryPricingSheetPrices,
      isFetching: isFetchingSizeBasedCategoryPricingSheetPrices,
      isLoading: isLoadingSizeBasedCategoryPricingSheetPrices,
      error: errorLoadingSizeBasedCategoryPricingSheetPrices,
    } = useGetSizeBasedPricingSheetPricesQuery(
      {
        groupId,
        clientId,
        branch: sizeBasedBranch,
        category: sizeBasedCategoryKey as SizeBasedCategoryKey,
        id: sizeBasedPricingSheetId || '',
      },
      {
        skip: skip || !sizeBasedPricingSheetId || !sizeBasedCategoryKey || pricingTab !== PricingTab.SizeBased,
        refetchOnMountOrArgChange: true,
      },
    );
    result.isFetchingSizeBasedCategoryPricingSheetPrices = isFetchingSizeBasedCategoryPricingSheetPrices;
    result.isLoadingSizeBasedCategoryPricingSheetPrices =
      sizeBasedCategoryPricingSheetPrices === undefined || isLoadingSizeBasedCategoryPricingSheetPrices;
    result.errorLoadingSizeBasedCategoryPricingSheetPrices = errorLoadingSizeBasedCategoryPricingSheetPrices;
    result.sizeBasedCategoryPricingSheetPrices = sizeBasedCategoryPricingSheetPrices;
  }

  const selectedTables = getClientUpdatePricingTables(
    clientId,
    categoryKey,
    pricingTab,
    getComponentCategoryItems(result?.componentCategoryItemsWithConditions || []) as ComponentCategoryItem[],
  );
  if (useClientUpdateDiffs) {
    const {
      currentData: clientUpdateDiffs = [],
      isLoading: isLoadingClientUpdateDiffs,
      isFetching: isFetchingClientUpdateDiffs,
    } = useGetClientDataBranchDiffQuery(
      {
        clientId,
        groupId,
        dataType: ClientDataType.Supplier,
        branch: clientUpdateBranch,
        tables: selectedTables,
      },
      {
        skip: skip || selectedTables.length === 0,
        refetchOnMountOrArgChange: true,
      },
    );
    result.isFetchingClientUpdateDiffs = isFetchingClientUpdateDiffs;
    result.isLoadingClientUpdateDiffs = isLoadingClientUpdateDiffs;
    result.clientUpdateDiffs = clientUpdateDiffs;
  }

  if (useClientUpdateBranchMergeCommit) {
    const { currentData: clientUpdateBranchMergeCommit = EMPTY_COMMITS_REFERENCE } = useGetBranchDiffMergesQuery(
      {
        dataType: ClientDataType.Supplier,
        clientId,
        groupId,
        branch: getClientUpdateBranch(pricingTab),
        limit: 1,
        fromBranch: ClientDataBranch.Main,
        tables: selectedTables,
      },
      {
        skip:
          skip ||
          clientUpdateBranch !== getClientUpdateBranch(pricingTab) ||
          result.isLoadingSizeBasedCategoryPricingSheets,
        refetchOnMountOrArgChange: true,
        // refetch every minute
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 60000 : undefined,
      },
    );
    result.clientUpdateBranchMergeCommit = clientUpdateBranchMergeCommit ? clientUpdateBranchMergeCommit[0] : undefined;
  }

  if (useClientUpdateRegions) {
    const { currentData: clientUpdateRegions, isLoading: isLoadingClientUpdateRegions } =
      useGetClientUpdateRegionsQuery(
        {
          groupId,
          clientId,
          branch: clientUpdateBranch,
        },
        {
          skip,
          refetchOnMountOrArgChange: true,
        },
      );

    let regions = clientUpdateRegions;
    // For regions with duplicate price columns, only show the first one
    if (regions) {
      regions = clientUpdateRegions?.filter(
        (region, i, arr) => !arr.slice(0, i).some(({ priceColumn }) => priceColumn === region.priceColumn),
      );
    }
    result.isLoadingClientUpdateRegions = clientUpdateRegions === undefined || isLoadingClientUpdateRegions;
    result.clientUpdateRegions = regions || EMPTY_CLIENT_UPDATE_REGIONS;
  }

  if (useCellMetadata) {
    const pricingSheetTable = getPricingSheetTable(
      clientId,
      pricingTab,
      result?.sizeBasedCategoryPricingSheetPrices?.category,
    );
    const { currentData: cellMetadata = EMPTY_CELL_METADATA_REFERENCE, isLoading: isLoadingCellMetadata } =
      useGetClientDataCellMetadataQuery(
        {
          dataType: ClientDataType.Supplier,
          clientId,
          table: pricingSheetTable || '',
          branch: clientUpdateBranch || ClientDataBranch.Main,
          groupId,
        },
        {
          skip: skip || pricingTab !== PricingTab.SizeBased || !pricingSheetTable,
          refetchOnMountOrArgChange: true,
        },
      );
    result.isLoadingCellMetadata = cellMetadata === undefined || isLoadingCellMetadata;
    result.cellMetadata = cellMetadata;
  }

  if (useClientUpdateStyles) {
    const { currentData: clientUpdateStyles, isLoading: isLoadingClientUpdateStyles } = useGetVisibleStylesQuery(
      {
        clientId,
        groupId,
        dataType: ClientDataType.Supplier,
        branch: clientUpdateBranch || ClientDataBranch.Main,
      },
      { skip, refetchOnMountOrArgChange: true, refetchOnFocus: false },
    );

    result.isLoadingClientUpdateStyles = clientUpdateStyles === undefined || isLoadingClientUpdateStyles;
    result.clientUpdateStyles = clientUpdateStyles || EMPTY_CLIENT_UPDATE_STYLES;
  }

  if (useCellMetadataDiff) {
    const { currentData: cellMetadataDiff = EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE } =
      useGetClientDataBranchTableDiffQuery(
        {
          dataType: ClientDataType.Supplier,
          clientId,
          groupId,
          branch: clientUpdateBranch || ClientDataBranch.Main,
          table: CELL_METADATA_TABLE,
        },
        {
          skip,
          refetchOnMountOrArgChange: true,
        },
      );
    result.cellMetadataDiff = cellMetadataDiff;
  }

  return result;
};
