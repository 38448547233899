import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
  Column,
  RangeSelectionChangedEvent,
  CellClickedEvent,
  GetMainMenuItemsParams,
  SendToClipboardParams,
  CellClassParams,
  ValueSetterParams,
  FilterChangedEvent,
  ProcessDataFromClipboardParams,
  ColumnEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  ColumnPinnedEvent,
  CellFocusedEvent,
  RowDragEndEvent,
  RowDragMoveEvent,
  ModelUpdatedEvent,
  ViewportChangedEvent,
  CellKeyDownEvent,
  ColDef,
  SortChangedEvent,
  RowClassParams,
  VirtualRowRemovedEvent,
  ICellRendererParams,
  ColumnMovedEvent,
  GridApi,
} from 'ag-grid-community';
import { renderToStaticMarkup } from 'react-dom/server';
import { Add, InsertLink, ContentCopy, BugReport, OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Popper, Theme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import Decimal from 'decimal.js';
import {
  setCellNote,
  setSelectedTable,
  goToCellRange,
  setLoadingUserPreferences,
  setHighlightedCell,
  setOptionIconsToGenerate,
  setSearchType,
  clearFilterExceptionIds,
  setPublishMergeResult,
} from '../ducks/clientDataSlice';
import { setSearchHidden } from '../ducks/search';
import { AppState } from '../types/AppState';
import { TableAppBar } from './TableAppBar';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DataGrid } from './DataGrid';
import {
  CellMetadataProperty,
  ColumnDataType,
  ColumnEventSource,
  ColumnEventType,
  DoltDiffType,
  EXPRESSION_DEBUG_DELIMITER,
  SearchType,
} from '../constants/ClientData';
import { mapClientAndDataTypeAndTableToUndoStackId, mapClientAndDataTypeToClientDataId } from '../utils/clientIdUtils';
import noteSrc from '../images/note.svg';
import deleteNoteSrc from '../images/deleteNote.svg';
import deleteRowSrc from '../images/deleteRow.svg';
import generateStyleIcon from '../images/generateStyleIcon.svg';
import revertIcon from '../images/revertIcon.svg';
import editHistory from '../images/editHistory.svg';
import {
  cantEditBranchData,
  copyToClipboard,
  generateRowFromObject,
  getCellRangeInfo,
  getRowDiffColumnToFromValues,
  hasCellMetadataProperty,
  indexValueGetter,
  updateValues,
  setTableFilterModelInSessionStorage,
  processDataFromClipboard,
  addRows,
  removeRows,
  filterHiddenColumns,
  getRowOrders,
  onRowDragEnd,
  onRowDragMove,
  columnValueSetter,
  getOrderByIndex,
  processHeaderForClipboard,
  updateCellMetadata,
  autosizeColumns,
  getTableFilterModelFromSessionStorage,
  getClientDataTableFromUrl,
  getDeletedRowId,
  getGridSelectedRangeRevertData,
  revertRangeChanges,
  getTableDataWithRemovedRows,
  areDiffValuesDifferent,
  getRowIdsFromCellRange,
  publishDisco,
  updateStatusBarFilterClearButton,
  getCellMetadataFormulasDiffMap,
  Decimal65,
  isOptionAbleToGenerateIcon,
} from '../utils/clientDataUtils';
import { onGridCellKeyDown, onGridKeyboardShortcut } from '../utils/clientDataKeyboardShortcutHandlerUtils';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import {
  CellMetadata,
  ClientDataTableRowDiff,
  ColumnChangedParams,
  CellMetadataFormulasDiffMap,
  ColumnMetadata,
} from '../types/ClientData';
import { ClientDataSearch } from './ClientDataSearch';
import {
  DefaultColumnFieldNames,
  KEY_COLUMN,
  defaultEditableColumn,
  fixedColumnWidth,
  getIndexColumnDef,
  largeTextCellEditorParams,
  maxAutoColumnWidth,
  minAutoColumnWidth,
} from '../constants/ClientDataColumn';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { unknownGroup } from '../constants/Group';
import { getClientDataTableLink } from '../utils/contextMenuUtils';
import { QueryParams } from '../constants/QueryParams';
import { TableData } from '../types/DataGrid';
import { ToastMessageType } from '../constants/Viewer';
import { I18nKeys } from '../constants/I18nKeys';
import { AddMultipleRows } from './AddMultipleRows';
import { ClientDataPreviewDialog } from './ClientDataPreviewDialog';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { ClientDataNoteDialog } from './ClientDataNoteDialog';
import { ClientDataCreateBranchDialog } from './ClientDataCreateBranchDialog';
import { ClientDataRevertBranchDialog } from './ClientDataRevertBranchDialog';
import { ClientDataPublishDialog } from './ClientDataPublishDialog';
import { ClientDataPublishResultDialog } from './ClientDataPublishResultDialog';
import { ClientDataCantPublishDialog } from './ClientDataCantPublishDialog';
import {
  clearSelections,
  onCellClicked as onCellClickedFunc,
  onRangeSelectionChanged as onRangeSelectionChangedFunc,
  selectRows,
} from '../utils/selectionUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataPublishUpdatesDialog } from './ClientDataPublishUpdatesDialog';
import { ClientDataNewSupplierUpdatesDialog } from './ClientDataNewSupplierUpdatesDialog';
import { ClientDataRollbackDialog } from './ClientDataRollbackDialog';
import { ClientDataGenerateOptionIconDialog } from './ClientDataGenerateOptionIconDialog';
import { ClientDataChangesSummaryPanel } from './ClientDataChangesSummaryPanel';
import { GridResizeHandle } from './GridResizeHandle';
import { ClientDataCellHistoryPopover } from './ClientDataCellHistoryPopover';
import { ClientDataVerifiedQuotesDialog } from './ClientDataVerifiedQuotesDialog';
import { UserPreference } from '../constants/User';
import { ClearGridFilters } from './ClearGridFilters';
import bugReportSrc from '../images/bugReport.svg';
import { SystemGroups } from '../constants/SystemGroups';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { ClientDataGoToSourceDataDialog } from './ClientDataGoToSourceDataDialog';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { setToastMessage } from '../ducks/viewerSlice';
import { ClientDataLoadOptionsToGenerateIconsDialog } from './ClientDataLoadOptionsToGenerateIconsDialog';
import { ClientDataCantGenerateIconsDialog } from './ClientDataCantGenerateIconsDialog';
import { IDEAROOM_CLIENT_ID } from '../constants/ClientId';

interface StyleProps {
  cellFlashColor?: string;
  filterApplied?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  gridModifiedCell: {
    '&.unpublished': {
      backgroundColor: '#E4A76733 !important',
    },
    '&.quick-update': {
      backgroundColor: '#A7292733 !important',
    },
    '&.professional-services': {
      backgroundColor: '#80008020 !important',
    },
    '&.client-update': {
      backgroundColor: '#3E426720 !important',
    },
    '&.pricing': {
      backgroundColor: '#3E426720 !important',
    },
  },
  gridRemovedRowUp: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 2px)',
      left: '-1px',
      top: '-1px',
    },
    '&.unpublished': {
      '&::before': {
        borderTop: '3px solid #E4A767',
      },
    },
    '&.quick-update': {
      '&::before': {
        borderTop: '3px solid #A72927',
      },
    },
    '&.professional-services': {
      '&::before': {
        borderTop: '3px solid #800080',
      },
    },
    '&.client-update': {
      '&::before': {
        borderTop: '3px solid #3E4267',
      },
    },
    '&.pricing': {
      '&::before': {
        borderTop: '3px solid #3E4267',
      },
    },
    '&.ag-cell-range-selected': {
      // When the cell is selected an extra 1px top border is added
      '&::before': {
        top: '-2px',
      },
    },
  },
  gridRemovedRowDown: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 2px)',
      left: '-1px',
      bottom: '-2px',
    },
    '&.unpublished': {
      '&::after': {
        borderBottom: '3px solid #E4A767',
      },
    },
    '&.quick-update': {
      '&::after': {
        borderBottom: '3px solid #A72927',
      },
    },
    '&.client-update': {
      '&::after': {
        borderBottom: '3px solid #3E4267',
      },
    },
    '&.professional-services': {
      '&::after': {
        borderBottom: '3px solid #800080',
      },
    },
    '&.pricing': {
      '&::after': {
        borderBottom: '3px solid #3E4267',
      },
    },
  },
  gridRemovedRow: {
    textDecoration: 'line-through',
    color: 'grey',
  },
  noteHandle: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '7px solid transparent',
      borderRight: '7px solid #4994EC',
      right: '-1px',
      top: '0px',
    },
  },
  customGridStyles: {
    '& .ag-status-name-value, .ag-status-name-value-value, .ag-grid-index-column': {
      color: (props) => (props.filterApplied ? '#4286F4' : 'inherit'),
    },
    '& .ag-header-icon': {
      color: '#4286F4',
    },
  },
  secret: {
    '--ag-value-change-value-highlight-background-color': (props) => props.cellFlashColor || 'rgba(22, 160, 133, 0.5)',
  },
  debugExpression: {
    color: 'rgba(66, 134, 244, 1)',
    '&::before': {
      content: `url(${bugReportSrc})`,
      position: 'absolute',
      width: 24,
      height: 24,
      right: '0px',
      top: '0px',
    },
  },
}));

interface ContextMenuParams {
  metadata: CellMetadata[];
  columns: string[];
  client: string;
  dataType: ClientDataType;
  clientDataId: string;
  table: string;
  branch?: ClientDataBranch;
}

export const ClientData: React.FC = () => {
  const { t } = useTranslation();

  const [cellFlashColor, setCellFlashColor] = React.useState<string | undefined>(undefined);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const classes = useStyles({ cellFlashColor, filterApplied });

  const clientId = useAppSelector((state) => state?.clientData.clientId);
  const clientDataType = useAppSelector((state) => state?.clientData.clientDataType);
  const clientDataBranch = useAppSelector((state) => state?.clientData.clientDataBranch);
  const selectedTable = useAppSelector((state) => state?.clientData.selectedTable);
  const showFormulas = useAppSelector((state) => state?.clientData.settings?.showFormulas || false);
  const pasteOptions = useAppSelector((state) => state?.clientData.paste) || {};
  const isCreatingBranch = useAppSelector((state) => state?.clientData.isCreatingBranch);
  const highlightedCell = useAppSelector((state) => state?.clientData.highlightedCell);
  const changeSummaryOpen = useAppSelector((state) => state?.clientData.changeSummaryOpen);
  const filterExceptions = useAppSelector((state) => state?.clientData.filter.exceptions) || {};
  const publishMergeResultSuccess = useAppSelector((state) => state?.clientData.publishMergeResult?.isSuccess || false);
  const searchOpen = useAppSelector((state) => state?.clientData.search?.open || false);
  const searchExpanded = useAppSelector((state) => state?.clientData.search?.expanded || false);
  const selectedViewerId = useAppSelector((state) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId);
  const dialogKey = useAppSelector((state) => state?.dialog.key);

  const {
    activeBranches,
    clientTables,
    clientTableColumns,
    isLoadingClientTableColumns,
    cellMetadata,
    cellMetadataDiff,
    isInitializingTableMetadata,
    tableMetadata,
    isInitializingSelectedTableData,
    selectedTableData,
    selectedTableDataDiff,
  } = useClientDataRepo({
    useBranches: true,
    useClientTables: true,
    useClientTablesColumns: true,
    useCellMetadata: true,
    useTableMetadata: true,
    useSelectedTableData: true,
    useSelectedTableDataDiff: true,
    useCellMetadataDiff: true,
  });

  const [changeSummaryData, setChangeSummaryData] = useState<{
    displayDeletedRowIds: string[];
    displayDeletedRowEnded: boolean;
  }>({ displayDeletedRowIds: [], displayDeletedRowEnded: true });
  const [shouldResizeColumns, setShouldResizeColumns] = useState(false);
  const [persistColumnWidths, setPersistColumnWidths] = useState(false);
  const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout>();
  const isGridReadOnly = cantEditBranchData(clientDataBranch, activeBranches);
  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(selectedViewerId, clientDataType, selectedTable);

  const contextMenuParams: ContextMenuParams = {
    metadata: cellMetadata,
    columns: clientTableColumns[selectedTable],
    client: clientId,
    dataType: clientDataType,
    clientDataId: mapClientAndDataTypeToClientDataId(selectedViewerId, clientDataType),
    table: selectedTable,
    branch: clientDataBranch,
  };

  const dispatch = useAppDispatch();

  const tableAppBarRef = useRef<HTMLDivElement>(null);
  const tableMenuButtonRef = useRef<HTMLDivElement>(null);
  const tableMenuSearchRef = useRef<HTMLDivElement>(null);
  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [searchAnchorEl, setSearchAnchorEl] = useState<HTMLDivElement | null>(null);
  const [cellHistoryRowColumn, setCellHistoryRowColumn] = useState<{ rowId: string; column: string } | null>(null);
  const [cellHistoryPopoverAnchorEl, setCellHistoryPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const rootRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);
  const { api: gridApi, columnApi: columnGridApi } = gridRef.current || {};

  const displayCellHistory = useCallback(
    (rowId: string | null, column?: string) => {
      if (rowId && column) {
        const node = gridApi?.getRowNode(rowId);
        if (node) {
          const dataRowId = node.data.originalRowId || rowId;
          setCellHistoryRowColumn({ column, rowId: dataRowId });
          gridApi?.redrawRows({ rowNodes: [node] });
        }
      } else {
        setCellHistoryRowColumn(null);
        setCellHistoryPopoverAnchorEl(null);
      }
    },
    [gridApi],
  );

  const {
    group: { groupId } = unknownGroup,
    preferences: { [UserPreference.ClientDataPreferences]: clientDataPreferences = {} } = {},
  } = useAppSelector((state: AppState) => state?.currentUser);
  const selectedTableName =
    clientTables.find((table) => table.formattedTableName === selectedTable)?.tableName || selectedTable;
  const { [selectedTableName]: clientTablePreferences = {} } = clientDataPreferences;

  const columnChangedParams: ColumnChangedParams = {
    tablePreferences: clientTablePreferences,
  };

  const saveClientTablePreferences = (prefs: any) => {
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.ClientDataPreferences,
        preferences: {
          ...clientDataPreferences,
          [selectedTableName]: prefs,
        },
      }),
    );
  };

  useEffect(() => {
    if (gridApi && publishMergeResultSuccess && dialogKey === undefined) {
      publishDisco(gridApi, setCellFlashColor, 2);
      dispatch(setPublishMergeResult({ data: undefined, isSuccess: false, error: undefined }));
    }
  }, [gridApi, publishMergeResultSuccess, dialogKey]);

  useEffect(() => {
    if (!isInitializingSelectedTableData && !isLoadingClientTableColumns && gridApi && columnGridApi) {
      const queryRange = [QueryParams.StartRowId, QueryParams.EndRowId, QueryParams.Columns].reduce(
        (range, queryParam) => {
          let value: string | string[] | null = sessionStorage.getItem(queryParam);
          if (value) {
            if (queryParam === QueryParams.Columns) value = value.split(',').map((column) => column.trim());
            Object.assign(range, { [queryParam]: value });
            sessionStorage.removeItem(queryParam);
          }
          return range;
        },
        {} as { startRowId: string; endRowId: string; columns: string[] },
      );
      const pathTable = getClientDataTableFromUrl();
      if (pathTable && Object.keys(queryRange).length === 3) {
        dispatch(
          goToCellRange({
            table: pathTable,
            location: queryRange,
            rootGridApi: gridApi,
            rootGridColApi: columnGridApi,
          }),
        );
      }
    }
  }, [dispatch, isInitializingSelectedTableData, isLoadingClientTableColumns, gridApi, columnGridApi]);

  React.useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  const [groupingCellUpdates, setGroupingCellUpdates] = React.useState(false);
  const [groupedCellUpdates, setGroupedCellUpdates] = React.useState<any[]>([]);
  const [availableColumns, setAvailableColumns] = React.useState<string[]>([]);

  const onGroupUpdateStart = () => {
    setGroupingCellUpdates(true);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateComplete = () => {
    updateValues(clientDataTableId, groupedCellUpdates, cellMetadata, dispatch);
    setGroupingCellUpdates(false);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateEdit = ({ oldValue, newValue, data, column }: ValueSetterParams): boolean => {
    const [{ newValue: previousValue = '' } = {}] = groupedCellUpdates;
    groupedCellUpdates.push({
      table: selectedTable,
      oldValue,
      newValue: previousValue || newValue,
      data,
      column: column.getColId(),
    });
    return true;
  };

  useEffect(() => {
    // Filter out columns with hidden property in tableMetadata
    setAvailableColumns(filterHiddenColumns(clientId, clientTableColumns[selectedTable], tableMetadata));
  }, [clientId, tableMetadata, clientTableColumns, selectedTable]);

  const goToSourceData = useCallback(
    (lookupTable: { table: string; datasetType: ClientDataType; column?: string }) => {
      const lookupTableToUse = lookupTable;
      const isReferenceData = lookupTableToUse.datasetType === ClientDataType.Reference;
      const branch = isReferenceData ? ClientDataBranch.Main : clientDataBranch;
      const group = isReferenceData ? SystemGroups.IdeaRoom : groupId;
      const client = isReferenceData
        ? mapClientAndDataTypeToClientDataId(IDEAROOM_CLIENT_ID, ClientDataType.Reference)
        : mapClientAndDataTypeToClientDataId(clientId, clientDataType);

      const cellRangeLink = getClientDataTableLink(group, client, branch, {
        columns: [lookupTableToUse.column || KEY_COLUMN],
        table: lookupTableToUse.table,
      });
      window.open(cellRangeLink, '_blank')?.focus();
    },
    [clientDataBranch, clientDataType, clientId, groupId],
  );

  const getLookupTablesMenuItems = useCallback(
    (columnMetadata: ColumnMetadata) => {
      const { lookupTables = [] } = columnMetadata;
      return lookupTables
        .filter(
          (lookup) =>
            lookup.datasetType === ClientDataType.Reference ||
            clientTables.some((clientTable) => clientTable.formattedTableName === lookup.table),
        )
        .map((lookup) => ({
          ...lookup,
          name:
            compoundCaseToTitleCase(lookup.table) +
            (lookup.datasetType === ClientDataType.Reference ? ' (Reference)' : ''),
          action: () => goToSourceData(lookup),
        }));
    },
    [clientTables, goToSourceData],
  );

  const goToSourceDataShortcut = useCallback(() => {
    const cellRanges = gridApi?.getCellRanges();
    const { columns: selectedColumns } = getCellRangeInfo(cellRanges);
    if (selectedColumns.length === 1) {
      const columnId = selectedColumns[0];
      const columnMetadata = tableMetadata?.metadata[columnId];
      if (columnMetadata && columnMetadata.lookupTables?.length) {
        const lookupTablesMenuItems = getLookupTablesMenuItems(columnMetadata);
        if (lookupTablesMenuItems.length > 1) {
          dispatch(
            openDialog({
              dialog: Dialogs.ClientDataGoToSourceData,
              options: {
                menuItems: lookupTablesMenuItems,
              },
            }),
          );
          return;
        }
        goToSourceData(columnMetadata.lookupTables[0]);
      }
    }
  }, [gridApi, tableMetadata?.metadata, goToSourceData, getLookupTablesMenuItems, dispatch]);

  useEffect(() => {
    const openTableMenu = () => setTableMenuAnchorEl(tableMenuButtonRef.current);
    const onKeyDown = (e: KeyboardEvent) =>
      onGridKeyboardShortcut(
        e,
        { dispatch, openTableMenu, goToSourceData: goToSourceDataShortcut, gridApi, setCellFlashColor },
        clientDataTableId,
        isGridReadOnly,
        false,
      );

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [tableMenuButtonRef, clientDataTableId, dispatch, gridApi, isGridReadOnly, goToSourceDataShortcut]);

  const getMainMenuItems = useCallback(
    ({ api, column }: GetMainMenuItemsParams) => {
      const firstSection: (string | MenuItemDef)[] = [
        'pinSubMenu',
        {
          name: 'Select This Column',
          action: () => {
            clearSelections(api);
            api.addCellRange({
              rowStartIndex: 0,
              rowEndIndex: selectedTableData.length - 1,
              columns: [column],
            });
            api.setFocusedCell(0, column);
          },
        },
      ];
      const columnId = column.getColId();
      const columnMetadata = tableMetadata?.metadata[columnId];
      if (columnMetadata?.dataType === ColumnDataType.Lookup) {
        const lookupTablesMenuItems = getLookupTablesMenuItems(columnMetadata);
        const singleTable = lookupTablesMenuItems.length === 1;
        firstSection.push({
          name: t(I18nKeys.ClientDataHeaderMenuGoToSource),
          icon: renderToStaticMarkup(<OpenInNew fontSize="small" />),
          shortcut: '^⌘G',
          action: singleTable ? () => goToSourceData(lookupTablesMenuItems[0]) : undefined,
          subMenu: singleTable ? undefined : lookupTablesMenuItems,
        });
      }

      const secondSection: (string | MenuItemDef)[] = ['autoSizeThis', 'autoSizeAll'];
      const thirdSection: (string | MenuItemDef)[] = ['resetColumns'];

      return [...firstSection, 'separator', ...secondSection, 'separator', ...thirdSection];
    },
    [tableMetadata, selectedTableData, goToSourceData, getLookupTablesMenuItems, t],
  );

  const getContextMenuItems = useCallback(
    (
      { api, node, column, context }: GetContextMenuItemsParams,
      { metadata, columns, client, dataType, clientDataId: menuClientDataId, table, branch }: ContextMenuParams,
    ): (string | MenuItemDef)[] => {
      const rowModel = api.getModel();
      const cellRanges = api.getCellRanges();
      const { startRowIndex, endRowIndex, rowCount, columns: selectedColumns } = getCellRangeInfo(cellRanges);
      const [startRowId, endRowId] = [startRowIndex, endRowIndex].map((i) => api.getDisplayedRowAtIndex(i)?.id);

      const cellSelected = node && column;
      const rangeSelected = cellSelected && (rowCount > 1 || selectedColumns.length > 1);
      let data: any;
      let rowId: string;
      let columnId: string;
      let rowIsReadOnly = false;
      let cellNoteExists = false;
      let rangeNoteExists = false;
      const expressionExists =
        tableMetadata && selectedColumns.some((c) => tableMetadata.metadata[c]?.dataType === ColumnDataType.Expression);
      if (cellSelected) {
        ({ data } = (node || {}) as RowNode);
        rowId = data[ClientDataFixedColumns.RowId];
        columnId = (column as Column).getColId();
        cellNoteExists = hasCellMetadataProperty(metadata, rowId, columnId, CellMetadataProperty.Note);
        rowIsReadOnly = data.rowIsReadOnly;
      }
      if (rangeSelected) {
        rangeNoteExists =
          cellNoteExists ||
          getRowIdsFromCellRange(api).some((r) =>
            selectedColumns.some((c) => hasCellMetadataProperty(metadata, r, c, CellMetadataProperty.Note)),
          );
      }

      const disableCellLink =
        !startRowId || !endRowId || !columns?.length || !groupId || !menuClientDataId || !table || rowIsReadOnly;

      const menuClientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(client, dataType, table);

      const styleIconRows: TableData[] = [];
      if (column?.getColDef().type === ColumnDataType.StyleIcon) {
        for (let i = startRowIndex; i <= endRowIndex; i += 1) {
          const rowNode = api.getDisplayedRowAtIndex(i);
          // Workaround for Sheds Style table
          if (rowNode && (!('value1' in rowNode.data) || rowNode.data.property === 'icon')) {
            styleIconRows.push(rowNode.data);
          }
        }
      }
      const optionIconRows: TableData[] = [];
      if (column?.getColDef().type === ColumnDataType.OptionIcon) {
        for (let i = startRowIndex; i <= endRowIndex; i += 1) {
          const rowNode = api.getDisplayedRowAtIndex(i);
          if (rowNode && isOptionAbleToGenerateIcon(rowNode.data)) {
            optionIconRows.push(rowNode.data);
          }
        }
      }

      const selectedRangeDiff = getGridSelectedRangeRevertData(
        api,
        context.tableDataDiff,
        context.cellMetadataFormulasDiffMap,
      );
      const canRevert = selectedRangeDiff.length > 0;

      const getLinkToCellItem = {
        name: `Get link to this ${rangeSelected ? 'range' : 'cell'}`,
        action: async () => {
          const cellRangeLink = getClientDataTableLink(groupId, menuClientDataId, branch, {
            start: startRowId,
            end: endRowId,
            columns: selectedColumns,
            table,
          });
          await navigator.clipboard.writeText(cellRangeLink);
        },
        icon: renderToStaticMarkup(<InsertLink className="ag-icon" />),
        disabled: disableCellLink,
      };
      const revertToPublishedItem = {
        name: `Revert to Published Data`,
        action: () => {
          const nodeData: TableData[] = [];
          for (let i = startRowIndex; i <= endRowIndex; i += 1) {
            nodeData.push(api.getDisplayedRowAtIndex(i)?.data);
          }
          if (tableMetadata) {
            revertRangeChanges(
              menuClientDataTableId,
              selectedTable,
              nodeData,
              tableMetadata,
              dispatch,
              selectedRangeDiff,
              !!rangeSelected,
              selectedColumns.includes(DefaultColumnFieldNames.Index),
              cellMetadata,
            );
          }
        },
        icon: renderToStaticMarkup(<img alt="Note" src={revertIcon} className="ag-icon" />),
        disabled: isGridReadOnly,
      };

      const showCellHistoryItem = {
        name: `Show Edit History`,
        action: () => {
          if (node) {
            displayCellHistory(rowId, columnId);
          }
        },
        icon: renderToStaticMarkup(<img alt="Note" src={editHistory} className="ag-icon" />),
      };

      const debugExpression = {
        name: `Debug Expression${rowCount > 1 ? 's' : ''}`,
        action: () => {
          // const nodeData: TableData[] = [];
          const cellUpdates: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];
          for (let i = startRowIndex; i <= endRowIndex; i += 1) {
            const rowNode = api.getDisplayedRowAtIndex(i);
            if (rowNode) {
              const nodeData = rowNode.data;

              selectedColumns
                .filter((c) => tableMetadata && tableMetadata.metadata[c].dataType === ColumnDataType.Expression)
                .forEach((c) => {
                  let nodeValue = api.getValue(c, rowNode);
                  if (nodeValue === null || nodeValue === undefined) {
                    nodeValue = '';
                  }
                  cellUpdates.push({
                    table: selectedTable,
                    data: nodeData,
                    column: c,
                    oldValue: nodeValue,
                    newValue: nodeValue,
                  });
                });
            }
          }
          const enable = cellUpdates.some((cell) => !cell.oldValue.includes(EXPRESSION_DEBUG_DELIMITER));
          cellUpdates.forEach((cellUpdate) => {
            if (enable) {
              // eslint-disable-next-line no-param-reassign
              cellUpdate.newValue = !cellUpdate.oldValue.includes(EXPRESSION_DEBUG_DELIMITER)
                ? `${
                    cellUpdate.oldValue.startsWith('=')
                      ? cellUpdate.oldValue.replace('=', `=${EXPRESSION_DEBUG_DELIMITER}`)
                      : `${EXPRESSION_DEBUG_DELIMITER}${cellUpdate.oldValue}`
                  }`
                : cellUpdate.oldValue;
            } else {
              // eslint-disable-next-line no-param-reassign
              cellUpdate.newValue = cellUpdate.oldValue.replace(EXPRESSION_DEBUG_DELIMITER, '');
            }
          });
          updateValues(clientDataTableId, cellUpdates, cellMetadata, dispatch);
        },
        icon: renderToStaticMarkup(<BugReport fontSize="large" className="ag-icon" />),
      };

      const result: (string | MenuItemDef)[] = [
        ...(cellSelected
          ? [
              {
                name: `Copy`,
                action: () => api.copyToClipboard(),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⌘C',
              },
              {
                name: `Copy with Headers`,
                action: () => api.copyToClipboard({ includeHeaders: true }),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⇧⌘C',
              },
              'separator',
              {
                name: `Insert ${rowCount} Row${rowCount > 1 ? `s` : ''} Above`,
                action: () => {
                  const nextOrder = getOrderByIndex(startRowIndex, api);

                  // Find the order of the row above this row by order ignoring sorting
                  let prevOrder: Decimal | undefined;
                  rowModel.forEachNode((n) => {
                    const order = new Decimal65(n?.data?.[ClientDataFixedColumns.Order] || 0);
                    if (order.lessThan(nextOrder)) {
                      prevOrder = prevOrder && prevOrder.greaterThan(order) ? prevOrder : order;
                    }
                  });

                  const rowOrders = getRowOrders(prevOrder, nextOrder, rowCount, api);
                  const rows = Array.from({ length: rowCount }, (_el, i) =>
                    generateRowFromObject(client, columns, rowOrders[i]),
                  );
                  addRows(menuClientDataTableId, rows, dispatch);
                },
                icon: renderToStaticMarkup(<Add className="ag-icon" />),
                disabled: isGridReadOnly,
                ...(isGridReadOnly ? { tooltip: t(I18nKeys.ContextMenuRowDisabledTooltip) } : {}),
              },
              {
                name: `Insert ${rowCount} Row${rowCount > 1 ? `s` : ''} Below`,
                action: () => {
                  const prevOrder = getOrderByIndex(endRowIndex, api);

                  // Find the order of the row below this row by order ignoring sorting
                  let nextOrder: Decimal | undefined;
                  rowModel.forEachNode((n) => {
                    const order = new Decimal65(n?.data?.[ClientDataFixedColumns.Order] || 0);
                    if (order.greaterThan(prevOrder)) {
                      nextOrder = nextOrder && nextOrder.lessThan(order) ? nextOrder : order;
                    }
                  });

                  const rowOrders = getRowOrders(prevOrder, nextOrder, rowCount, api);
                  const rows = Array.from({ length: rowCount }, (_el, i) =>
                    generateRowFromObject(client, columns, rowOrders[i]),
                  );
                  addRows(menuClientDataTableId, rows, dispatch);
                },
                icon: renderToStaticMarkup(<Add className="ag-icon" />),
                disabled: isGridReadOnly,
                ...(isGridReadOnly ? { tooltip: t(I18nKeys.ContextMenuRowDisabledTooltip) } : {}),
              },
              {
                name: `Delete ${rowCount} Row${rowCount > 1 ? `s` : ''}`,
                action: () => {
                  const rows = [];
                  for (let i = startRowIndex; i <= endRowIndex; i += 1) {
                    const rowNode = api.getDisplayedRowAtIndex(i);
                    rows.push(rowNode?.data);
                  }
                  removeRows(menuClientDataTableId, rows, dispatch);
                },
                cssClasses: ['redFont', 'bold'],
                icon: renderToStaticMarkup(<img alt="Delete Row" src={deleteRowSrc} className="ag-icon" />),
                disabled: isGridReadOnly || rowIsReadOnly,
                ...(isGridReadOnly ? { tooltip: t(I18nKeys.ContextMenuRowDisabledTooltip) } : {}),
                shortcut: '⌘-',
              },
              'separator',
              ...(column?.getColId() !== DefaultColumnFieldNames.Index
                ? [
                    {
                      name: `${cellNoteExists ? 'Edit' : 'Add'} Note${rangeSelected ? 's' : ''}`,
                      action: () => {
                        dispatch(
                          // Selected node should be first to be edited
                          setCellNote({
                            rowIds: [rowId, ...getRowIdsFromCellRange(api).filter((id) => id !== rowId)],
                            colIds: [columnId, ...selectedColumns.filter((id) => id !== columnId)],
                          }),
                        );
                        dispatch(openDialog({ dialog: Dialogs.ClientDataNote }));
                      },
                      icon: renderToStaticMarkup(<img alt="Note" src={noteSrc} className="ag-icon" />),
                      shortcut: '⇧F2',
                      disabled: rowIsReadOnly,
                    },
                    ...(cellNoteExists || rangeNoteExists
                      ? [
                          {
                            name: `Delete Note${rangeSelected ? 's' : ''}`,
                            action: () => {
                              if (branch) {
                                updateCellMetadata(
                                  clientDataTableId,
                                  getRowIdsFromCellRange(api).flatMap((r) =>
                                    selectedColumns.map((c) => ({
                                      cellsMetadata: cellMetadata,
                                      colId: c,
                                      rowId: r,
                                      metadataProperty: CellMetadataProperty.Note,
                                      value: '',
                                    })),
                                  ),
                                  dispatch,
                                );
                              }
                            },
                            icon: renderToStaticMarkup(
                              <img alt="Delete Note" src={deleteNoteSrc} className="ag-icon" />,
                            ),
                            disabled: rowIsReadOnly,
                          },
                        ]
                      : []),
                    ...(expressionExists ? [debugExpression] : []),
                    ...(cellSelected ? [getLinkToCellItem] : []),
                    ...(cellSelected && !rangeSelected ? [showCellHistoryItem] : []),
                    ...(canRevert ? [revertToPublishedItem] : []),
                    ...(!rangeSelected
                      ? [
                          {
                            name: `Edit in Large Window`,
                            action: () => {
                              const columnDefs = api.getColumnDefs();
                              const columnDef: ColDef<any> | undefined = columnDefs?.find(
                                (colDef: ColDef<any>) => colDef.colId === columnId,
                              );
                              if (
                                columnDefs &&
                                columnDef &&
                                columnDef.cellEditor !== largeTextCellEditorParams.cellEditor
                              ) {
                                columnDef.cellEditor = largeTextCellEditorParams.cellEditor;
                                columnDef.cellEditorPopup = largeTextCellEditorParams.cellEditorPopup;
                                columnDef.cellEditorParams = largeTextCellEditorParams.cellEditorParams;
                                api.setColumnDefs(columnDefs);
                              }
                              api.startEditingCell({
                                rowIndex: startRowIndex,
                                colKey: columnId,
                              });
                            },
                            shortcut: '⌘Enter',
                          },
                        ]
                      : []),
                    ...(styleIconRows.length > 0
                      ? [
                          {
                            name: rangeSelected
                              ? `Regenerate ${styleIconRows.length} Style Icons`
                              : 'Regenerate Style Icon',
                            action: () => {
                              if (isGridReadOnly) {
                                dispatch(
                                  setToastMessage({
                                    type: ToastMessageType.Info,
                                    message: t(I18nKeys.ClientDataPublishedBranchReadOnly),
                                  }),
                                );
                              } else {
                                dispatch(setOptionIconsToGenerate([{ table, column: columnId, rows: styleIconRows }]));
                              }
                            },
                            icon: renderToStaticMarkup(<img alt="Note" src={generateStyleIcon} className="ag-icon" />),
                            disabled: rowIsReadOnly,
                          },
                        ]
                      : []),
                    ...(optionIconRows.length > 0
                      ? [
                          {
                            name:
                              optionIconRows.length > 1
                                ? `Regenerate ${optionIconRows.length} Icons`
                                : 'Regenerate Icon',
                            action: () => {
                              if (isGridReadOnly) {
                                dispatch(
                                  setToastMessage({
                                    type: ToastMessageType.Info,
                                    message: t(I18nKeys.ClientDataPublishedBranchReadOnly),
                                  }),
                                );
                              } else {
                                dispatch(setOptionIconsToGenerate([{ table, column: columnId, rows: optionIconRows }]));
                              }
                            },
                            icon: renderToStaticMarkup(<img alt="Note" src={generateStyleIcon} className="ag-icon" />),
                            disabled: rowIsReadOnly,
                          },
                        ]
                      : []),
                    'separator',
                  ]
                : [
                    ...(cellSelected ? [getLinkToCellItem] : []),
                    ...(canRevert ? [revertToPublishedItem] : []),
                    'separator',
                  ]),
            ]
          : []),
        {
          name: 'Adjust Columns',
          subMenu: ['autoSizeAll', 'resetColumns'],
        },
        'export',
      ];
      return result;
    },
    [
      groupId,
      isGridReadOnly,
      t,
      dispatch,
      clientDataTableId,
      cellMetadata,
      tableMetadata,
      selectedTable,
      displayCellHistory,
    ],
  );

  const onCellClicked = useCallback(
    (params: CellClickedEvent) => onCellClickedFunc(params, [DefaultColumnFieldNames.Index, ...availableColumns]),
    [availableColumns],
  );

  const onRangeSelectionChanged = useCallback(
    (params: RangeSelectionChangedEvent) =>
      onRangeSelectionChangedFunc(params, [DefaultColumnFieldNames.Index, ...availableColumns], changeSummaryData, () =>
        setChangeSummaryData((current) => ({ ...current, displayDeletedRowIds: [] })),
      ),
    [availableColumns, changeSummaryData],
  );

  const onCellKeyDown = (params: CellKeyDownEvent) =>
    onGridCellKeyDown(params, { dispatch, isGridReadOnly, clientDataTableId });

  const sendToClipboard = (params: SendToClipboardParams, showFormulasEnabled: boolean) => {
    const copiedData = copyToClipboard(params, showFormulasEnabled);
    navigator.clipboard.writeText(copiedData).catch((err) => {
      console.error('Could not copy to clipboard: ', err);
    });
  };

  const statusBarPanels = ['agTotalAndFilteredRowCountComponent', ClearGridFilters, AddMultipleRows];

  useEffect(() => {
    if (searchOpen && tableAppBarRef.current) {
      // When opening search, set the search type to current selection if a range is selected
      const cellRanges = gridApi?.getCellRanges();
      const { rowCount: selectedRowCount, columns: selectedColumns } = getCellRangeInfo(cellRanges);
      const rangeSelected = selectedRowCount > 1 || selectedColumns.length > 1;
      if (rangeSelected) dispatch(setSearchType(SearchType.CurrentSelection));

      setSearchAnchorEl(tableAppBarRef.current);
    } else if (searchAnchorEl) {
      setSearchAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchOpen]);

  const getRowClass = (rowClassParam: RowClassParams<TableData>) => {
    const { data } = rowClassParam;
    if (data?.diffType === DoltDiffType.Removed) {
      return [classes.gridRemovedRow];
    }
    return [];
  };

  const getCellClass = (cellClassParam: CellClassParams<TableData>) => {
    const { field } = cellClassParam.colDef;
    const { rowId, diffType } = cellClassParam.data || { diffType: '' };
    const {
      tableDataDiff = [],
      tableDataWithRemovedRows = [],
      cellMetadata: cellMetadataFromContext = [],
      cellMetadataFormulasDiffMap,
    } = cellClassParam.context;

    const isDeletedRow = diffType === DoltDiffType.Removed;

    const classList: string[] = [];

    const rowDiff = (tableDataDiff as ClientDataTableRowDiff[]).find((diff) => diff.rowId === rowId);
    const cellMetadataFormulaDiffs = (cellMetadataFormulasDiffMap as CellMetadataFormulasDiffMap).get(rowId || '');
    if ((rowDiff || cellMetadataFormulaDiffs) && !isDeletedRow) {
      const columnDiff = rowDiff ? getRowDiffColumnToFromValues(rowDiff, field || '') : undefined;
      const cellMetadataColumnFormulaDiff = cellMetadataFormulaDiffs
        ? getRowDiffColumnToFromValues(cellMetadataFormulaDiffs, field || '')
        : undefined;

      if (
        (columnDiff && areDiffValuesDifferent(columnDiff.from, columnDiff.to)) ||
        (cellMetadataColumnFormulaDiff &&
          areDiffValuesDifferent(cellMetadataColumnFormulaDiff.from, cellMetadataColumnFormulaDiff.to)) ||
        rowDiff?.diffType === DoltDiffType.Added
      ) {
        // FIXME: Color when metadata changed
        classList.push(classes.gridModifiedCell, clientDataBranch || ClientDataBranch.Main);
      }
    }

    if (field === ClientDataFixedColumns.Index) {
      if (!isDeletedRow) {
        const rowIndexInRemovedList = (tableDataWithRemovedRows as TableData[]).findIndex((row) => row.rowId === rowId);
        if (
          rowIndexInRemovedList > 0 &&
          tableDataWithRemovedRows[rowIndexInRemovedList - 1].diffType === DoltDiffType.Removed
        ) {
          classList.push(classes.gridRemovedRowUp, clientDataBranch || ClientDataBranch.Main);
        }
        if (
          rowIndexInRemovedList < tableDataWithRemovedRows.length - 1 &&
          tableDataWithRemovedRows[rowIndexInRemovedList + 1].diffType === DoltDiffType.Removed
        ) {
          classList.push(classes.gridRemovedRowDown, clientDataBranch || ClientDataBranch.Main);
        }
      }
      classList.push('ag-grid-index-column');
      return classList;
    }

    if (rowId && field) {
      const hasNote = hasCellMetadataProperty(cellMetadataFromContext, rowId, field, CellMetadataProperty.Note);
      if (hasNote) {
        classList.push(classes.noteHandle);
      }

      if (tableMetadata && tableMetadata.metadata[field]?.dataType === ColumnDataType.Expression) {
        const cellData = cellClassParam.data && cellClassParam.data[field];
        if (typeof cellData === 'string' && cellData.includes(EXPRESSION_DEBUG_DELIMITER)) {
          classList.push(classes.debugExpression);
        }
      }
    }

    return classList;
  };

  const onCellEditRequest = () => {
    if (isGridReadOnly) {
      dispatch(
        setToastMessage({ type: ToastMessageType.Info, message: t(I18nKeys.ClientDataPublishedBranchReadOnly) }),
      );
    }
  };

  const onColumnChanged = (
    event: ColumnEvent | ColumnPinnedEvent | ColumnVisibleEvent | ColumnResizedEvent,
    params: ColumnChangedParams,
  ): void => {
    const { type, source, column, columnApi } = event;
    const { tablePreferences = {} } = params;
    const columnId = column?.getColId();

    switch (type) {
      case ColumnEventType.Moved:
        if (source === ColumnEventSource.UiColumnMoved) {
          const prefs = { ...tablePreferences };
          const { finished } = event as ColumnMovedEvent;
          if (!finished) {
            break;
          }
          if (columnId) {
            const columnState = columnApi.getColumnState();
            columnState.forEach((col, i) => {
              const { colId } = col;
              prefs[colId] = { ...prefs[colId], order: i };
            });
            saveClientTablePreferences(prefs);
          }
        }
        break;
      case ColumnEventType.Pinned:
        if (source === ColumnEventSource.ContextMenu) {
          const prefs = { ...tablePreferences };
          const { pinned } = event as ColumnPinnedEvent;

          if (columnId) {
            if (pinned === 'left' || pinned === 'right') {
              prefs[columnId] = { ...prefs[columnId], pinned };
            } else if (prefs[columnId]) {
              delete prefs[columnId].pinned;
            }
            saveClientTablePreferences(prefs);
          }
        }
        break;
      case ColumnEventType.Visible:
        if (source === ColumnEventSource.ToolPanelUi || source === ColumnEventSource.Api) {
          const prefs = { ...tablePreferences };
          if (columnId) {
            const { visible = true } = event as ColumnVisibleEvent;
            if (visible === false) {
              prefs[columnId] = { ...prefs[columnId], hide: true };
            } else {
              delete prefs[columnId].hide;
            }
            saveClientTablePreferences(prefs);
          }
        }
        break;
      case ColumnEventType.Resized:
        if (source === ColumnEventSource.UiColumnResized) {
          const { finished } = event as ColumnResizedEvent;
          if (!finished) {
            break;
          }
          const prefs = { ...clientTablePreferences };
          const width = column?.getActualWidth() || 200;
          if (columnId) {
            prefs[columnId] = { ...prefs[columnId], width };
            saveClientTablePreferences(prefs);
          }
        }
        if (source === ColumnEventSource.AutosizeColumns) {
          const { finished, columns } = event as ColumnResizedEvent;
          if (!finished || !persistColumnWidths) {
            break;
          }
          (columns || []).forEach((col) => {
            const id = col.getColId();
            const width = col.getActualWidth() || 200;
            const isFixedColumn = (
              [
                DefaultColumnFieldNames.Index,
                DefaultColumnFieldNames.GoToCellRange,
                DefaultColumnFieldNames.AddToTable,
              ] as string[]
            ).includes(id);

            // Don't let autosize fall above or below the min/max
            if (isFixedColumn) columnApi.setColumnWidth(col, fixedColumnWidth);
            if (width < minAutoColumnWidth && !isFixedColumn) columnApi.setColumnWidth(col, minAutoColumnWidth);
            if (width > maxAutoColumnWidth && !isFixedColumn) columnApi.setColumnWidth(col, maxAutoColumnWidth);

            const prefs = { ...clientTablePreferences };
            if (id) {
              prefs[id] = { ...prefs[id], width: col.getActualWidth() };
              saveClientTablePreferences(prefs);
            }
          });
        }
        break;
      case ColumnEventType.EverythingChanged:
        if (source === ColumnEventSource.ContextMenu) {
          const prefs = { ...tablePreferences };
          delete prefs[selectedTableName];
          dispatch(saveUserPreferences({ userPreference: UserPreference.ClientDataPreferences, preferences: prefs }));
          dispatch(setLoadingUserPreferences(true));
        }
        if (source === ColumnEventSource.ColumnMenu) {
          const prefs = { ...tablePreferences };
          // Loop through all columns, check their visibility and update the preferences
          const displayedColumns = columnApi.getAllDisplayedColumns();
          const displayedColumnIds = displayedColumns.map((col) => col.getColId());
          // Hide any columns in preferences that are not displayed
          Object.keys(prefs).forEach((colId) => {
            if (!displayedColumnIds.includes(colId)) {
              prefs[colId] = { ...prefs[colId], hide: true };
            } else {
              delete prefs[colId].hide;
            }
          });
          saveClientTablePreferences(prefs);
        }
        break;
      default:
        break;
    }
  };

  const onColumnValueChanged = (params: ValueSetterParams): boolean =>
    columnValueSetter(clientDataTableId, params, cellMetadata, dispatch);

  const selectedTableColumns = useMemo(
    () => [
      {
        ...getIndexColumnDef({ floatingFilter: true }),
        cellClass: getCellClass,
        valueGetter: indexValueGetter,
      },
      ...availableColumns,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableColumns],
  );

  useEffect(() => {
    // Must refresh cells when data diff or cell metadata changes due to custom styling
    // FIXME: refresh only cells that changed in datadiff and cell metadata
    gridApi?.refreshCells({ force: true, suppressFlash: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTableDataDiff, cellMetadata]);

  useEffect(() => {
    // Prevents unnecessary autosizes by enabling check only when the selected table changes
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    setShouldResizeColumns(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTable]);

  const onViewportChanged = (params: ViewportChangedEvent) => {
    /*
      We have to setTimeout before resizing because AG-Grid needs the rows and headers to be rendered before being able to correctly
      calculate the columns width. Unfortunatelly, I couldn't find an useful event that triggers after both rows and headers were rendered.
      onFirstDataRendered would be the ideal event per AG-Grids documentation, however it's only triggered once when the grid first loads.
      onViewportChanged correctly triggers only when new cells are rendered, so we can safely check for rendered nodes here. However, it's not guaranteed
      that the headers were rendered already. This setTimeout waits a second hoping that by that time the headers would have been rendered so we can
      correctly resize columns.
    */
    const { api, columnApi } = params;
    if (shouldResizeColumns && api.getRenderedNodes().length > 0) {
      setPersistColumnWidths(false);
      /* 
        Performs a first resize that won't persist.
        In most cases this will be enough, and the columns should look like they have the correct size from the start.
        However, we'll run again after a second, persisting to the storage this time.
      */
      window.requestAnimationFrame(() => {
        autosizeColumns(columnApi, clientDataPreferences, tableMetadata);
      });
      setShouldResizeColumns(false);
      setResizeTimeout(
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            setPersistColumnWidths(true);
            autosizeColumns(columnApi, clientDataPreferences, tableMetadata);
          });
        }, 1000),
      );
    }
  };

  const updateFilterState = (api: GridApi, filterModel: any) => {
    const isTableFiltered = Object.keys(filterModel).length > 0;
    updateStatusBarFilterClearButton(api, isTableFiltered);
    setFilterApplied(isTableFiltered);
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    const { api } = event;
    const filterModel = api.getFilterModel();
    /*
      When columns defs change, this event will be triggered because ag-grid removed a column that had a filter
      However, selectedTable will have a different table already, so this was clearing the filter of the next table.
      afterFloatingFilter is effectivelly undefined when the filter is cleared through this table change process.

      Allow quickFilter to trigger this event, we use it for status bar filter clearing.
    */
    if (event.afterFloatingFilter !== undefined || event.source === 'quickFilter') {
      const exceptionIds = filterExceptions[selectedTable] || [];
      if (exceptionIds.length) {
        // Clear the filter exceptions and rerun the filter
        dispatch(clearFilterExceptionIds());
        api.onFilterChanged();
      }
      setTableFilterModelInSessionStorage(selectedTable, filterModel);
      updateFilterState(api, filterModel);
    }
  };

  const onModelUpdated = (event: ModelUpdatedEvent) => {
    if (!selectedTable) return;
    const { api, columnApi } = event;
    const tableFilterModel = getTableFilterModelFromSessionStorage(selectedTable) || {};
    const filteredColumns = Object.keys(tableFilterModel);
    const currentFilterModel = api.getFilterModel();

    // Check whether the current filter model is different from the one in the storage
    if (JSON.stringify(tableFilterModel) !== JSON.stringify(currentFilterModel)) {
      const columns = columnApi.getAllGridColumns();
      // Find if all the columns in the filter model are in the table
      const allColumnsExist = filteredColumns.every((column) => columns?.some((col) => col.getColId() === column));
      if (allColumnsExist) {
        api.setFilterModel(tableFilterModel);
        updateFilterState(api, tableFilterModel);
      } else {
        api.setFilterModel({});
        updateFilterState(api, {});
      }
    }
  };

  const onSortChanged = (event: SortChangedEvent) => {
    const { api, columnApi } = event;
    const columns = columnApi.getColumns() || [];

    const prefs = { ...clientTablePreferences };
    columns.forEach((col) => {
      const colId = col.getColId();
      const sort = col.getSort();
      const { sortIndex } = col.getColDef() || {};
      if (colId) {
        prefs[colId] = { ...prefs[colId], sort, sortIndex };
      }
    });
    saveClientTablePreferences(prefs);
    api.setSuppressRowDrag(columns.some((col) => !!col.getSort()));
  };

  const onChangeSummaryFocusedChange = (table: string, change: TableData) => {
    if (!searchOpen) {
      setChangeSummaryData((current) => ({ ...current, displayDeletedRowEnded: false }));
      if (gridApi && columnGridApi) {
        clearSelections(gridApi);

        const rowId =
          change.diffType === DoltDiffType.Removed
            ? getDeletedRowId(change.dataRowId as string)
            : (change.dataRowId as string);

        if (change.diffType === DoltDiffType.Removed) {
          const displayDeletedRowIds = [rowId];
          setChangeSummaryData((current) => ({ ...current, displayDeletedRowEnded: false, displayDeletedRowIds }));
        }

        let columns: string[] = [ClientDataFixedColumns.Index];
        if (change.colId && change.colId !== ClientDataFixedColumns.Order) {
          columns = [change.colId as string];
        }

        dispatch(
          goToCellRange({
            table,
            location: { startRowId: rowId, endRowId: rowId, columns },
            rootGridApi: gridApi,
            rootGridColApi: columnGridApi,
            onComplete: () => {
              setChangeSummaryData((current) => ({ ...current, displayDeletedRowEnded: true }));
            },
          }),
        );
      }
    }
  };

  const onVirtualRowRemoved = (event: VirtualRowRemovedEvent) => {
    const { data, api, columnApi, node } = event;
    const { displayDeletedRowIds } = changeSummaryData;

    /*
      When the "deleted" row is removed from the viewport and is not in the displayDeletedRowIds anymore
      it means the row was actually removed from the tableData. In that case, if there's a range selected below 
      the deleted row, ag-grid will move the range to the next line, so this moves it back to the original range row.
    */
    if (data.diffType === DoltDiffType.Removed && !displayDeletedRowIds.includes(data.rowId)) {
      const cellRanges = api.getCellRanges() || [];
      const { startRowIndex, endRowIndex } = getCellRangeInfo(cellRanges);

      if (startRowIndex > node.childIndex) {
        selectRows(startRowIndex - 1, endRowIndex - 1, cellRanges[0].columns, api, columnApi, false);
      }
    }
  };

  useEffect(() => {
    // Reset displayed deleted rows when anything changes
    if (changeSummaryData.displayDeletedRowEnded) {
      setChangeSummaryData((current) => ({ ...current, displayDeletedRowIds: [] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDataBranch, clientId, clientDataType, selectedTable, searchOpen, changeSummaryOpen]);

  const [searchWidth, setSearchWidth] = React.useState('700px');
  useEffect(() => {
    if (!rootRef.current) return;
    setSearchWidth(`max(calc(${rootRef.current?.clientWidth}px * ${searchExpanded ? 0.95 : 0.5}), 700px)`);
  }, [searchExpanded, rootRef]);

  const selectedTableDataWithRemovedRows = useMemo(
    () => getTableDataWithRemovedRows(selectedTableData, selectedTableDataDiff),
    [selectedTableData, selectedTableDataDiff],
  );

  const tableData = useMemo(() => {
    if (changeSummaryData.displayDeletedRowIds.length > 0 && !isInitializingSelectedTableData) {
      return selectedTableDataWithRemovedRows.filter(
        (row) => !row.diffType || changeSummaryData.displayDeletedRowIds.includes(row.rowId),
      );
    }
    return selectedTableData;
  }, [
    selectedTableData,
    selectedTableDataWithRemovedRows,
    changeSummaryData.displayDeletedRowIds,
    isInitializingSelectedTableData,
  ]);

  useEffect(() => {
    const setFilterExceptionsInContext = async () => {
      await Promise.all(
        (columnGridApi?.getColumns() || []).map(async (col) => {
          const filter = (await gridApi?.getFilterInstance(col)) as any;
          if (filter && filter.textFilterParams) {
            filter.textFilterParams.context.filterExceptionIds = filterExceptions[selectedTable] || [];
          }
        }),
      );
      gridApi?.onFilterChanged();
    };
    setFilterExceptionsInContext();
  }, [filterExceptions, selectedTable]);

  const getCellRenderer = (params: ICellRendererParams) => {
    if (
      params.context.cellHistoryRowColumn &&
      (params.data.originalRowId || params.data.rowId) === params.context.cellHistoryRowColumn.rowId &&
      params.column?.getColId() === params.context.cellHistoryRowColumn.column
    ) {
      // Hack to get the Grid's cell element allowing us to anchor the popover
      setCellHistoryPopoverAnchorEl(params.eGridCell);
    }
    return undefined;
  };

  const cellMetadataFormulasDiffMap = useMemo(
    () => getCellMetadataFormulasDiffMap(cellMetadataDiff),
    [cellMetadataDiff],
  );

  const getCustomColumnHeaderTooltip = (colId: string) => {
    let headerTooltip = `${compoundCaseToTitleCase(colId)}\n{{${colId}}}`;
    const columnMetadata = tableMetadata?.metadata[colId];
    if (columnMetadata?.dataType === ColumnDataType.Lookup) {
      const lookupTables = getLookupTablesMenuItems(columnMetadata);
      const hasReferenceTable = lookupTables.some((table) => table.datasetType === ClientDataType.Reference);
      const hasDataTypeTable = lookupTables.some((table) => table.datasetType !== ClientDataType.Reference);

      let source = `${compoundCaseToTitleCase(clientDataType)} Data`;
      if (hasReferenceTable && hasDataTypeTable) {
        source = `Reference and ${compoundCaseToTitleCase(clientDataType)} Data`;
      } else if (hasReferenceTable) {
        source = 'Reference Data';
      }
      const sourceLabel = t(I18nKeys.ClientDataHeaderTooltipSource, { source });

      const lookupTableNames = Array.from(
        new Set(
          lookupTables.map(
            (lookupTable) =>
              `${compoundCaseToTitleCase(lookupTable.table)} • ${compoundCaseToTitleCase(
                lookupTable.column || KEY_COLUMN,
              )}`,
          ),
        ),
      );

      const tableKeyLabel = lookupTableNames.slice(0, 3).join('\n');
      headerTooltip = `${headerTooltip}\n${sourceLabel}\n${tableKeyLabel}${
        lookupTableNames.length > 3
          ? `\n${t(I18nKeys.ClientDataHeaderTooltipSourceAndOtherTables, { count: lookupTableNames.length - 3 })}`
          : ''
      }`;
    } else if (columnMetadata?.dataType === ColumnDataType.Enum) {
      headerTooltip = `${headerTooltip}\n${t(I18nKeys.ClientDataHeaderTooltipSourceBuiltIn)}`;
    }

    return headerTooltip;
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <TableAppBar
        ref={tableAppBarRef}
        searchRef={tableMenuSearchRef}
        menuButtonRef={tableMenuButtonRef}
        clientId={clientId}
        clientDataType={clientDataType}
        changeTab={(table: string) => dispatch(setSelectedTable(table))}
        menuAnchorEl={tableMenuAnchorEl}
        setMenuAnchorEl={(el) => setTableMenuAnchorEl(el)}
      />
      <Popper style={{ zIndex: 1300, width: searchWidth }} open={!!searchAnchorEl} anchorEl={searchAnchorEl}>
        <ClientDataSearch gridRef={gridRef} />
      </Popper>
      <PanelGroup autoSaveId="client-data-resizable-panels" direction="vertical">
        <Panel minSize={20} defaultSize={50} order={1} id="grid-panel">
          <DataGrid
            className={`${classes.customGridStyles} ${classes.secret}`}
            gridRef={gridRef}
            data={tableData}
            cellMetadata={cellMetadata}
            tableMetadata={tableMetadata}
            isLoadingTableMetadata={isInitializingTableMetadata}
            context={{
              tableDataDiff: selectedTableDataDiff,
              cellMetadataFormulasDiffMap,
              tableDataWithRemovedRows: selectedTableDataWithRemovedRows,
              displayDeletedRowIds: changeSummaryData.displayDeletedRowIds,
              filterExceptionIds: filterExceptions[selectedTable] || [],
              cellHistoryRowColumn,
            }}
            getCellClass={getCellClass}
            getRowClass={getRowClass}
            getCustomColumnHeaderTooltip={getCustomColumnHeaderTooltip}
            getContextMenuItems={(params: GetContextMenuItemsParams) => getContextMenuItems(params, contextMenuParams)}
            readOnly={isGridReadOnly}
            onCellEditRequest={onCellEditRequest}
            getMainMenuItems={getMainMenuItems}
            isLoading={isInitializingSelectedTableData || isInitializingTableMetadata || isCreatingBranch}
            selectedTable={selectedTable}
            selectedTableColumns={selectedTableColumns}
            defaultColDefinition={defaultEditableColumn}
            showFormulas={showFormulas}
            statusBarPanels={statusBarPanels}
            highlightedCell={highlightedCell}
            disableStatusBar={changeSummaryOpen}
            onViewportChanged={onViewportChanged}
            onModelUpdated={onModelUpdated}
            onVirtualRowRemoved={onVirtualRowRemoved}
            onCellClicked={(params: CellClickedEvent) => onCellClicked(params)}
            onCellFocused={({ rowIndex, column, api }: CellFocusedEvent) => {
              if (!highlightedCell) return;
              const columnId = typeof column === 'string' ? column : column?.getColId();
              // As soon as a different cell from the search result is focused, turn highlighting off
              if (
                (!rowIndex && rowIndex !== 0) ||
                api.getModel().getRow(rowIndex)?.id !== highlightedCell.rowId ||
                columnId !== highlightedCell.colId
              ) {
                dispatch(setHighlightedCell(undefined));
              }
            }}
            onRangeSelectionChanged={onRangeSelectionChanged}
            onCellKeyDown={onCellKeyDown}
            groupingCellUpdates={groupingCellUpdates}
            onGroupUpdateStart={onGroupUpdateStart}
            onGroupUpdateComplete={onGroupUpdateComplete}
            onGroupUpdateEdit={onGroupUpdateEdit}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            sendToClipboard={(params: SendToClipboardParams) => sendToClipboard(params, showFormulas)}
            processDataFromClipboard={(params: ProcessDataFromClipboardParams) =>
              processDataFromClipboard(params, { cellMetadata, dispatch, pasteOptions })
            }
            onValueSetter={onColumnValueChanged}
            onColumnMoved={(event: ColumnEvent) => onColumnChanged(event, columnChangedParams)}
            onColumnResized={(event: ColumnEvent) => onColumnChanged(event, columnChangedParams)}
            onColumnPinned={(event: ColumnEvent) => onColumnChanged(event, columnChangedParams)}
            onColumnVisible={(event: ColumnEvent) => onColumnChanged(event, columnChangedParams)}
            onColumnEverythingChanged={(event: ColumnEvent) => onColumnChanged(event, columnChangedParams)}
            onRowDragMove={(params: RowDragMoveEvent) => onRowDragMove(params, selectedTableData)}
            onRowDragEnd={(params: RowDragEndEvent) => onRowDragEnd(params, clientDataTableId, cellMetadata, dispatch)}
            processHeaderForClipboard={processHeaderForClipboard}
            getCellRenderer={getCellRenderer}
          />
          <ClientDataCellHistoryPopover
            open={!!cellHistoryRowColumn}
            anchorEl={cellHistoryPopoverAnchorEl}
            onClose={() => displayCellHistory(null)}
            rowId={cellHistoryRowColumn?.rowId}
            column={cellHistoryRowColumn?.column}
          />
        </Panel>
        {changeSummaryOpen && (
          <>
            <GridResizeHandle />
            <Panel minSize={10} defaultSize={50} order={2} id="changes-summary-panel">
              <ClientDataChangesSummaryPanel onChangeFocused={onChangeSummaryFocusedChange} />
            </Panel>
          </>
        )}
      </PanelGroup>
      <ClientDataPreviewDialog />
      <ClientDataNoteDialog />
      <ClientDataCreateBranchDialog />
      <ClientDataRevertBranchDialog />
      <ClientDataPublishDialog />
      <ClientDataPublishResultDialog />
      <ClientDataCantPublishDialog />
      <ClientDataPublishUpdatesDialog />
      <ClientDataNewSupplierUpdatesDialog />
      <ClientDataRollbackDialog />
      <ClientDataVerifiedQuotesDialog />
      <ClientDataLoadOptionsToGenerateIconsDialog />
      <ClientDataCantGenerateIconsDialog />
      <ClientDataGenerateOptionIconDialog />
      <ClientDataGoToSourceDataDialog />
    </div>
  );
};
