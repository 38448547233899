/* eslint-disable no-param-reassign */
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';

import { createAppAsyncThunk } from './thunks';
import { SITE_DETAIL_TABLE, SiteDetailChange } from '../constants/ClientData';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { MaximumDigits } from '../constants/PricingSurcharge';
import { updateClientData, UpdateClientData } from '../ducks/clientDataSlice';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { addDispatchCommandToUndo } from '../utils/undoManagerUtils';

export const updateSiteDetails = createAppAsyncThunk<SiteDetailChange[], SiteDetailChange[]>(
  'clientData/updateSiteDetails',
  async (changes, { dispatch, getState }) => {
    const state = getState();
    const { clientData, currentUser } = state;
    const { group: { groupId } = unknownGroup } = currentUser;
    const { clientDataBranch = ClientDataBranch.Main, clientDataType, clientId } = clientData;

    const { data: [siteDetailTableData] = [] } = clientDataApi.endpoints.getClientDataTableData.select({
      dataType: clientDataType,
      clientId,
      groupId,
      branch: clientDataBranch,
      table: SITE_DETAIL_TABLE,
    })(state);

    const { [ClientDataFixedColumns.RowId]: rowId } = siteDetailTableData;
    const { oldRows: undoUpdates, newRows: updates } = changes.reduce(
      ({ oldRows, newRows }, { value: val, column: col, percentage: percent = false }) => {
        let columnValue = val;
        if (columnValue !== undefined && columnValue !== '' && percent && typeof val === 'string') {
          const valueAsString =
            val.length > MaximumDigits.PercentChange ? val.slice(0, MaximumDigits.PercentChange) : val;
          columnValue = (parseFloat(valueAsString) / 100).toString();
        }

        oldRows.push({
          table: SITE_DETAIL_TABLE,
          column: col,
          value: siteDetailTableData[col],
          rowData: {
            [ClientDataFixedColumns.RowId]: rowId,
            [col]: siteDetailTableData[col],
          },
        });
        newRows.push({
          table: SITE_DETAIL_TABLE,
          column: col,
          value: columnValue,
          rowData: {
            [ClientDataFixedColumns.RowId]: rowId,
            [col]: columnValue,
          },
        });
        return { oldRows, newRows };
      },
      { oldRows: [], newRows: [] } as { oldRows: UpdateClientData[]; newRows: UpdateClientData[] },
    );

    const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, SITE_DETAIL_TABLE);

    addDispatchCommandToUndo(
      dispatch,
      [updateClientData({ rows: undoUpdates, branch: ClientDataBranch.SiteDetail })],
      [updateClientData({ rows: updates, branch: ClientDataBranch.SiteDetail })],
      clientDataTableId,
      true,
    );

    return changes;
  },
);
