import React, { useCallback, useState } from 'react';
import { debounce, TextField, TextFieldProps } from '@mui/material';

interface DebounceProps {
  debounceTime?: number;
  onDebouncedChange?: (value: string) => void;
}

type CombinedProps = TextFieldProps & DebounceProps;

export const DebouncedTextField: React.FC<CombinedProps> = ({
  debounceTime = 300,
  onDebouncedChange,
  defaultValue = '',
  ...props
}) => {
  const [value, setValue] = useState(defaultValue as string);

  const debouncedChangeHandler = useCallback(
    debounce((newValue: string) => {
      onDebouncedChange?.(newValue);
    }, debounceTime),
    [debounceTime, onDebouncedChange],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedChangeHandler(newValue);
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField {...props} value={value} onChange={handleChange} />;
};
