import { Chip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { I18nKeys } from '../constants/I18nKeys';
import { BRANCH_LABELS } from '../constants/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';

const useStyles = makeStyles((theme: Theme) => ({
  branchChip: {
    margin: '12px 0px',
    borderRadius: '4px',
    '&.branchChip-undefined': {
      background: '#eaeaea',
      border: '1px solid #c2c1c1',
    },
    '&.branchChip-main': {
      background: '#DBE3D5',
      border: '1px solid #698350',
    },
    '&.branchChip-quick-update': {
      background: '#E7C5C7',
      border: '1px solid #A72927',
    },
    '&.branchChip-unpublished': {
      background: '#F9EAD9',
      border: '1px solid #E4A767',
    },
    '&.branchChip-professional-services': {
      background: '#80008020',
      border: '1px solid #800080',
    },
    '&.branchChip-client-update': {
      background: '#3E426720',
      border: '1px solid #3E4267',
    },
    '&.branchChip-pricing': {
      background: '#3E426720',
      border: '1px solid #3E4267',
    },
    '& .MuiChip-label': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize,
    },
  },
}));

export const ClientDataBranchChip = React.forwardRef<HTMLDivElement, { branch?: ClientDataBranch; className?: string }>(
  ({ branch, className, ...props }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Chip
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        className={`${classes.branchChip} branchChip-${branch} ${className || ''}`}
        label={branch ? t(BRANCH_LABELS[branch]) : t(I18nKeys.ClientDataBranchLoadingLabel)}
        size="small"
        variant="outlined"
      />
    );
  },
);
