import React, { useEffect } from 'react';
import { PriceColumn } from '@idearoom/types';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheetView } from './PricingSheetView';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setPriceByQuantity, setSelectedSizeBasedPricingSheetId } from '../ducks/pricingSlice';
import { getPriceColumnsByCategory, getPricingSheetRegions } from '../utils/pricingSheetUtils';
import { PricingSheetRegion, PriceColumnsByGroupId } from '../types/Region';
import { PriceByQuantity } from '../constants/PricingClientUpdate';
import { isUsingCombinedView, splitViewIsEnabled } from '../utils/pricingClientUpdateUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';

export const PricingSizeBasedPricingSheets: React.FC = () => {
  const dispatch = useAppDispatch();

  const pricingDataBranch = useAppSelector((state) => state.pricing.sizeBased.pricingDataBranch);
  const selectedCategoryKey = useAppSelector((state) => state.pricing.sizeBased.selectedCategoryKey);
  const pricingSheetId = useAppSelector((state) => state.pricing.sizeBased.selectedPricingSheetId);
  const priceByQuantity = useAppSelector((state) => state.pricing.sizeBased.priceByQuantity);

  const [regions, setRegions] = React.useState<PricingSheetRegion[]>([]);
  const [defaultPriceColumn, setDefaultPriceColumn] = React.useState<PriceColumnsByGroupId>({
    [PriceByQuantity.Each]: PriceColumn.price,
  });

  const {
    clientUpdateRegions = [],
    isLoadingClientUpdateRegions,
    sizeBasedCategoryPricingSheets: pricingSheets = [],
    isFetchingSizeBasedCategoryPricingSheets,
    isLoadingSizeBasedCategoryPricingSheets,
    errorLoadingSizeBasedCategoryPricingSheets,
    sizeBasedCategoryPricingSheetPrices: selectedPricingSheet,
    isFetchingSizeBasedCategoryPricingSheetPrices,
    isLoadingSizeBasedCategoryPricingSheetPrices,
    errorLoadingSizeBasedCategoryPricingSheetPrices,
    cellMetadata = [],
    isLoadingCellMetadata,
    cellMetadataDiff = [],
    isFetchingClientUpdateDiffs,
    clientUpdateDiffs,
  } = useClientUpdatePricingRepo({
    useClientUpdateRegions: true,
    useSizeBasedCategoryPricingSheets: true,
    useSizeBasedCategoryPricingSheetsPrices: true,
    useCellMetadata: true,
    useCellMetadataDiff: true,
    useClientUpdateDiffs: true,
  });

  // Reset price by quantity when changing the branch back to main (reverting the changes)
  useEffect(() => {
    if (pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setPriceByQuantity(undefined));
    }
  }, [pricingDataBranch]);

  useEffect(() => {
    if (priceByQuantity || !selectedPricingSheet || !clientUpdateRegions) return;

    if (splitViewIsEnabled(selectedPricingSheet)) {
      dispatch(setPriceByQuantity(PriceByQuantity.Split));
      return;
    }
    dispatch(
      setPriceByQuantity(
        isUsingCombinedView(selectedPricingSheet, clientUpdateRegions)
          ? PriceByQuantity.Combined
          : PriceByQuantity.Each,
      ),
    );
  }, [priceByQuantity, selectedPricingSheet, clientUpdateRegions, selectedCategoryKey, dispatch]);

  useEffect(() => {
    if (!selectedPricingSheet || priceByQuantity === undefined) return;
    const priceByQuantities =
      priceByQuantity === PriceByQuantity.Split ? [PriceByQuantity.Each, PriceByQuantity.Combined] : [priceByQuantity];
    setRegions(getPricingSheetRegions(selectedPricingSheet, clientUpdateRegions, priceByQuantities));
    setDefaultPriceColumn(
      Object.fromEntries(
        priceByQuantities.map((columnGroupId) => [
          columnGroupId,
          getPriceColumnsByCategory(PriceColumn.price, selectedPricingSheet, columnGroupId)[0],
        ]),
      ),
    );
  }, [pricingSheetId, isLoadingSizeBasedCategoryPricingSheetPrices, isLoadingClientUpdateRegions, priceByQuantity]);

  useEffect(() => {
    if (!pricingSheetId && pricingSheets.length && !isFetchingSizeBasedCategoryPricingSheets)
      dispatch(setSelectedSizeBasedPricingSheetId(pricingSheets[0].id));
  }, [pricingSheetId, pricingSheets, isFetchingSizeBasedCategoryPricingSheets, dispatch]);

  return (
    <PricingSheetView
      gridViewType={GridViewType.Grid}
      selectedPricingSheetId={pricingSheetId}
      selectedPricingSheet={selectedPricingSheet}
      pricingSheets={pricingSheets}
      errorLoadingPricingSheets={
        errorLoadingSizeBasedCategoryPricingSheets || errorLoadingSizeBasedCategoryPricingSheetPrices
      }
      isFetchingPricingSheets={
        isFetchingSizeBasedCategoryPricingSheets ||
        isFetchingSizeBasedCategoryPricingSheetPrices ||
        isFetchingClientUpdateDiffs
      }
      isLoadingPricingSheets={
        isLoadingClientUpdateRegions || isLoadingSizeBasedCategoryPricingSheets || isLoadingCellMetadata
      }
      isLoadingPricingSheetPrices={isLoadingSizeBasedCategoryPricingSheetPrices}
      regions={regions}
      defaultPriceColumn={defaultPriceColumn}
      cellMetadata={cellMetadata}
      cellMetadataDiff={cellMetadataDiff}
      pricingDiffs={clientUpdateDiffs}
    />
  );
};
