import moment from 'moment';
import { utils, read } from 'xlsx';
import { sanitizeInput } from './inputUtils';

/**
 * Takes a file and encodes it to a base64 string
 *
 * @param file file to be encoded
 * @returns file as an encode as base64 string
 */
export const toBase64 = (file: File | Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject): void => {
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      if (typeof reader.result === 'string') {
        // Replace the file data text at the beginning of the base64 string
        // data:image/imageType;base64 for images
        // data:application/octet-stream;base64 for KML files
        resolve(reader.result.replace(/^data:.*;base64,/, ''));
      } else {
        resolve(reader.result);
      }
    };
    reader.onerror = (error): void => reject(error);
  });

/**
 * Create a timestamped file name for a vendor file
 *
 * @param clientId ClientId used to get the vendor name
 * @param file Image object with a 'name' prop
 * @param prefix Prefix to add to the file name (eg. 'logo-')
 * @returns File name with a datetime stamp
 */
export const generateFileName = (clientId: string, file: any, prefix?: string): string => {
  const fileExtension = file.name.split('.').slice(-1);
  return `${prefix || ''}${clientId.substring(clientId.indexOf('-') + 1)}-${moment(new Date(Date())).format(
    'YYYYMMDDhhmmss',
  )}.${fileExtension}`;
};

/**
 * Takes a file name and removes the file extension
 *
 * @param fileName name of the file to remove the extension from
 * @returns file name without the extension
 */
export const removeFileExtension = (fileName: string): string => fileName.replace(/\.[^/.]+$/, '');

/**
 * Generates a CSV file from the given headers and rows
 *
 * @param headers headers for the CSV file
 * @param rows rows for the CSV file
 * @returns Blob of the CSV file
 */
export const generateCSVFile = (headers: string[], rows: string[][]) => {
  const escapedRows = [headers, ...rows].map(
    (row) => `"${row.map((cell) => `${cell || ''}`.replace(/"/g, '""')).join('","')}"`,
  );
  const csv = escapedRows.join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });

  return blob;
};

/**
 * Exports a CSV file to the user's browser
 *
 * @param fileName name of the file to be exported
 * @param headers headers for the CSV file
 * @param rows rows for the CSV file
 */
export const exportToCSV = (fileName: string, headers: string[], rows: string[][]) => {
  const csv = generateCSVFile(headers, rows);
  const url = URL.createObjectURL(csv);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

/**
 * Verify that the file size is less than the max size
 *
 * @param file file to be validated
 * @param maxSize max size in MB
 * @returns whether the file size is less than the max size
 */
export const validateFileSize = (file: File, maxSize: number): boolean => file.size <= maxSize * 1024 * 1024;

/**
 * Validates that the file is a valid spreadsheet file within the max size
 * and either a .csv or .xlsx file.
 *
 * @param file file to be validated
 * @returns error message if the file is invalid
 */
export const validateSpreadsheetFile = (file?: File): string => {
  if (!file) return 'Select a file to upload.';
  if (!validateFileSize(file, 15)) return 'File is too large. Maximum size is 15MB.';

  const allowedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  if (!allowedTypes.includes(file.type)) return 'File must be a .csv or .xlsx file.';

  return '';
};

/**
 * Reads a spreadsheet file and returns its contents as a csv string
 * Accepts .csv and .xlsx files
 *
 * @param file file to be read
 * @returns csv string
 */
export const readSpreadsheetFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the file based on its type
    reader.onload = (e) => {
      const data = e.target?.result;

      try {
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          // If the file is an xlsx file, parse it and convert it to CSV
          const workbook = read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const csv = utils.sheet_to_csv(sheet);
          resolve(csv);
        } else if (file.type === 'text/csv' && typeof data === 'string') {
          // If the file is already a CSV, just return its contents
          resolve(data);
        } else {
          reject(new Error('Unsupported file type'));
        }
      } catch (error) {
        const { message } = error as Error;
        reject(new Error(`Error processing file: ${message}`));
      }
    };

    reader.onerror = () => {
      reject(new Error('File read error'));
    };

    // Read the file based on its type
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      reader.readAsArrayBuffer(file);
    } else if (file.type === 'text/csv') {
      reader.readAsText(file);
    } else {
      reject(new Error('Unsupported file type'));
    }
  });

/**
 * Sanitizes a CSV string by escaping cells starting with =, +, -, or @
 *
 * @param csvString CSV string to be sanitized
 * @returns sanitized CSV string
 */
export const sanitizeCSV = (csvString: string): string => {
  // Escape cells starting with =, +, -, or @
  const formulaRegex = /(^|,)([=+\-@].*)/g;
  return sanitizeInput(csvString.replace(formulaRegex, (match, prefix, content) => `${prefix}'${content}`));
};

/**
 * Reads a spreadsheet file, sanitizes the input, and returns its contents as a cell data matrix
 * Accepts .csv and .xlsx files
 *
 * @param file file to be read
 * @returns cell data matrix
 */
export const importSpreadsheetFile = async (file: File): Promise<string[][]> => {
  let csvString = '';
  try {
    csvString = await readSpreadsheetFile(file);
  } catch (error) {
    const { message } = error as Error;
    throw new Error(message);
  }

  const csvRows = sanitizeCSV(csvString).split('\n');
  return csvRows.map((row) => row.split(',').map((cell) => cell.replace(/^"|"$/g, '').trim()));
};
