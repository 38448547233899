import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid2 as Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PriceColumn } from '@idearoom/types';
import { Check, Edit } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridCellParams,
  GridRenderCellParams,
  GridAlignment,
  GridColumnHeaderClassNamePropType,
  useGridApiRef,
  GridCellEditStartParams,
  MuiEvent,
} from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { setSelectedComponentId, updatePricingComponentRows } from '../ducks/pricingSlice';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import {
  ActionColumn,
  DisplayColumns,
  ComponentFormData,
  HelperColumns,
  MiscPriceColumns,
  PricingCalculationColumns,
  componentColumnMap,
  pricingComponentWidthProps,
} from '../constants/PricingClientUpdate';
import { I18nKeys } from '../constants/I18nKeys';
import { areDiffValuesDifferent, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import {
  filterComponentCategoryItems,
  formatComponentCategoryValue,
  getComponentCategoryColumns,
  getComponentCategoryItemRows,
  getComponentCategoryItems,
  getIdentifiersForComponentUpdate,
  groupComponentCategoryItems,
  isRegionalPricingField,
} from '../utils/pricingClientUpdateUtils';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { AppState } from '../types/AppState';
import { shouldShowKey, removeCurrencySymbolsCommasAndSpaces } from '../utils/pricingUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { PricingComponentEditFields } from '../constants/FormFields';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { areObjectsEqual } from '../utils/objectUtils';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { SortDirection } from '../constants/SortDirection';
import { sortByAlphanumericParts } from '../utils/sortUtils';
import { MuiDataGrid } from './MUIDataGrid';
import { onMuiDataGridKeyboardShortcut } from '../utils/muiDataGridKeyboardShortcutUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { ComponentCategoryItem, ConditionalPrice } from '../types/PricingClientUpdate';

const useStyles = makeStyles({
  content: {
    flex: 1,
    minHeight: 0,
  },
  tableGrid: {
    height: '100%',
    '& div[style*="z-index: 100000"]': {
      display: 'none',
    },
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    paddingBottom: '45px',
  },
  modified: {
    backgroundColor: 'rgb(228, 167, 103, 0.2) !important',
    '&.Mui-selected': {
      backgroundColor: 'rgba(228, 167, 103, 0.4) !important',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgba(228, 167, 103, 0.5) !important',
    },
  },
  componentLabel: {
    display: 'inline-block',
    fontSize: '14px',
  },
  componentKey: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  header: {
    fontWeight: 'bold',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      textWrap: 'wrap',
      textAlign: 'center',
    },
  },
  priceHeader: {
    '& .MuiDataGrid-columnHeaderTitle': {
      paddingRight: '4px',
      textAlign: 'end',
    },
  },
  priceCalculationCell: {
    paddingLeft: '6px',
  },
  priceCell: {
    paddingRight: '26px',
  },
  tableIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  noResultsOverlay: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type Props = {
  searchValue?: string;
};

export const PricingComponentCategoryItems: React.FC<Props> = ({ searchValue = '' }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<{ string: any }[]>([]);
  const [filteredRows, setFilteredRows] = useState<{ string: any }[]>([]);
  const [focusedCell, setFocusedCell] = useState<{ id: string; field: string } | undefined>(undefined);
  const [debouncedRows, setDebouncedRows] = useState<ComponentFormData[]>([]);

  const currency = useAppSelector((state) => state.viewer.currency);
  const categoryKey = useAppSelector((state) => state.pricing.component.selectedCategoryKey);
  const { [UserPreference.PricingComponentPreferences]: clientUpdatePreferences = {} } =
    useAppSelector((state) => state.currentUser.preferences) || {};
  const isCreatingBranch = useAppSelector((state) => state.clientData.isCreatingBranch);
  const { gridState: savedGridState = {} } = clientUpdatePreferences;

  const clientId = useAppSelector(
    ({ viewer: { selectedTabId, selectedClientId }, clientData: { clientId: clientDataId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataId || selectedTabId || selectedClientId || ''),
  );

  const { clientTableColumns } = useClientDataRepo({
    useClientTablesColumns: true,
  });

  const {
    componentCategoryItemsWithConditions = [],
    isLoadingComponentCategoryItemsWithConditions,
    clientUpdateDiffs = [],
    clientUpdateRegions: regions,
    clientUpdateStyles: styles,
  } = useClientUpdatePricingRepo({
    useClientUpdateDiffs: true,
    useComponentCategoryItemsWithConditions: true,
    useClientUpdateRegions: true,
    useClientUpdateStyles: true,
  });

  const saveGridState = () => {
    const state = apiRef.current?.exportState?.();
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.PricingComponentPreferences,
        preferences: {
          ...clientUpdatePreferences,
          gridState: state,
        },
      }),
    );
  };

  const getHeaderClassName = (column: string): GridColumnHeaderClassNamePropType => {
    const classList: string[] = [classes.header];
    if (Object.values(PriceColumn).includes(column as PriceColumn)) {
      classList.push(classes.priceHeader);
    }
    return classList.join(' ');
  };

  const getCellClassName = useCallback(
    (params: GridCellParams): string => {
      const { field, row } = params;
      const column = field as keyof ConditionalPrice | keyof ComponentCategoryItem;
      const classList: string[] = [];

      const rowWithConditions = componentCategoryItemsWithConditions.find(
        ({ item: i }) => i[ClientDataFixedColumns.RowId] === row[ClientDataFixedColumns.RowId],
      );

      if (rowWithConditions) {
        // Does a diff exist for this item or any of its conditions?
        const diffExists = [rowWithConditions.item, ...rowWithConditions.conditions].some((r) => {
          const columnValue = (
            r as Record<keyof ConditionalPrice | keyof ComponentCategoryItem, string | null | Record<string, string>>
          )[column];
          const columnRowId =
            typeof columnValue === 'object' && columnValue !== null
              ? columnValue[ClientDataFixedColumns.RowId]
              : r[ClientDataFixedColumns.RowId];
          const diff = clientUpdateDiffs
            .flatMap(({ changes }) => changes || [])
            .find(({ rowId: diffRowId }) => diffRowId === columnRowId);
          const columnDiff = getRowDiffColumnToFromValues(diff, field);
          return areDiffValuesDifferent(columnDiff?.from, columnDiff?.to);
        });
        if (diffExists) classList.push(classes.modified);
      }

      if (Object.values(PriceColumn).includes(field as PriceColumn)) {
        classList.push(classes.priceCell);
      } else if (field === PricingCalculationColumns.PriceCalculation) {
        classList.push(classes.priceCalculationCell);
      }

      return classList.join(' ');
    },
    [clientUpdateDiffs, classes, componentCategoryItemsWithConditions],
  );

  const getColumnAlignment = (column: string): GridAlignment => {
    if ((Object.values(PriceColumn) as string[]).includes(column)) {
      return 'right';
    }
    if (([HelperColumns.VariesByRegion, ActionColumn.Edit] as string[]).includes(column)) {
      return 'center';
    }
    return 'left';
  };

  const isCellEditable = (params: GridCellParams): boolean => {
    const { field, row } = params;
    if (PricingCalculationColumns.PriceCalculation === field) return false;
    if (HelperColumns.AppliesTo === field) {
      return false;
    }
    if (!row[HelperColumns.VariesByRegion] && isRegionalPricingField(field)) {
      return false;
    }

    if (
      MiscPriceColumns.UpgradePrice === field &&
      !parseFloat(removeCurrencySymbolsCommasAndSpaces(row[MiscPriceColumns.UpgradePrice]))
    ) {
      return false;
    }
    return true;
  };

  const debounceProcessedRows = useMemo(() => {
    const onComponentUpdateHandler = () => {
      setDebouncedRows((prevDebouncedRows) => {
        dispatch(updatePricingComponentRows(prevDebouncedRows));
        return [];
      });
    };
    return debounce(onComponentUpdateHandler, 400);
  }, []);

  useEffect(() => {
    if (debouncedRows.length > 0) {
      debounceProcessedRows();
    }
  }, [debounceProcessedRows, debouncedRows]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(
    () => () => {
      debounceProcessedRows.cancel();
    },
    [],
  );

  const processRowUpdate = (newRow: any): any => {
    const formData: ComponentFormData = {
      [PricingComponentEditFields.Component]: newRow[ClientDataFixedColumns.RowId],
      [PricingComponentEditFields.Table]: newRow.table,
      [DisplayColumns.Label]: newRow[DisplayColumns.Label],
      ...Object.fromEntries(
        Object.keys(PriceColumn)
          .filter((column) => (clientTableColumns[newRow.table] || []).includes(column))
          .map((column) => [column, newRow[column]]),
      ),
      [DisplayColumns.PriceExpression]: newRow[DisplayColumns.PriceExpression],
      [MiscPriceColumns.UpgradePrice]: newRow[MiscPriceColumns.UpgradePrice],
      [HelperColumns.VariesByRegion]: newRow[HelperColumns.VariesByRegion],
      [HelperColumns.Key]: newRow[HelperColumns.Key],
    };

    setDebouncedRows((previousDebouncedRows) => [...previousDebouncedRows, formData]);

    // Rows must be updated for regional pricing checkbox state to persist
    const index = rows.findIndex((r: any) => r[ClientDataFixedColumns.RowId] === newRow[ClientDataFixedColumns.RowId]);
    if (index !== -1) {
      setRows((prevRows) => [...prevRows.slice(0, index), newRow, ...prevRows.slice(index + 1)]);
    }

    return newRow;
  };

  const getColumnCellRenderer = (column: string): { renderCell: GridColDef['renderCell'] } | undefined => {
    if (column === HelperColumns.VariesByRegion) {
      return {
        renderCell: (params: GridRenderCellParams) => {
          const { field, row } = params;
          return row[field] ? <Check className={classes.tableIcon} /> : null;
        },
      };
    }
    if (column === DisplayColumns.Label) {
      return {
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          const showKey = shouldShowKey(row, categoryKey, componentCategoryItemsWithConditions);
          return showKey ? (
            <Typography className={classes.componentLabel}>
              {row.label} <span className={classes.componentKey}> ({row.key})</span>
            </Typography>
          ) : (
            row.label
          );
        },
      };
    }
    if (isRegionalPricingField(column)) {
      return {
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          if (row[HelperColumns.VariesByRegion]) {
            return row[column];
          }
          return '';
        },
      };
    }
    return undefined;
  };

  const customNoResultsOverlay = () => (
    <div className={classes.noResultsOverlay}>{t(I18nKeys.PricingComponentNoComponentsFound)}</div>
  );

  const columns: GridColDef[] = useMemo(
    () =>
      getComponentCategoryColumns(componentCategoryItemsWithConditions, clientTableColumns, regions)
        .sort((a, b) => (componentColumnMap[a]?.order || 0) - (componentColumnMap[b]?.order || 0))
        .map((column: string) => {
          let label = compoundCaseToTitleCase(column);
          if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
            label = t(I18nKeys.PricingCalculationField);
          }
          if (Object.values(PriceColumn).some((v) => v !== PriceColumn.price && v === column)) {
            label = regions?.find(({ priceColumn: regionPriceColumn }) => regionPriceColumn === column)?.label || label;
          }
          if (column === ActionColumn.Edit) {
            return {
              field: column,
              headerName: label,
              type: 'actions',
              headerClassName: getHeaderClassName(column),
              headerAlign: getColumnAlignment(column),
              ...pricingComponentWidthProps[column],
              pinned: 'right',
              getActions: ({ id }) => [
                <GridActionsCellItem
                  icon={<Edit className={classes.tableIcon} />}
                  label="Edit"
                  className="textPrimary"
                  onClick={(): void => {
                    dispatch(setSelectedComponentId(id));
                    dispatch(openDialog({ dialog: Dialogs.PricingComponentEdit }));
                  }}
                  color="inherit"
                />,
              ],
            };
          }
          return {
            field: column,
            headerName: t(label),
            ...pricingComponentWidthProps[column],
            editable: PricingCalculationColumns.PriceCalculation !== column,
            type: column === HelperColumns.VariesByRegion ? 'boolean' : 'string',
            headerClassName: getHeaderClassName(column),
            headerAlign: getColumnAlignment(column),
            align: getColumnAlignment(column),
            ...getColumnCellRenderer(column),
            valueGetter: (_value: string, row: any): string =>
              formatComponentCategoryValue(
                column,
                row,
                categoryKey,
                componentCategoryItemsWithConditions,
                styles,
                currency,
              ),
            sortComparator: (a: any, b: any): number => sortByAlphanumericParts(a, b, SortDirection.Asc),
          };
        }),
    [
      classes.tableIcon,
      componentCategoryItemsWithConditions,
      regions,
      styles,
      clientTableColumns,
      currency,
      categoryKey,
      t,
    ],
  );

  useEffect(() => {
    const newRows = getComponentCategoryItemRows(
      categoryKey,
      componentCategoryItemsWithConditions,
      rows || [],
      currency,
    );
    if (!areObjectsEqual(rows, newRows)) setRows(newRows);

    const groupedCategoryItems = groupComponentCategoryItems(categoryKey, componentCategoryItemsWithConditions);
    const filteredItems = filterComponentCategoryItems(searchValue, groupedCategoryItems);
    const newFilteredRows = newRows.filter((row: any) =>
      getComponentCategoryItems(filteredItems).some(
        (f) => f[ClientDataFixedColumns.RowId] === row[ClientDataFixedColumns.RowId],
      ),
    );
    if (!areObjectsEqual(filteredRows, newFilteredRows)) setFilteredRows(newFilteredRows);
  }, [categoryKey, componentCategoryItemsWithConditions, searchValue]);

  useEffect(() => {
    const { clientCategoryKey } = getIdentifiersForComponentUpdate(
      clientId,
      ClientDataType.Supplier,
      '',
      undefined,
      undefined,
      categoryKey,
    );
    const onKeyDown = (e: KeyboardEvent) =>
      onMuiDataGridKeyboardShortcut(e, clientCategoryKey, focusedCell, apiRef.current, dispatch);

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  useEffect(() => {
    // Track the current focused cell, which is the highlighted cell via keyboard navigation
    // The focused cell can be different from the selected cells in the selection model
    const handleCellFocusIn = (params: GridCellParams) => {
      const { id, field } = params;
      setFocusedCell({ id: `${id}`, field });
    };
    const unsubscribeFromEvent = apiRef.current?.subscribeEvent?.('cellFocusIn', handleCellFocusIn);

    return () => {
      unsubscribeFromEvent?.();
    };
  }, [apiRef.current]);

  if (isLoadingComponentCategoryItemsWithConditions || isCreatingBranch)
    return <CircularProgress style={{ alignSelf: 'center' }} color="primary" />;
  return (
    <Grid container className={classes.content} spacing={1}>
      <Grid size={{ xs: 12 }} className={classes.tableGrid}>
        <MuiDataGrid
          apiRef={apiRef}
          loading={isLoadingComponentCategoryItemsWithConditions || isCreatingBranch}
          rows={filteredRows}
          columns={columns}
          autoHeight={false}
          cellSelection
          checkboxSelection
          disableRowSelectionOnClick
          ignoreValueFormatterDuringExport={false}
          disableClipboardPaste
          getCellClassName={getCellClassName}
          isCellEditable={isCellEditable}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[100]}
          onProcessRowUpdateError={(error: any): void => console.error(error)}
          onSortModelChange={() => saveGridState()}
          getRowId={(row) => row[ClientDataFixedColumns.RowId]}
          showToolbar={false}
          initialState={{
            ...savedGridState,
            pinnedColumns: {
              ...(savedGridState.pinnedColumns || {}),
              right: Array.from(new Set([ActionColumn.Edit, ...(savedGridState?.pinnedColumns?.right || [])])),
            },
          }}
          slots={{
            noRowsOverlay: customNoResultsOverlay,
          }}
          countParams={{
            countLabel: I18nKeys.ComponentCategoryItemCount,
            filteredLabel: I18nKeys.ComponentCategoryItemFilteredCount,
          }}
          onCellEditStart={(_: GridCellEditStartParams, event: MuiEvent & KeyboardEvent) => {
            const { metaKey, ctrlKey, key } = event;
            // Prevent opening the cell editor on paste
            if ((metaKey || ctrlKey) && key === 'v') {
              // eslint-disable-next-line no-param-reassign
              event.defaultMuiPrevented = true;
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
