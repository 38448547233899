/* eslint-disable react-hooks/rules-of-hooks */
import { unknownGroup } from '../constants/Group';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { OnlyExistingKeys } from '../types/Repo';
import { AuthStatus } from '../constants/AuthStatus';
import { Translation } from '../types/Translations';
import { useGetVendorDataTranslationsQuery } from '../services/vendorDataApi';

const EMPTY_TRANSLATIONS_REFERENCE: Readonly<{ translation: Translation; regionTranslation: Translation }> = {
  translation: {},
  regionTranslation: {},
};

type UseVendorDataRepoReturn<UseTranslations> = OnlyExistingKeys<{
  translations: UseTranslations extends true ? { translation: Translation; regionTranslation: Translation } : never;
}>;

export const useVendorDataRepo = <UseTranslations extends boolean = false>({
  useTranslations,
}: {
  useTranslations?: UseTranslations;
} = {}): UseVendorDataRepoReturn<UseTranslations> => {
  const clientId = useAppSelector(
    ({ viewer: { selectedClientId, selectedTabId }, clientData: { clientId: clientDataClientId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataClientId || selectedTabId || selectedClientId || ''),
  );
  const { authStatus, group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const isSignedIn = authStatus === AuthStatus.SignedIn;

  const result: any = { isPricingBranchDeleted: false };

  if (useTranslations) {
    const skip = !isSignedIn || !clientId;
    const { currentData: translations = EMPTY_TRANSLATIONS_REFERENCE } = useGetVendorDataTranslationsQuery(
      {
        clientId,
        groupId,
      },
      {
        skip,
        refetchOnMountOrArgChange: true,
      },
    );
    result.translations = translations;
  }

  return result;
};
