/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { DealersState } from '../types/DealersState';
import { Dealer } from '../types/Dealer';

const InitialDealersState: DealersState = {
  dealers: [],
  dialogClientId: undefined,
  dialogDealer: undefined,
  loading: false,
  logoUrl: undefined,
};

export const dealersSlice = createSlice({
  name: 'dealers',
  initialState: InitialDealersState,
  reducers: {
    fetchDealers(state, _action: PayloadAction<string>) {
      state.dealers = [];
      state.loading = true;
    },

    fetchDealersSuccess(state, action: PayloadAction<{ dealers: Dealer[]; logoUrl: string | undefined }>) {
      const {
        payload: { dealers, logoUrl },
      } = action;

      state.dealers = dealers;
      state.loading = false;
      state.logoUrl = logoUrl;
    },

    fetchDealersError(state) {
      state.dealers = [];
      state.loading = false;
      state.logoUrl = undefined;
    },

    saveDealerSuccess(state, action: PayloadAction<{ dealer: Dealer }>) {
      const {
        payload: { dealer },
      } = action;

      const { dealers } = state;
      const newDealers = dealers.map((d) => (d.key === dealer?.key ? dealer : d));

      state.dealers = newDealers;
    },

    removeDealerSuccess(state, action: PayloadAction<{ dealer: Dealer }>) {
      const {
        payload: { dealer: { key } = {} },
      } = action;

      const { dealers } = state;
      const newDealers = dealers.filter((d) => d.key !== key);

      state.dealers = newDealers;
    },

    setDealerDialog(
      state,
      action: PayloadAction<{ dialogDealer?: Dealer; dialogClientId?: string; logoUrl?: string }>,
    ) {
      const {
        payload: { dialogDealer, dialogClientId, logoUrl },
      } = action;

      state.dialogDealer = dialogDealer;
      state.dialogClientId = dialogClientId;
      state.logoUrl = logoUrl;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const {
  fetchDealers,
  fetchDealersError,
  fetchDealersSuccess,
  removeDealerSuccess,
  saveDealerSuccess,
  setDealerDialog,
  setLoading,
} = dealersSlice.actions;

export const removeDealer = createAction<{ clientId: string; dealer: Dealer }>('dealers/removeDealer');
