import { API } from '@aws-amplify/api';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { extractErrorProps } from './errorUtils';
import { FetchError } from '../types/API';
import { compoundCaseToKebabCase } from './stringUtils';

const defaultErrorMessage = 'Something went wrong. Please try again.';

/**
 * @returns undefined if the error message is not a foreign key error.
 * @returns message in format of "X" is not valid for the "Y" column." if the error message is a foreign key error.
 *
 * Example input error message:
 *   "insert into `sup_base` (`baseType`, ..., `useSkidPositions`) values ('skids', ..., 1) ON DUPLICATE KEY UPDATE `baseType`=VALUES(`baseType`), ..., `useSkidPositions`=VALUES(`useSkidPositions`) - cannot add or update a child row - Foreign key violation on fk: `sup_base_skidmaterial_foreign`, table: `sup_base`, referenced table: `ref_material`, key: `[legacyasd]`"
 */
export const formatSqlForeignKeyErrorMessage = (errorMessage: string): string | undefined => {
  const match = errorMessage.match(
    /a (.+?) row - Foreign key violation on fk: `(.+?)(?:_foreign)?`, table: `(.+?)`, (?:referenced table: `.+?`, )?key: `\[(.+?)\]`/,
  );

  if (!match) {
    return undefined;
  }

  // If edit to child row is the cause of the error, return a user-friendly error message.
  const [, rowType, column, table, fieldValue] = match;

  const friendlyColumn = column.replace(`${table.toLowerCase()}_`, ''); // Remove table from beginning of column name

  // In the case that the child row is changed, but violated constraint.
  if (rowType === 'child') {
    return `"${fieldValue}" is not valid for the "${friendlyColumn}" column.`;
  }

  // In the case that the parent row is changed, but violated constraint.
  if (rowType === 'parent') {
    const friendlyTable = table.toLowerCase().replace('sup_', '').replace('ven_', ' ').replace('ref_', '');
    return `"${fieldValue}" cannot be changed or deleted because it is being referenced by the "${friendlyColumn}" column of a row in "${friendlyTable}" table.`;
  }

  return undefined;
};

export const displayIfMessageNotIncludes = (text: string) => (errorMessage: string, status?: number) =>
  !(!!status && status >= 400 && !!errorMessage && errorMessage.includes(text));

export const amplifyAPIBaseQuery =
  (
    { apiName, baseUrl }: { apiName: string; baseUrl: string } = { apiName: '', baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: 'get' | 'put' | 'post' | 'del';
      init?: {
        [key: string]: any;
      };
      /** @param errorMessage is after optional formatting via `formatErrorMessage` */
      displayToastOnError?: boolean | ((errorMessage: string, status?: number) => boolean);
      formatErrorMessage?: (errorMessage: string) => string;
    },
    unknown,
    FetchError
  > =>
  async ({ url, method, init = {}, displayToastOnError = true, formatErrorMessage }) => {
    try {
      const result = await API[method](apiName, `${baseUrl}${url}`, init);
      return { data: result };
    } catch (error) {
      const { errorMessage = defaultErrorMessage, status } = extractErrorProps(error);

      let showToast = false;
      switch (typeof displayToastOnError) {
        case 'function': {
          showToast = displayToastOnError(errorMessage, status);
          break;
        }
        default:
          showToast = displayToastOnError;
      }

      const formattedErrorMessage = formatErrorMessage ? formatErrorMessage(errorMessage) : errorMessage;

      if (showToast) {
        toast.error(formattedErrorMessage);
      }

      return {
        error: {
          status,
          data: formattedErrorMessage,
        },
      };
    }
  };

export const getRequestHeader = (fields: { [key: string]: any }) =>
  Object.keys(fields).reduce((acc, key) => ({ ...acc, [compoundCaseToKebabCase(key)]: `${fields[key]}` }), {});

export const getEndpointCacheKey = (endpoint: string, args: any) =>
  `${endpoint}(${JSON.stringify(args, Object.keys(args).sort())})`;
