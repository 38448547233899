import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataBranchMetadata } from '../types/ClientData';
import { PublishBarButton } from '../types/Viewer';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';

import { ClientDataType } from '../constants/ClientDataType';
import { config } from '../config/config';
import { sitesText } from '../constants/VendorData';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { MergeStatus } from '../constants/ClientData';
import { setSiteDetailPublishMergeResult } from '../ducks/vendorDataSlice';
import { getConfiguratorPreviewUrl, getSetBranch } from './clientDataUtils';
import { mapClientIdToConfiguratorAndVendor } from './clientIdUtils';
import { getConfiguratorUrlWithLocale } from './vendorUtils';
import { useDeleteBranchMutation, usePublishClientDataMutation } from '../services/clientDataApi';
import { openNotificationDialog } from '../ducks/notificationDialog';

export const getSharedPricingButtons = ({
  dispatch,
  t,
  clientId,
  clientDataType,
  groupId,
  branch,
  activeBranches,
  deleteBranch,
  validate,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  clientDataType: ClientDataType;
  groupId: string;
  branch: ClientDataBranch;
  activeBranches: ClientDataBranchMetadata[];
  deleteBranch: ReturnType<typeof useDeleteBranchMutation>[0];
  validate?: () => string;
  isPublishing: boolean;
}): PublishBarButton[] => {
  // Disable buttons if branch does not exist
  const branchExists = !!activeBranches.find((b) => b.branchType === branch);
  let dialog: Dialogs;
  let dialogId: string;
  switch (branch) {
    case ClientDataBranch.Pricing:
      dialog = Dialogs.PricingBasePreview;
      dialogId = 'pricing-base';
      break;
    case ClientDataBranch.ClientUpdate:
      dialog = Dialogs.PricingClientUpdatePreview;
      dialogId = 'pricing-component';
      break;
    case ClientDataBranch.PricingSizeBased:
      dialog = Dialogs.PricingClientUpdatePreview;
      dialogId = 'pricing-size-based';
      break;
    default:
      dialog = Dialogs.PricingSurchargePreview;
      dialogId = 'pricing-surcharge';
      break;
  }
  const setBranch = getSetBranch(branch);

  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationTitle
                : I18nKeys.PricingBaseRevertConfirmationTitle,
            ),
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationMessage
                : I18nKeys.PricingBaseRevertConfirmationMessage,
            ),
            undefined,
            [
              {
                onClick: () => {
                  if (branchExists) {
                    dispatch(setBranch(ClientDataBranch.Main));
                    deleteBranch({
                      dataType: clientDataType,
                      branch,
                      clientId,
                      groupId,
                    })
                      .unwrap()
                      .catch(() => {
                        dispatch(setBranch(branch));
                      });
                  }
                },
                label: t(I18nKeys.RevertButton),
              },
            ],
            false,
            `${dialogId}-revert-`, // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: () => {
        const validationMessage = validate?.();
        if (!validationMessage) {
          dispatch(
            openDialog({
              dialog,
            }),
          );
        } else {
          dispatch(openNotificationDialog('', validationMessage));
          dispatch(openDialog({ dialog: Dialogs.Notification }));
        }
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: () => {
        const validationMessage = validate?.();
        if (!validationMessage) {
          dispatch(openDialog({ dialog: Dialogs.PricingPublish }));
        } else {
          dispatch(openNotificationDialog('', validationMessage));
          dispatch(openDialog({ dialog: Dialogs.Notification }));
        }
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};

export const getSitesButtons = ({
  dispatch,
  t,
  clientId,
  groupId,
  clientDataType,
  clientDataBranch,
  locale,
  productionURL,
  deleteBranch,
  publishClientData,
  isCreatingBranch,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  groupId: string;
  clientDataType: ClientDataType;
  clientDataBranch: ClientDataBranch | undefined;
  locale: string;
  productionURL: string;
  deleteBranch: ReturnType<typeof useDeleteBranchMutation>[0];
  publishClientData: ReturnType<typeof usePublishClientDataMutation>[0];
  isCreatingBranch: boolean;
  isPublishing: boolean;
}): PublishBarButton[] => {
  const disabled = clientDataBranch !== ClientDataBranch.SiteDetail || isCreatingBranch || isPublishing;
  const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
  const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(sitesText.revertConfirmationTitle),
            t(sitesText.revertConfirmationBody),
            undefined,
            [
              {
                onClick: () => {
                  if (clientDataBranch) {
                    dispatch(setClientDataBranch(ClientDataBranch.Main));
                    deleteBranch({
                      dataType: clientDataType,
                      branch: clientDataBranch,
                      clientId,
                      groupId,
                    })
                      .unwrap()
                      .catch(() => {
                        dispatch(setClientDataBranch(clientDataBranch));
                      });
                  }
                },
              },
            ],
            false,
            'sites-revert', // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: (): void => {
        const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientDataBranch, undefined, undefined);
        window.open(previewUrl, '_blank', 'noopener noreferrer');
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(sitesText.publishConfirmationTitle),
            t(sitesText.publishConfirmationBody, { env: config.environment.STAGE || '' }),
            undefined,
            [
              {
                onClick: () => {
                  if (clientDataBranch) {
                    publishClientData({
                      dataType: clientDataType,
                      branch: clientDataBranch,
                      clientId,
                      groupId,
                      message: `Publishing site details for ${clientId}`,
                    })
                      .unwrap()
                      .then((result) => {
                        const data = result;
                        if (data?.mainMerge?.status === MergeStatus.Succeed) {
                          dispatch(setClientDataBranch(ClientDataBranch.Main));
                        }
                        dispatch(setSiteDetailPublishMergeResult({ data, isSuccess: true, error: undefined }));
                      })
                      .catch((error) => {
                        dispatch(setSiteDetailPublishMergeResult({ data: undefined, isSuccess: false, error }));
                        dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                      })
                      .finally(() => {
                        dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                      });
                  }
                },
              },
            ],
            false,
            'sites-publish', // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};
