import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppState } from '../types/AppState';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { COMMIT_SAVE_PREFIX, CellMetadataProperty } from '../constants/ClientData';
import {
  UpdateClientDataMetadata,
  addClientDataRows,
  removeClientDataRows,
  saveClientDataComplete,
  saveClientDataStart,
  setClientDataBranch,
  setCreatingBranch,
  setCreatingBranchComplete,
  updateClientData,
  updateClientDataMetadata,
  updateClientDataRows,
} from '../ducks/clientDataSlice';
import { clientDataApi } from '../services/clientDataApi';
import { CellMetadata, ClientDataBranchMetadata } from '../types/ClientData';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { getUpdatedCellMetadata, getUpdatedCellsMetadata } from '../utils/metadataUtils';
import { GridData, TableData } from '../types/DataGrid';
import {
  evaluateFormula,
  getClientDataBranchFromUrl,
  getSelectedBranchForUpdate,
  parseFormula,
} from '../utils/clientDataUtils';
import { unknownGroup } from '../constants/Group';
import { getPathPart } from '../utils/urlUtils';
import { RouteKeys } from '../constants/AppRoutes';

export const clientDataListener = createListenerMiddleware<AppState>();

/**
 * Updates the currently selected branch when clientData.clientId or clientData.clientDataType changes
 */
clientDataListener.startListening({
  predicate: (action, currentState, originalState) => {
    const { clientData } = currentState;
    const { clientData: previousClientData } = originalState;
    return (
      clientData.clientId !== previousClientData.clientId ||
      clientData.clientDataType !== previousClientData.clientDataType
    );
  },
  effect: async (action, listenerApi) => {
    const {
      clientData,
      currentUser: { group: { groupId } = unknownGroup },
    } = listenerApi.getState();
    listenerApi.dispatch(setClientDataBranch(undefined));

    let clientDataBranches: ClientDataBranchMetadata[] = [];
    if (clientData.clientId && clientData.clientDataType) {
      const clientDataBranchsListener = listenerApi.dispatch(
        clientDataApi.endpoints.getClientDataBranches.initiate(
          {
            dataType: clientData.clientDataType,
            clientId: clientData.clientId,
            groupId,
          },
          // Force refetches because the active branches might have changed
          { forceRefetch: true },
        ),
      );
      clientDataBranches = (await clientDataBranchsListener.unwrap()) as ClientDataBranchMetadata[];
      clientDataBranchsListener.unsubscribe();
    }

    let { clientDataBranch } = clientData;
    if (clientDataBranches.length > 0) {
      const pathBranch = getClientDataBranchFromUrl();
      if (pathBranch) {
        const branchIsActive = clientDataBranches.some((branch) => branch.branchType === pathBranch);
        clientDataBranch = branchIsActive ? (pathBranch as ClientDataBranch) : ClientDataBranch.Main;
      }

      const route = getPathPart(2);
      const currentBranchIsStillActive = clientDataBranches.some(
        (branch) => !([RouteKeys.SitesKey] as string[]).includes(route) && branch.branchType === clientDataBranch,
      );
      if (!currentBranchIsStillActive) {
        if (route === RouteKeys.SitesKey) {
          clientDataBranch = clientDataBranches.some((branch) => branch.branchType === ClientDataBranch.SiteDetail)
            ? ClientDataBranch.SiteDetail
            : ClientDataBranch.Main;
        } else if (clientDataBranches.some((branch) => branch.branchType === ClientDataBranch.Unpublished)) {
          // Automatically sets the branch to Unpublished if it exists
          clientDataBranch = ClientDataBranch.Unpublished;
        } else if (clientDataBranches.some((branch) => branch.branchType === ClientDataBranch.Hotfix)) {
          // Sets to Hotfix instead
          clientDataBranch = ClientDataBranch.Hotfix;
        } else {
          clientDataBranch = ClientDataBranch.Main;
        }
      }
    } else {
      clientDataBranch = undefined;
    }
    listenerApi.dispatch(setClientDataBranch(clientDataBranch));
  },
});

/**
 * Updates/Delete rows from the database
 *
 * @returns
 */
const updateClientDataEffect = async (
  data: GridData,
  metadata: {
    [table: string]: CellMetadata[];
  } | null,
  isDelete: boolean,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
  branch?: ClientDataBranch,
  setBranch?: (branch: ClientDataBranch | undefined) => AnyAction,
) => {
  try {
    dispatch(saveClientDataStart());

    const { clientData, currentUser } = state;
    const { clientId, clientDataType, clientDataBranch } = clientData;
    const { user, group: { groupId } = unknownGroup } = currentUser;

    if (!clientDataBranch) {
      toast.error('No branch selected.');
      return;
    }

    let updateBranch = getSelectedBranchForUpdate(state, branch || clientDataBranch);
    let newBranch = false;
    if (updateBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      updateBranch = branch || ClientDataBranch.Unpublished;
      newBranch = true;
    }

    if (isDelete) {
      await dispatch(
        clientDataApi.endpoints.deleteClientData.initiate({
          clientId,
          dataType: clientDataType,
          branch: updateBranch,
          data,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
          newBranch,
          user,
          groupId,
        }),
      );
    } else {
      await dispatch(
        clientDataApi.endpoints.updateClientData.initiate({
          clientId,
          groupId,
          dataType: clientDataType,
          branch: updateBranch,
          data,
          metadata,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
          newBranch,
          user,
        }),
      );
    }
    dispatch(setBranch ? setBranch(updateBranch) : setClientDataBranch(updateBranch));
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

clientDataListener.startListening({
  actionCreator: updateClientData,
  effect: async (action, { dispatch, getState }) => {
    const {
      payload: { rows, branch, setBranch },
    } = action;
    const state = getState();
    const { selectedTable } = state.clientData;

    const tablesData: { [table: string]: TableData[] } = {};
    rows.forEach(({ rowData, table = selectedTable, column, value }) => {
      tablesData[table] = tablesData[table] || [];
      const { [ClientDataFixedColumns.RowId]: rowId } = rowData;
      const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);
      if (existingRow) {
        existingRow[column] = value;
      } else {
        tablesData[table] = [
          ...tablesData[table],
          {
            ...rowData,
            [column]: value,
          },
        ];
      }
    });

    await updateClientDataEffect(tablesData, null, false, dispatch, state, branch, setBranch);
  },
});

clientDataListener.startListening({
  actionCreator: updateClientDataRows,
  effect: async (action, { dispatch, getState }) => {
    const { payload: rows } = action;
    const state = getState();
    const { selectedTable, clientId, clientDataType, clientDataBranch = ClientDataBranch.Main } = state.clientData;
    const { group: { groupId } = unknownGroup } = state.currentUser;

    const cellMetadataFetch = dispatch(
      clientDataApi.endpoints.getClientDataCellMetadata.initiate({
        dataType: clientDataType,
        clientId,
        table: selectedTable,
        branch: clientDataBranch,
        groupId,
      }),
    );
    // Must unsubscribe from RTK Query cache so the data doesn't hang
    cellMetadataFetch.unsubscribe();
    let { data: cellsMetadata = [] } = await cellMetadataFetch;

    const tablesData: { [table: string]: TableData[] } = {};
    const tablesCellMetadata: { [table: string]: CellMetadata[] } = {};
    rows.forEach(({ rowData, table = selectedTable, column, value, formula }) => {
      tablesData[table] = tablesData[table] || [];
      const { [ClientDataFixedColumns.RowId]: rowId } = rowData;
      let updatedMetadata: CellMetadata | null = null;

      if (selectedTable === table) {
        // FIXME: We can't replace metadata for non selected tables
        updatedMetadata = getUpdatedCellMetadata(
          clientId,
          cellsMetadata,
          selectedTable,
          rowData[ClientDataFixedColumns.RowId],
          column,
          CellMetadataProperty.Formula,
          formula,
        );
        if (updatedMetadata) {
          tablesCellMetadata[table] = [
            ...(tablesCellMetadata[table] || []).filter((metadata) => metadata.rowId !== rowId),
            updatedMetadata,
          ];
          // Updating overall cell metadata so this metadata update is not overwritten by getUpdatedCellMetadata in
          // remaining updates to the same row
          cellsMetadata = [...cellsMetadata.filter((metadata) => metadata.rowId !== rowId), updatedMetadata];
        }
      }

      // If there is cell metadata for the table, check if there is a formula for any columns
      const tableCellMetadata = updatedMetadata || cellsMetadata.find((m) => m.rowId === rowId);
      const rowFormulas: { [col: string]: string } = {};
      if (tableCellMetadata) {
        const { metadata } = tableCellMetadata;
        if (metadata) {
          // Pull out the formula for each column adding it to the rowFormulas object
          Object.keys(metadata).forEach((col) => {
            const colMetadata = metadata[col];
            if (colMetadata && colMetadata[CellMetadataProperty.Formula]) {
              rowFormulas[col] = colMetadata[CellMetadataProperty.Formula] || '';
            }
          });
        }
      }

      const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);

      const updatedRow = existingRow || { ...rowData };
      updatedRow[column] = value;
      // If there are formulas for the row, evaluate them and update the corresponding columns
      Object.keys(rowFormulas).forEach((col) => {
        const parsedFormula = parseFormula(rowFormulas[col]);
        updatedRow[col] = evaluateFormula(parsedFormula, updatedRow);
      });

      if (!existingRow) {
        tablesData[table] = [...tablesData[table], updatedRow];
      }
    });

    await updateClientDataEffect(tablesData, tablesCellMetadata, false, dispatch, state);
  },
});

clientDataListener.startListening({
  actionCreator: addClientDataRows,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { selectedTable } = state.clientData;
    const {
      payload: { rows, table = selectedTable },
    } = action;

    const data = { [table]: rows };
    await updateClientDataEffect(data, null, false, dispatch, state);
  },
});

clientDataListener.startListening({
  actionCreator: removeClientDataRows,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { selectedTable } = state.clientData;
    const {
      payload: { rows, table = selectedTable },
    } = action;

    const data = {
      [table]: rows
        .filter((row) => row[ClientDataFixedColumns.RowId])
        .map((row) => ({ [ClientDataFixedColumns.RowId]: row[ClientDataFixedColumns.RowId] })),
    };
    await updateClientDataEffect(data, null, true, dispatch, state);
  },
});

/**
 * Updates/Delete cell metadata from the database
 *
 * @returns
 */
const updateClientDataMetadataEffect = async (
  updates: UpdateClientDataMetadata[],
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
) => {
  const {
    currentUser: { group: { groupId } = unknownGroup },
    clientData: { clientId, clientDataType: dataType, clientDataBranch, selectedTable: table },
  } = state;

  const cellMetadataUpdates = getUpdatedCellsMetadata(clientId, table, updates).filter(Boolean);

  if (!cellMetadataUpdates.length) return;

  try {
    dispatch(saveClientDataStart());
    if (!clientDataBranch) {
      toast.error('No branch selected.');
      return;
    }

    let branch = clientDataBranch;
    let newBranch = false;
    if (clientDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      branch = ClientDataBranch.Unpublished;
      newBranch = true;
    }

    if (cellMetadataUpdates.length === 1) {
      const [cellMetadata] = cellMetadataUpdates;
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellMetadata.initiate({
          dataType,
          clientId,
          groupId,
          table,
          cellMetadata,
          branch,
          rowId: cellMetadata.rowId,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    } else {
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellsMetadata.initiate({
          dataType,
          clientId,
          groupId,
          table,
          cellsMetadata: cellMetadataUpdates,
          branch,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    }

    dispatch(setClientDataBranch(branch));
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

clientDataListener.startListening({
  actionCreator: updateClientDataMetadata,
  effect: async (action, { dispatch, getState }) => {
    const { payload: updates = [] } = action;
    const state = getState();

    await updateClientDataMetadataEffect(updates, dispatch, state);
  },
});
