import {
  Alert,
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { BRANCH_LABELS, MergeStatus } from '../constants/ClientData';
import { mapClientIdToConfiguratorAndVendor, mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { clientDataApi } from '../services/clientDataApi';
import { useGetClientName } from '../hooks/useGetClientName';
import { ClientDataType } from '../constants/ClientDataType';
import { PublishMergeResult } from '../types/ClientData';
import { unknownGroup } from '../constants/Group';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { config } from '../config/config';

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: { padding: '0px 8px 8px 8px' },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statusIcon: {
    width: '30px',
    height: '30px',
    marginRight: '15px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
  successColor: {
    color: theme.palette.success.dark,
  },
  infoColor: {
    color: 'rgba(160, 160, 160, 1)',
  },
  vendorsBox: {
    paddingTop: '0px',
  },
  vendorsPublishBox: {
    maxHeight: '500px',
    minWidth: '500px',
    overflow: 'auto',
    flexBasis: '100%',
    paddingLeft: '44px',
  },
  vendorPublishBox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  vendorPublishBoxIcon: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
  },
  vendorPublishBoxError: {
    background: 'rgba(244, 67, 54, 0.1)',
    borderRadius: '6px',
  },
  vendorPublishBoxErrorBox: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ProductionLinkUrl: React.FC<{ url?: string; children?: React.ReactNode }> = ({ url, children }) => (
  <Link href={url} target="_blank" rel="noreferrer noopener" underline="hover">
    {children}
  </Link>
);

interface StateProps {
  dialogKey: Dialogs;
  clientDataType: ClientDataType;
  publishMergeResult?: PublishMergeResult;
  vendorsToPublish: string[];
}

export const PublishResultDialog: React.FC<StateProps> = ({
  dialogKey,
  clientDataType,
  publishMergeResult,
  vendorsToPublish,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.clientData);
  const {
    data,
    error,
    isSuccess: isPublishSuccess,
  } = publishMergeResult || { data: undefined, error: undefined, isSuccess: false };
  const { vendorData: { locale = '', productionURL: vendorUrl = '' } = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });

  const { configurator = '', vendor = '' } = clientId ? mapClientIdToConfiguratorAndVendor(clientId) : {};
  const [publishingResults, setPublishingResults] = useState<{
    [vendorKey: string]: { isPublishing: boolean; isSuccess: boolean; error?: string; productionUrl?: string };
  }>({});
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [productionUrl, setProductionUrl] = useState('');
  const [isPublishingVendors, setIsPublishingVendors] = useState(false);
  const openDialogKey = useAppSelector((state) => state.dialog.key);
  const getClientName = useGetClientName();

  useEffect(() => {
    if (openDialogKey === dialogKey) {
      setPublishingResults({});
      if (clientDataType === ClientDataType.Supplier) {
        const publishVendors = async () => {
          setIsPublishingVendors(true);
          for (let i = 0; i < vendorsToPublish.length; i += 1) {
            const vendorKey = vendorsToPublish[i];
            const url = getConfiguratorUrlWithLocale(configurator, vendorKey, locale, vendorUrl);
            const vendorProductionUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
            try {
              setPublishingResults((prevResults) => ({
                ...prevResults,
                [vendorKey]: { isSuccess: false, isPublishing: true },
              }));

              // eslint-disable-next-line no-await-in-loop
              await dispatch(
                clientDataApi.endpoints.publishVendor.initiate({
                  clientId: mapConfiguratorToClientId({ key: configurator, vendor: vendorKey }),
                  suppliers: [vendor],
                  groupId,
                }),
              )
                .unwrap()
                .then(() =>
                  setPublishingResults((prevResults) => ({
                    ...prevResults,
                    [vendorKey]: { isSuccess: true, isPublishing: false, productionUrl: vendorProductionUrl },
                  })),
                );
            } catch (e: any) {
              setPublishingResults((prevResults) => ({
                ...prevResults,
                [vendorKey]: {
                  isSuccess: false,
                  isPublishing: false,
                  productionUrl: vendorProductionUrl,
                  error: e.data,
                },
              }));
            }
          }
          setIsPublishingVendors(false);
        };
        publishVendors();
      }
    }
  }, [vendorsToPublish, openDialogKey, clientDataType, dispatch, configurator, vendor, dialogKey, locale, vendorUrl]);

  useEffect(() => {
    if (clientId !== undefined && clientId !== '' && data && !isLoadingVendorData) {
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, vendorUrl);
      const configuratorUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
      setProductionUrl(configuratorUrl);
    }
  }, [clientId, data, configurator, vendor, isLoadingVendorData]);

  const additionalBranchesMergesWithConflicts =
    data?.additionalBranchMerges?.filter(({ status }) => status === MergeStatus.Conflicts) || [];

  const mergeSuccessful = isPublishSuccess && data && data.mainMerge.status === MergeStatus.Succeed;
  const errorMessage = error && 'data' in error ? error.data.replace(URL_REGEX, '') : '';
  const errorCellLink = error && 'data' in error ? (error.data.match(URL_REGEX) || [])[0] : undefined;

  const someVendorFailedToPublish = Object.keys(publishingResults).some(
    (vendorKey) => publishingResults[vendorKey].error,
  );
  const allVendorFailedToPublish = Object.keys(publishingResults).every(
    (vendorKey) => publishingResults[vendorKey].error,
  );
  const isSuccess = mergeSuccessful && !isPublishingVendors && !someVendorFailedToPublish;
  const isLoading = mergeSuccessful && isPublishingVendors;
  const isError = (!mergeSuccessful || someVendorFailedToPublish) && !isPublishingVendors;
  const environment = config.environment.STAGE || 'development';

  return (
    <Dialog dialogKey={dialogKey} maxWidth="lg" disableClose={isPublishingVendors}>
      <DialogContent className={classes.dialogContent}>
        {isLoading && <CircularProgress className={classes.statusIcon} size={30} style={{ padding: '2px' }} />}
        {isSuccess && <CheckCircleIcon className={`${classes.statusIcon} ${classes.successColor}`} />}
        {isError && <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />}
        <div>
          {isLoading && (
            <Typography>
              <Trans i18nKey={I18nKeys.ClientDataPublishPublishingVendors as string} context={environment} />
            </Typography>
          )}
          {isSuccess && (
            <>
              <Typography>
                <Trans
                  i18nKey={I18nKeys.ClientDataPublishSuccess as string}
                  components={{ a: <ProductionLinkUrl url={productionUrl} /> }}
                  context={environment}
                />
              </Typography>
              {data &&
                data.additionalBranchMerges?.length &&
                (additionalBranchesMergesWithConflicts.length > 0 ? (
                  <Typography>
                    <Trans
                      i18nKey={I18nKeys.ClientDataPublishSuccessConflicts as string}
                      values={{
                        branches: additionalBranchesMergesWithConflicts.map(({ branch }) => t(BRANCH_LABELS[branch])),
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography>
                    <Trans i18nKey={I18nKeys.ClientDataPublishSuccessNoConflicts as string} />
                  </Typography>
                ))}
            </>
          )}
          {isError && (
            <>
              <Typography>
                <Trans
                  i18nKey={
                    (allVendorFailedToPublish
                      ? I18nKeys.ClientDataPublishError
                      : I18nKeys.ClientDataPublishErrorSome) as string
                  }
                  context={environment}
                />
              </Typography>
              {error && (
                <Alert severity="error" icon={false}>
                  <code>{errorMessage}</code>
                  {errorCellLink && (
                    <>
                      <br />
                      <a href={errorCellLink}>Cell with error</a>
                    </>
                  )}
                </Alert>
              )}
              {data && data.mainMerge.status === MergeStatus.Conflicts && (
                <Typography>
                  <Trans i18nKey={I18nKeys.ClientDataPublishUnresolvedMergeConflicts as string} />
                </Typography>
              )}
            </>
          )}
        </div>
        {mergeSuccessful && clientDataType === ClientDataType.Supplier && vendorsToPublish.length > 0 && (
          <Box className={classes.vendorsPublishBox}>
            {vendorsToPublish.map((vendorKey) => {
              const vendorName = getClientName(vendorKey);
              const publishingStatus = publishingResults[vendorKey] || {};
              if (publishingStatus.isSuccess) {
                return (
                  <Paper key={vendorKey} className={classes.vendorPublishBox} elevation={0}>
                    <CheckCircleIcon className={`${classes.vendorPublishBoxIcon} ${classes.successColor}`} />
                    <Typography>
                      Vendor: <ProductionLinkUrl url={publishingStatus.productionUrl}>{vendorName}</ProductionLinkUrl>
                    </Typography>
                  </Paper>
                );
              }
              if (publishingStatus.error) {
                return (
                  <Paper
                    key={vendorKey}
                    className={`${classes.vendorPublishBox} ${classes.vendorPublishBoxError}`}
                    elevation={0}
                  >
                    <CancelIcon className={`${classes.vendorPublishBoxIcon} ${classes.errorColor}`} />
                    <div className={classes.vendorPublishBoxErrorBox}>
                      <Typography>
                        Vendor: <ProductionLinkUrl url={publishingStatus.productionUrl}>{vendorName}</ProductionLinkUrl>
                      </Typography>
                      <code>{publishingStatus.error}</code>
                    </div>
                  </Paper>
                );
              }
              if (publishingStatus.isPublishing) {
                return (
                  <Paper key={vendorKey} className={classes.vendorPublishBox} elevation={0}>
                    <div style={{ overflow: 'hidden', display: 'flex' }}>
                      <CircularProgress className={classes.vendorPublishBoxIcon} size={24} style={{ padding: '2px' }} />
                    </div>
                    <Typography>Vendor: {vendorName}</Typography>
                  </Paper>
                );
              }
              return (
                <Paper key={vendorKey} className={classes.vendorPublishBox} elevation={0}>
                  <MoreHorizIcon className={`${classes.vendorPublishBoxIcon} ${classes.infoColor}`} />
                  <Typography>Vendor: {vendorName}</Typography>
                </Paper>
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {additionalBranchesMergesWithConflicts.length > 0 && (
          <Button color={mergeSuccessful ? 'success' : 'error'}>{t(I18nKeys.ClientDataPublishResolveNowButton)}</Button>
        )}
        <Button
          disabled={isPublishingVendors}
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color={mergeSuccessful ? 'success' : 'error'}
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
