import { makeStyles } from '@mui/styles';
import { Grid2 as Grid, FormControl, InputAdornment, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { SiteDetailDataFields, basicInformationFieldsMap } from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField } from '../utils/vendorDataUtils';
import { DebouncedTextField } from './DebouncedTextField';
import { SiteDetailChange } from '../constants/ClientData';

const useStyles = makeStyles({
  fieldContainer: {
    padding: '8px',
  },
  checkboxFieldContainer: {
    padding: '0 8px',
    '&:first-child': {
      paddingTop: '8px',
    },
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  inputLabel: {
    marginTop: '8px',
    marginLeft: '12px',
  },
  filledInput: {
    fontSize: 16,
    borderRadius: 0,
    height: '55px',
  },
});

interface Props {
  data?: SiteDetail;
  onChange: (changes: SiteDetailChange[]) => void;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesBasicInformation: React.FC<Props> = ({
  data = {},
  onChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container>
      {Object.entries(basicInformationFieldsMap)
        .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
        .map(([key, field]) => {
          const { props: vendorProps = {}, i18nKey, checkbox } = field as SiteDetailField;
          if (checkbox) {
            return (
              <Grid size={{ xs: 12 }} className={classes.checkboxFieldContainer} key={key}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={key}
                        checked={!!data[key as SiteDetailDataFields]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                          onChange([{ column: key, value: e.target.checked }])
                        }
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t(i18nKey)}
                  />
                </FormControl>
              </Grid>
            );
          }
          return (
            <Grid size={{ xs: 12, sm: vendorProps.sm || 12 }} className={classes.fieldContainer} key={key}>
              <FormControl fullWidth>
                <DebouncedTextField
                  label={t(i18nKey)}
                  name={key}
                  onDebouncedChange={(v): void => onChange([{ column: key, value: v }])}
                  defaultValue={data[key as SiteDetailDataFields] || ''}
                  variant="filled"
                  disabled={disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                  slotProps={
                    [SiteDetailDataFields.HomeLinkUrl].includes(key as SiteDetailDataFields)
                      ? {
                          input: {
                            endAdornment: (
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
                                    event.stopPropagation();
                                    window.open(data.homeLinkUrl, '_blank', 'noopener noreferrer');
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }
                      : {}
                  }
                />
              </FormControl>
            </Grid>
          );
        })}
    </Grid>
  );
};
