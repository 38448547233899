export enum ClientDataBranch {
  Main = 'main',
  Unpublished = 'unpublished',
  ProfessionalServices = 'professional-services',
  Hotfix = 'quick-update',
  Pricing = 'pricing',
  ClientUpdate = 'client-update',
  SiteDetail = 'site-detail',
  PricingSizeBased = 'pricing-size-based',
  PricingSurcharge = 'pricing-surcharge',
}

export const pricingBranches: Set<ClientDataBranch> = new Set([
  ClientDataBranch.Main,
  ClientDataBranch.Pricing,
  ClientDataBranch.ClientUpdate,
  ClientDataBranch.PricingSizeBased,
  ClientDataBranch.PricingSurcharge,
]);
