import { CssBaseline, Theme, Container, useMediaQuery } from '@mui/material';
import { connect } from 'react-redux';
import { HookRouter, navigate, useInterceptor, useRoutes, usePath } from 'hookrouter';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Permission } from '@idearoom/types';
import { AppRoutes, RouteKeys } from '../constants/AppRoutes';
import { AuthStatus } from '../constants/AuthStatus';
import { setSearchTerm } from '../ducks/search';
import { Group } from '../types/Group';
import { unknownGroup } from '../constants/Group';
import { unknownUser, User } from '../types/User';
import {
  isCurrentUserAdmin,
  isCurrentUserAdminOrManager,
  isCurrentUserSuperUser,
  isIdeaRoomGroup,
  isIdeaRoomUser,
} from '../utils/userUtils';
import { UserDialog } from './UserDialog';
import { ConfiguratorDialog } from './ConfiguratorDialog';
import { ConfirmDialog } from './ConfirmationDialog';
import { NotificationDialog } from './NotificationDialog';
import { Dealers } from './Dealers';
import { Group as GroupComponent } from './Group';
import { Groups } from './Groups';
import { Loading } from './Loading';
import { OrderReport } from './OrderReport';
import { Profile } from './Profile';
import { LogoBar } from './LogoBar';
import { Users } from './Users';
import { Settings } from './Settings';
import { SceneEnvironmentDialog } from './SceneEnvironmentDialog';
import { WhatsNewDialog } from './WhatsNewDialog';
import { WhatsNewPreviewDialog } from './WhatsNewPreviewDialog';
import { Tour } from './Tour';
import { Pricing } from './Pricing';
import { Usage } from './Usage';
import { Sites } from './Sites';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { AllowedHTMLTagsDialog } from './AllowedHTMLTagsDialog';
import { I18nDialog } from './I18nDialog';
import { LanguageDialog } from './LanguageDialog';
import { ClientData } from './ClientData';
import { ImpersonationDialog } from './ImpersonationDialog';
import { Reports } from './Reports';
import { Payments } from './Payments';
import { MenuStatus, MenuWidthMap, publishBarHeight } from '../constants/Viewer';
import { Menu } from './Menu';
import { useAppDispatch } from '../hooks';
import { PublishBar } from './PublishBar';
import { getLogoBarHeight, includesPath } from '../utils/viewerUtils';
import { AppState } from '../types/AppState';
import { setPublishBarDisplay } from '../ducks/viewerSlice';
import { LoadingDialog } from './LoadingDialog';
import { Integrations } from './Integrations';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { PricingTab } from '../constants/Pricing';
import { hasAdminPermissions } from '../utils/permissionUtils';

const useStyles = makeStyles<
  Theme,
  { menuStatus: MenuStatus; displayPublishBar: boolean; mobileView: boolean; path: string }
>((theme: Theme) => ({
  main: {
    flexGrow: 1,
    marginTop: ({ mobileView, path }): string => `${getLogoBarHeight({ mobileView, path })}px`,
    marginBottom: ({ displayPublishBar }): string => `${displayPublishBar ? publishBarHeight : 0}px`,
    marginLeft: ({ menuStatus, mobileView }): string => (mobileView ? `0px` : `${MenuWidthMap[menuStatus]}px`),
    transition: theme.transitions.create(['margin-left', 'margin-bottom'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: 'auto',
    maxHeight: ({ displayPublishBar, mobileView, path }): string =>
      `calc(100vh - ${getLogoBarHeight({ mobileView, path })}px - ${displayPublishBar ? publishBarHeight : 0}px)`,
  },
  root: {
    display: 'flex',
    float: 'left',
    height: '100%',
    width: '100%',
    padding: 0,
    maxWidth: 'none',
  },
}));

const routes = (user: User = unknownUser, userGroup: Group = unknownGroup): HookRouter.RouteObject => {
  const routesObject = {
    '/profile': () => <Profile key={RouteKeys.ProfileKey} />,
    '/leads': () => <OrderReport key={RouteKeys.OrdersKey} />,
    '/orders': () => <OrderReport key={RouteKeys.OrdersKey} />,
    '/payments': () => <Payments key={RouteKeys.PaymentsKey} />,
    '/integrations': () => <Integrations key={RouteKeys.IntegrationsKey} />,
  } as HookRouter.RouteObject;

  const { configurators } = userGroup;
  if (
    configurators &&
    configurators.some((c) =>
      getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
    ) &&
    hasAdminPermissions(user.permissions)
  ) {
    routesObject['/dealers'] = (): JSX.Element => <Dealers key={RouteKeys.DealersKey} />;
  }

  if (isIdeaRoomGroup(userGroup.groupId)) {
    // Only IdeaRoom members can access the group page.
    routesObject['/groups'] = (): JSX.Element => <Groups key={RouteKeys.GroupsKey} />;
    routesObject['/groups/:groupId'] = ({ groupId }: HookRouter.QueryParams): JSX.Element => (
      <GroupComponent key={RouteKeys.GroupKey} groupId={groupId} />
    );
    routesObject['/reports'] = (): JSX.Element => <Reports key={RouteKeys.ReportsKey} />;
  }

  if (isIdeaRoomUser(user) || isCurrentUserSuperUser(user)) {
    routesObject['/data'] = (): JSX.Element => <ClientData key={RouteKeys.ClientDataKey} />;
    routesObject['/data/*'] = (): JSX.Element => <ClientData key={RouteKeys.ClientDataKey} />;
  }

  if (hasAdminPermissions(user.permissions) || user.permissions.includes(Permission.Manager)) {
    // Only admins and managers can access the users page.
    routesObject['/users'] = (): JSX.Element => <Users key={RouteKeys.UsersKey} groupId={userGroup.groupId} />;
  }

  if (hasAdminPermissions(user.permissions)) {
    // Only admins can access the usage, pricing, and sites pages.
    routesObject['/usage'] = (): JSX.Element => <Usage key={RouteKeys.UsageKey} />;
    routesObject[`/pricing`] = (): JSX.Element => <Pricing key={RouteKeys.PricingKey} />;
    Object.values(PricingTab).forEach((tab) => {
      routesObject[`/pricing/${tab}`] = (): JSX.Element => <Pricing key={RouteKeys.PricingKey} />;
    });
    routesObject['/sites'] = (): JSX.Element => <Sites key={RouteKeys.SitesKey} />;
    routesObject['/settings'] = (): JSX.Element => <Settings key={RouteKeys.SettingsKey} groupId={userGroup.groupId} />;
  }

  return routesObject;
};

interface Props {
  authStatus: AuthStatus;
  loading: boolean;
  group?: Group;
  user?: User;
  menuStatus: MenuStatus;
  displayPublishBar: boolean;
}

export const SalesViewComponent: React.FC<Props> = ({
  authStatus,
  group,
  loading,
  user,
  menuStatus,
  displayPublishBar,
}: Props) => {
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const path = usePath();

  const classes = useStyles({ menuStatus, displayPublishBar, mobileView, path });
  if (!loading && authStatus !== AuthStatus.SignedIn) {
    navigate(AppRoutes.SignIn);
  }

  const dispatch = useAppDispatch();
  const route = useRoutes(routes(user, group));

  const routeChangedInterceptor = (currentPath: string, nextPath: string): string => {
    // clear search on route changes
    dispatch(setSearchTerm(''));
    dispatch(setSecondaryActions([]));
    return nextPath;
  };

  useInterceptor(routeChangedInterceptor);

  useEffect(() => {
    const expectedPublishBarDisplay = includesPath(path, [AppRoutes.Pricing, AppRoutes.Sites]);
    if (displayPublishBar !== expectedPublishBarDisplay) {
      dispatch(setPublishBarDisplay(expectedPublishBarDisplay));
    }
  }, [path, dispatch, displayPublishBar]);

  return loading ? (
    <Loading />
  ) : (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <LogoBar />
      <Menu />
      <main className={classes.main}>
        <Container className={classes.root}>
          {route}
          <ConfirmDialog />
          <NotificationDialog />
          <LoadingDialog />
          <LanguageDialog />
          <Tour />
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && route && route.key === RouteKeys.SettingsKey && (
            <WhatsNewDialog />
          )}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && route && route.key === RouteKeys.SettingsKey && (
            <WhatsNewPreviewDialog />
          )}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && route && route.key === RouteKeys.SettingsKey && (
            <SceneEnvironmentDialog />
          )}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && <AllowedHTMLTagsDialog />}
          {(isCurrentUserAdminOrManager(user) || isIdeaRoomUser(user)) &&
            [RouteKeys.GroupKey, RouteKeys.UsersKey].includes(route?.key) && <UserDialog />}
          {isIdeaRoomUser(user) && [RouteKeys.GroupKey, RouteKeys.GroupsKey].includes(route?.key) && (
            <ConfiguratorDialog />
          )}
          {isIdeaRoomUser(user) && [RouteKeys.GroupKey, RouteKeys.UsersKey].includes(route?.key) && (
            <ImpersonationDialog />
          )}
          {isIdeaRoomUser(user) && route && route.key === RouteKeys.SettingsKey && <I18nDialog />}
        </Container>
      </main>
      {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && displayPublishBar && <PublishBar />}
    </div>
  );
};

const mapStateToProps = ({
  currentUser: { authStatus, loading, user, group },
  viewer: { menuStatus, displayPublishBar },
}: AppState): Props => ({
  authStatus,
  group,
  loading,
  user,
  menuStatus,
  displayPublishBar,
});

export const SalesView = connect(mapStateToProps)(SalesViewComponent);
