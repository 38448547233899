import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { VendorData } from '../types/VendorData';
import { Translation } from '../types/Translations';

export const vendorDataApi = createApi({
  reducerPath: 'vendorDataApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/vendorData',
  }),
  endpoints: (builder) => ({
    /**
     * Get vendor data by clientId
     */
    getVendorData: builder.query<
      VendorData[],
      {
        clientId: string;
      }
    >({
      query: ({ clientId }) => ({
        url: ``,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Get vendor data translations by clientId
     */
    getVendorDataTranslations: builder.query<
      {
        translation: Translation;
        regionTranslation: Translation;
      },
      {
        clientId: string;
        groupId: string;
      }
    >({
      query: ({ clientId, groupId }) => ({
        url: `/translations`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
    }),

    /**
     * Add vendor data for a client
     *
     * @param {string} clientId
     */
    addVendorData: builder.mutation<
      void,
      {
        clientId: string;
        vendorData: VendorData;
      }
    >({
      query: ({ clientId, vendorData }) => ({
        url: ``,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            vendorData,
          },
        },
      }),
    }),

    /**
     * Update vendor data for a client
     *
     * @param {string} clientId
     */
    updateVendorData: builder.mutation<
      void,
      {
        clientId: string;
        vendorData: VendorData;
      }
    >({
      query: ({ clientId, vendorData }) => ({
        url: ``,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            vendorData,
          },
        },
      }),
    }),

    /**
     * Delete vendor data for a client
     *
     * @param {string} clientId
     */
    deleteVendorData: builder.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ clientId: id }),
        },
      }),
    }),
  }),
});

export const { useGetVendorDataTranslationsQuery } = vendorDataApi;
